export const response = {
    "chats": [
        {
            "id": 85627,
            "chatid": 96243618,
            "messageid": "ef665ff5271db9c23d78c2186b40a5aea47c04f33ad61cc1404bb8d7021dab6c",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":96243618,\"offerId\":300860652,\"createdAt\":\"2024-10-02T18:52:00.112417+00:00\",\"updatedAt\":\"2024-10-02T18:55:15.523150+00:00\",\"messages\":[{\"messageId\":\"ef665ff5271db9c23d78c2186b40a5aea47c04f33ad61cc1404bb8d7021dab6c\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте. Завтра позвоню. Мой номер 89271686925\",\"offers\":[]},\"createdAt\":\"2024-10-02T18:55:15.523150+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-10-02 21:55:15.917295",
            "update_at": "2024-10-02 21:55:15.917295",
            "create_at_original": "2024-10-02 21:52:00",
            "update_at_original": "2024-10-02 21:55:15",
            "objectId": 63977339
        },
        {
            "id": 85626,
            "chatid": 96243618,
            "messageid": "f52d8fb059bff5411bba8353373f3ed9b3b9162ba9a4a2f8129fff4d4fb51869",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":96243618,\"offerId\":300860652,\"createdAt\":\"2024-10-02T18:52:00.112417+00:00\",\"updatedAt\":\"2024-10-02T18:52:00.180882+00:00\",\"messages\":[{\"messageId\":\"f52d8fb059bff5411bba8353373f3ed9b3b9162ba9a4a2f8129fff4d4fb51869\",\"userId\":122318476,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Пожалуйста, свяжитесь со мной +79954997015\",\"offers\":[]},\"createdAt\":\"2024-10-02T18:52:00.180882+00:00\"}]}],\"users\":[{\"userId\":122318476,\"name\":\"Ксения\",\"firstName\":\"Ксения\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=122318476&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=122318476&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-10-02 21:52:00.928074",
            "update_at": "2024-10-02 21:52:00.928074",
            "create_at_original": "2024-10-02 21:52:00",
            "update_at_original": "2024-10-02 21:52:00",
            "objectId": 63977339
        },
        {
            "id": 85029,
            "chatid": 95988871,
            "messageid": "6e011ed77ff8dd71b23cc9b58fe42acaf36b42d4589dca19a0eff7795d002634",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95988871,\"offerId\":307963232,\"createdAt\":\"2024-10-01T04:37:54.113209+00:00\",\"updatedAt\":\"2024-10-01T04:40:23.909792+00:00\",\"messages\":[{\"messageId\":\"6e011ed77ff8dd71b23cc9b58fe42acaf36b42d4589dca19a0eff7795d002634\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте. Нет, но можно сделать.\\nЕсть баня.\\nНомер для связи 89271686925\",\"offers\":[]},\"createdAt\":\"2024-10-01T04:40:23.909792+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":307963232,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/307963232/\",\"title\":\"Дом, 40 м²\",\"address\":\"Саратовская область, Энгельс, Тихая улица, 3\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2283730912-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2283730912-1.jpg\"},\"price\":\"4 500 000 ₽\",\"externalId\":\"72628711\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-10-01 07:40:24.591428",
            "update_at": "2024-10-01 07:40:24.591428",
            "create_at_original": "2024-10-01 07:37:54",
            "update_at_original": "2024-10-01 07:40:23",
            "objectId": 72628711
        },
        {
            "id": 85028,
            "chatid": 95988871,
            "messageid": "74d3c0e7814c4ad1b2571290f500fc0210ce6b9161e9893bf5c677908fe488f4",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95988871,\"offerId\":307963232,\"createdAt\":\"2024-10-01T04:37:54.113209+00:00\",\"updatedAt\":\"2024-10-01T04:37:54.165473+00:00\",\"messages\":[{\"messageId\":\"74d3c0e7814c4ad1b2571290f500fc0210ce6b9161e9893bf5c677908fe488f4\",\"userId\":54327425,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Душм в доме нет ?\",\"offers\":[]},\"createdAt\":\"2024-10-01T04:37:54.165473+00:00\"}]}],\"users\":[{\"userId\":54327425,\"name\":\"Сергей\",\"firstName\":\"Сергей\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=54327425&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=54327425&t=2&p=2\"}}}],\"offers\":[{\"id\":307963232,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/307963232/\",\"title\":\"Дом, 40 м²\",\"address\":\"Саратовская область, Энгельс, Тихая улица, 3\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2283730912-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2283730912-1.jpg\"},\"price\":\"4 500 000 ₽\",\"externalId\":\"72628711\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-10-01 07:37:54.981339",
            "update_at": "2024-10-01 07:37:54.981339",
            "create_at_original": "2024-10-01 07:37:54",
            "update_at_original": "2024-10-01 07:37:54",
            "objectId": 72628711
        },
        {
            "id": 84951,
            "chatid": 95957480,
            "messageid": "a029c229586f061b3472e75a9b9c6074f97d0d01f18b2548c392efac0a420a51",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95957480,\"offerId\":306907471,\"createdAt\":\"2024-09-30T17:18:30.328390+00:00\",\"updatedAt\":\"2024-09-30T17:18:30.546323+00:00\",\"messages\":[{\"messageId\":\"a029c229586f061b3472e75a9b9c6074f97d0d01f18b2548c392efac0a420a51\",\"userId\":115641322,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте в аренду нельзя взять?\",\"offers\":[]},\"createdAt\":\"2024-09-30T17:18:30.546323+00:00\"}]}],\"users\":[{\"userId\":115641322,\"name\":\"115641322\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115641322&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115641322&t=2&p=2\"}}}],\"offers\":[{\"id\":306907471,\"userId\":104181819,\"url\":\"https://cian.ru/sale/suburban/306907471/\",\"title\":\"Дом, 66,6 м²\",\"address\":\"Саратовская область, Энгельс, пос. Новое Осокорье, улица Байкальская, 97\",\"publishedUserId\":104181819,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2259825156-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2259825156-1.jpg\"},\"price\":\"3 400 000 ₽\",\"externalId\":\"70899050\"}]}",
            "user_id": 14828,
            "agency_id": 14103,
            "created_at": "2024-09-30 20:18:31.384836",
            "update_at": "2024-09-30 20:18:31.384836",
            "create_at_original": "2024-09-30 20:18:30",
            "update_at_original": "2024-09-30 20:18:30",
            "objectId": 70899050
        },
        {
            "id": 84916,
            "chatid": 95936258,
            "messageid": "dbffeec6055cdf481bd0a8d754499607c8eceac67a036e7884ffee9677e8363b",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95936258,\"offerId\":300865439,\"createdAt\":\"2024-09-30T14:16:29.613645+00:00\",\"updatedAt\":\"2024-09-30T14:18:40.256987+00:00\",\"messages\":[{\"messageId\":\"dbffeec6055cdf481bd0a8d754499607c8eceac67a036e7884ffee9677e8363b\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте. Да. Звоните , 89271686925\",\"offers\":[]},\"createdAt\":\"2024-09-30T14:18:40.256987+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300865439,\"userId\":115390423,\"url\":\"https://cian.ru/sale/flat/300865439/\",\"title\":\"1-комн. кв., 21,4 м², 8/9 этаж\",\"address\":\"Саратовская область, Саратов, проспект Энтузиастов, 9а\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148411549-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148411549-1.jpg\"},\"price\":\"2 900 000 ₽\",\"externalId\":\"63832560\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-30 17:18:41.066303",
            "update_at": "2024-09-30 17:18:41.066303",
            "create_at_original": "2024-09-30 17:16:29",
            "update_at_original": "2024-09-30 17:18:40",
            "objectId": 63832560
        },
        {
            "id": 84915,
            "chatid": 95936258,
            "messageid": "e65030acb98e56384ceacc4bbd2c9af19fce1f525cae1be0fe940a2fc8b2e8a1",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95936258,\"offerId\":300865439,\"createdAt\":\"2024-09-30T14:16:29.613645+00:00\",\"updatedAt\":\"2024-09-30T14:16:29.891107+00:00\",\"messages\":[{\"messageId\":\"e65030acb98e56384ceacc4bbd2c9af19fce1f525cae1be0fe940a2fc8b2e8a1\",\"userId\":13920165,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Уточните, пожалуйста, вы ещё продаёте эту квартиру?\",\"offers\":[]},\"createdAt\":\"2024-09-30T14:16:29.891107+00:00\"}]}],\"users\":[{\"userId\":13920165,\"name\":\"13920165\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=13920165&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=13920165&t=2&p=2\"}}}],\"offers\":[{\"id\":300865439,\"userId\":115390423,\"url\":\"https://cian.ru/sale/flat/300865439/\",\"title\":\"1-комн. кв., 21,4 м², 8/9 этаж\",\"address\":\"Саратовская область, Саратов, проспект Энтузиастов, 9а\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148411549-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148411549-1.jpg\"},\"price\":\"2 900 000 ₽\",\"externalId\":\"63832560\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-30 17:16:32.081937",
            "update_at": "2024-09-30 17:16:32.081937",
            "create_at_original": "2024-09-30 17:16:29",
            "update_at_original": "2024-09-30 17:16:29",
            "objectId": 63832560
        },
        {
            "id": 84554,
            "chatid": 95804228,
            "messageid": "6c3bcb02581cef1ea671114f714575620ca645b471a42097d8ae48a64f4dd958",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95804228,\"offerId\":306555301,\"createdAt\":\"2024-09-29T13:26:53.084252+00:00\",\"updatedAt\":\"2024-09-29T13:26:53.261851+00:00\",\"messages\":[{\"messageId\":\"6c3bcb02581cef1ea671114f714575620ca645b471a42097d8ae48a64f4dd958\",\"userId\":122124703,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте, подскажите, пожалуйста, а диван не планируете менять?\",\"offers\":[]},\"createdAt\":\"2024-09-29T13:26:53.261851+00:00\"}]}],\"users\":[{\"userId\":122124703,\"name\":\"Александра\",\"firstName\":\"Александра\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=122124703&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=122124703&t=2&p=2\"}}}],\"offers\":[{\"id\":306555301,\"userId\":114681854,\"url\":\"https://cian.ru/rent/flat/306555301/\",\"title\":\"1-комн. кв., 39,2 м², 16/26 этаж\",\"address\":\"Саратовская область, Энгельс, улица Пушкина, 87\",\"publishedUserId\":114681854,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2253640066-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2253640066-1.jpg\"},\"price\":\"23 000 ₽/мес.\",\"externalId\":\"53936599\"}]}",
            "user_id": 14236,
            "agency_id": 14103,
            "created_at": "2024-09-29 16:26:54.017528",
            "update_at": "2024-09-29 16:26:54.017528",
            "create_at_original": "2024-09-29 16:26:53",
            "update_at_original": "2024-09-29 16:26:53",
            "objectId": 53936599
        },
        {
            "id": 84453,
            "chatid": 95777014,
            "messageid": "d5be5cbd3fe70cb1a0c25726839dcedabceb3ce9ec2b8d6de5a2edb49c6fbbfa",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95777014,\"offerId\":306555301,\"createdAt\":\"2024-09-29T09:38:20.165471+00:00\",\"updatedAt\":\"2024-09-29T09:38:20.448716+00:00\",\"messages\":[{\"messageId\":\"d5be5cbd3fe70cb1a0c25726839dcedabceb3ce9ec2b8d6de5a2edb49c6fbbfa\",\"userId\":110186933,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Пожалуйста, свяжитесь со мной +79378192555\",\"offers\":[]},\"createdAt\":\"2024-09-29T09:38:20.448716+00:00\"}]}],\"users\":[{\"userId\":110186933,\"name\":\"Татьяна  Осипова\",\"firstName\":\"Татьяна \",\"lastName\":\"Осипова\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=110186933&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=110186933&t=2&p=2\"}}}],\"offers\":[{\"id\":306555301,\"userId\":114681854,\"url\":\"https://cian.ru/rent/flat/306555301/\",\"title\":\"1-комн. кв., 39,2 м², 16/26 этаж\",\"address\":\"Саратовская область, Энгельс, улица Пушкина, 87\",\"publishedUserId\":114681854,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2253640066-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2253640066-1.jpg\"},\"price\":\"23 000 ₽/мес.\",\"externalId\":\"53936599\"}]}",
            "user_id": 14236,
            "agency_id": 14103,
            "created_at": "2024-09-29 12:38:21.002243",
            "update_at": "2024-09-29 12:38:21.002243",
            "create_at_original": "2024-09-29 12:38:20",
            "update_at_original": "2024-09-29 12:38:20",
            "objectId": 53936599
        },
        {
            "id": 84260,
            "chatid": 95694034,
            "messageid": "e80abf7b7cb121d563e4f8ec7299165de506a412989c95c907964314a5150600",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95694034,\"offerId\":305858926,\"createdAt\":\"2024-09-28T11:49:55.625207+00:00\",\"updatedAt\":\"2024-09-28T11:49:55.856170+00:00\",\"messages\":[{\"messageId\":\"e80abf7b7cb121d563e4f8ec7299165de506a412989c95c907964314a5150600\",\"userId\":114743865,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте,квартира угловая ?\",\"offers\":[]},\"createdAt\":\"2024-09-28T11:49:55.856170+00:00\"}]}],\"users\":[{\"userId\":114743865,\"name\":\"114743865\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=114743865&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=114743865&t=2&p=2\"}}}],\"offers\":[{\"id\":305858926,\"userId\":114681854,\"url\":\"https://cian.ru/sale/flat/305858926/\",\"title\":\"1-комн. кв., 39,8 м², 3/10 этаж\",\"address\":\"Саратовская область, Энгельс, улица Шурова Гора, 7/8\",\"publishedUserId\":114681854,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2241306491-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2241306491-1.jpg\"},\"price\":\"4 070 000 ₽\",\"externalId\":\"70054879\"}]}",
            "user_id": 14236,
            "agency_id": 14103,
            "created_at": "2024-09-28 14:49:56.662742",
            "update_at": "2024-09-28 14:49:56.662742",
            "create_at_original": "2024-09-28 14:49:55",
            "update_at_original": "2024-09-28 14:49:55",
            "objectId": 70054879
        },
        {
            "id": 84228,
            "chatid": 95406674,
            "messageid": "0fa3c6f845a0ff1433f433291ec8ed2a9ccd6c8611006b6f118a666b5c02e408",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95406674,\"offerId\":300865457,\"createdAt\":\"2024-09-26T07:23:04.472583+00:00\",\"updatedAt\":\"2024-09-28T10:48:36.077757+00:00\",\"messages\":[{\"messageId\":\"0fa3c6f845a0ff1433f433291ec8ed2a9ccd6c8611006b6f118a666b5c02e408\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Хорошо\",\"offers\":[]},\"createdAt\":\"2024-09-28T10:48:36.077757+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300865457,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300865457/\",\"title\":\"Земельный участок, 13 сот.\",\"address\":\"Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148411746-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148411746-1.jpg\"},\"price\":\"550 000 ₽\",\"externalId\":\"63869445\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-28 13:48:36.968841",
            "update_at": "2024-09-28 13:48:36.968841",
            "create_at_original": "2024-09-26 10:23:04",
            "update_at_original": "2024-09-28 13:48:36",
            "objectId": 63869445
        },
        {
            "id": 84224,
            "chatid": 95406674,
            "messageid": "2dcc463634ee82639d9a3bf2b7e7638bf07cd1167757add5cd7b5f30e3ffbbe0",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95406674,\"offerId\":300865457,\"createdAt\":\"2024-09-26T07:23:04.472583+00:00\",\"updatedAt\":\"2024-09-28T10:38:21.694712+00:00\",\"messages\":[{\"messageId\":\"2dcc463634ee82639d9a3bf2b7e7638bf07cd1167757add5cd7b5f30e3ffbbe0\",\"userId\":106270336,\"direction\":\"in\",\"content\":{\"text\":\"В это воскресенье не будем смотреть\",\"offers\":[]},\"createdAt\":\"2024-09-28T10:38:21.694712+00:00\"}]}],\"users\":[{\"userId\":106270336,\"name\":\"Олег\",\"firstName\":\"Олег\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=106270336&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=106270336&t=2&p=2\"}}}],\"offers\":[{\"id\":300865457,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300865457/\",\"title\":\"Земельный участок, 13 сот.\",\"address\":\"Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148411746-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148411746-1.jpg\"},\"price\":\"550 000 ₽\",\"externalId\":\"63869445\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-28 13:38:22.563784",
            "update_at": "2024-09-28 13:38:22.563784",
            "create_at_original": "2024-09-26 10:23:04",
            "update_at_original": "2024-09-28 13:38:21",
            "objectId": 63869445
        },
        {
            "id": 84223,
            "chatid": 95406674,
            "messageid": "85bc3f8e03b991842e0fe15e69d51918744961d6591a07a5ec08709a62e84fbb",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95406674,\"offerId\":300865457,\"createdAt\":\"2024-09-26T07:23:04.472583+00:00\",\"updatedAt\":\"2024-09-28T10:38:21.006558+00:00\",\"messages\":[{\"messageId\":\"85bc3f8e03b991842e0fe15e69d51918744961d6591a07a5ec08709a62e84fbb\",\"userId\":106270336,\"direction\":\"in\",\"content\":{\"text\":\"В это воскресенье не будем смотреть\",\"offers\":[]},\"createdAt\":\"2024-09-28T10:38:21.006558+00:00\"}]}],\"users\":[{\"userId\":106270336,\"name\":\"Олег\",\"firstName\":\"Олег\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=106270336&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=106270336&t=2&p=2\"}}}],\"offers\":[{\"id\":300865457,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300865457/\",\"title\":\"Земельный участок, 13 сот.\",\"address\":\"Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148411746-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148411746-1.jpg\"},\"price\":\"550 000 ₽\",\"externalId\":\"63869445\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-28 13:38:21.807449",
            "update_at": "2024-09-28 13:38:21.807449",
            "create_at_original": "2024-09-26 10:23:04",
            "update_at_original": "2024-09-28 13:38:21",
            "objectId": 63869445
        },
        {
            "id": 84222,
            "chatid": 95406674,
            "messageid": "8eac72c86a3ea4c4dd5594ed6cd19bbf90bea7e7bb1efa32e7e331bc4cba083b",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95406674,\"offerId\":300865457,\"createdAt\":\"2024-09-26T07:23:04.472583+00:00\",\"updatedAt\":\"2024-09-28T10:35:57.234797+00:00\",\"messages\":[{\"messageId\":\"8eac72c86a3ea4c4dd5594ed6cd19bbf90bea7e7bb1efa32e7e331bc4cba083b\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Участок у подножия горы, местами не ровный, но не на склоне\",\"offers\":[]},\"createdAt\":\"2024-09-28T10:35:57.234797+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300865457,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300865457/\",\"title\":\"Земельный участок, 13 сот.\",\"address\":\"Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148411746-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148411746-1.jpg\"},\"price\":\"550 000 ₽\",\"externalId\":\"63869445\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-28 13:35:57.696303",
            "update_at": "2024-09-28 13:35:57.696303",
            "create_at_original": "2024-09-26 10:23:04",
            "update_at_original": "2024-09-28 13:35:57",
            "objectId": 63869445
        },
        {
            "id": 84221,
            "chatid": 95406674,
            "messageid": "9508d5a9e33c1102e542bb55ce3aa5bd8c9699458db9e3fd643f6e1ad1cae0fa",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95406674,\"offerId\":300865457,\"createdAt\":\"2024-09-26T07:23:04.472583+00:00\",\"updatedAt\":\"2024-09-28T10:33:27.027571+00:00\",\"messages\":[{\"messageId\":\"9508d5a9e33c1102e542bb55ce3aa5bd8c9699458db9e3fd643f6e1ad1cae0fa\",\"userId\":106270336,\"direction\":\"in\",\"content\":{\"text\":\"Добрый день. На фото непонятно, он на склоне находится?\",\"offers\":[]},\"createdAt\":\"2024-09-28T10:33:27.027571+00:00\"}]}],\"users\":[{\"userId\":106270336,\"name\":\"Олег\",\"firstName\":\"Олег\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=106270336&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=106270336&t=2&p=2\"}}}],\"offers\":[{\"id\":300865457,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300865457/\",\"title\":\"Земельный участок, 13 сот.\",\"address\":\"Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148411746-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148411746-1.jpg\"},\"price\":\"550 000 ₽\",\"externalId\":\"63869445\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-28 13:33:35.207435",
            "update_at": "2024-09-28 13:33:35.207435",
            "create_at_original": "2024-09-26 10:23:04",
            "update_at_original": "2024-09-28 13:33:27",
            "objectId": 63869445
        },
        {
            "id": 84220,
            "chatid": 95406674,
            "messageid": "11ccee8bd4f417831ed4a0a212c50137216c0507a9e4d1ebbfee9305325450a5",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95406674,\"offerId\":300865457,\"createdAt\":\"2024-09-26T07:23:04.472583+00:00\",\"updatedAt\":\"2024-09-28T10:33:26.427166+00:00\",\"messages\":[{\"messageId\":\"11ccee8bd4f417831ed4a0a212c50137216c0507a9e4d1ebbfee9305325450a5\",\"userId\":106270336,\"direction\":\"in\",\"content\":{\"text\":\"Добрый день. На фото непонятно, он на склоне находится?\",\"offers\":[]},\"createdAt\":\"2024-09-28T10:33:26.427166+00:00\"}]}],\"users\":[{\"userId\":106270336,\"name\":\"Олег\",\"firstName\":\"Олег\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=106270336&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=106270336&t=2&p=2\"}}}],\"offers\":[{\"id\":300865457,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300865457/\",\"title\":\"Земельный участок, 13 сот.\",\"address\":\"Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148411746-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148411746-1.jpg\"},\"price\":\"550 000 ₽\",\"externalId\":\"63869445\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-28 13:33:27.239048",
            "update_at": "2024-09-28 13:33:27.239048",
            "create_at_original": "2024-09-26 10:23:04",
            "update_at_original": "2024-09-28 13:33:26",
            "objectId": 63869445
        },
        {
            "id": 84219,
            "chatid": 95406674,
            "messageid": "a6838dffa20a7583e0ea79d8f8e9add1e818c832e6e1f8433b6183f21b40beb1",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95406674,\"offerId\":300865457,\"createdAt\":\"2024-09-26T07:23:04.472583+00:00\",\"updatedAt\":\"2024-09-28T10:02:47.622140+00:00\",\"messages\":[{\"messageId\":\"a6838dffa20a7583e0ea79d8f8e9add1e818c832e6e1f8433b6183f21b40beb1\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте. Участок завтра будете смотреть?\",\"offers\":[]},\"createdAt\":\"2024-09-28T10:02:47.622140+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300865457,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300865457/\",\"title\":\"Земельный участок, 13 сот.\",\"address\":\"Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148411746-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148411746-1.jpg\"},\"price\":\"550 000 ₽\",\"externalId\":\"63869445\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-28 13:02:48.328411",
            "update_at": "2024-09-28 13:02:48.328411",
            "create_at_original": "2024-09-26 10:23:04",
            "update_at_original": "2024-09-28 13:02:47",
            "objectId": 63869445
        },
        {
            "id": 84196,
            "chatid": 95386563,
            "messageid": "37a2121fd40afc6a1218f98e21bedea129ebca8e1a40d66e0a9bc47511483d6f",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95386563,\"offerId\":300836901,\"createdAt\":\"2024-09-26T03:44:30.749522+00:00\",\"updatedAt\":\"2024-09-28T08:47:49.131435+00:00\",\"messages\":[{\"messageId\":\"37a2121fd40afc6a1218f98e21bedea129ebca8e1a40d66e0a9bc47511483d6f\",\"userId\":48504235,\"direction\":\"in\",\"content\":{\"text\":\"Ок\",\"offers\":[]},\"createdAt\":\"2024-09-28T08:47:49.131435+00:00\"}]}],\"users\":[{\"userId\":48504235,\"name\":\"Надежда Пурим\",\"firstName\":\"Надежда\",\"lastName\":\"Пурим\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=48504235&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=48504235&t=2&p=2\"}}}],\"offers\":[{\"id\":300836901,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300836901/\",\"title\":\"Дом, 75,7 м²\",\"address\":\"Саратовская область, Красноармейск муниципальное образование, с. Ревино, улица Садовая, 2\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2150548187-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2150548187-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63973944\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-28 11:47:49.902659",
            "update_at": "2024-09-28 11:47:49.902659",
            "create_at_original": "2024-09-26 06:44:30",
            "update_at_original": "2024-09-28 11:47:49",
            "objectId": 63973944
        },
        {
            "id": 84051,
            "chatid": 95386563,
            "messageid": "127253addc56aeddf1568b38a84c6512a4967cbd970a468d429f81dce6b53c67",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95386563,\"offerId\":300836901,\"createdAt\":\"2024-09-26T03:44:30.749522+00:00\",\"updatedAt\":\"2024-09-27T16:04:50.139886+00:00\",\"messages\":[{\"messageId\":\"127253addc56aeddf1568b38a84c6512a4967cbd970a468d429f81dce6b53c67\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте. Пока не получила ответ от продавца.\",\"offers\":[]},\"createdAt\":\"2024-09-27T16:04:50.139886+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300836901,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300836901/\",\"title\":\"Дом, 75,7 м²\",\"address\":\"Саратовская область, Красноармейск муниципальное образование, с. Ревино, улица Садовая, 2\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2150548187-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2150548187-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63973944\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-27 19:04:50.915563",
            "update_at": "2024-09-27 19:04:50.915563",
            "create_at_original": "2024-09-26 06:44:30",
            "update_at_original": "2024-09-27 19:04:50",
            "objectId": 63973944
        },
        {
            "id": 83962,
            "chatid": 95386563,
            "messageid": "ec87ce76909cc230e433e0ea5fe6d70a831a74157c0a771608948d1ce7a3df9a",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95386563,\"offerId\":300836901,\"createdAt\":\"2024-09-26T03:44:30.749522+00:00\",\"updatedAt\":\"2024-09-27T10:23:08.445961+00:00\",\"messages\":[{\"messageId\":\"ec87ce76909cc230e433e0ea5fe6d70a831a74157c0a771608948d1ce7a3df9a\",\"userId\":48504235,\"direction\":\"in\",\"content\":{\"text\":\"Анастасия добрый день, что на счёт дома, скажите ?\",\"offers\":[]},\"createdAt\":\"2024-09-27T10:23:08.445961+00:00\"}]}],\"users\":[{\"userId\":48504235,\"name\":\"Надежда Пурим\",\"firstName\":\"Надежда\",\"lastName\":\"Пурим\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=48504235&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=48504235&t=2&p=2\"}}}],\"offers\":[{\"id\":300836901,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300836901/\",\"title\":\"Дом, 75,7 м²\",\"address\":\"Саратовская область, Красноармейск муниципальное образование, с. Ревино, улица Садовая, 2\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2150548187-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2150548187-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63973944\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-27 13:23:09.177207",
            "update_at": "2024-09-27 13:23:09.177207",
            "create_at_original": "2024-09-26 06:44:30",
            "update_at_original": "2024-09-27 13:23:08",
            "objectId": 63973944
        },
        {
            "id": 83867,
            "chatid": 89031205,
            "messageid": "edf19d067f3854d7c54658c1f884f1ac6e264385f525e55259cf95a76b318724",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":89031205,\"offerId\":305017367,\"createdAt\":\"2024-08-09T10:21:42.668390+00:00\",\"updatedAt\":\"2024-09-27T05:53:58.465825+00:00\",\"messages\":[{\"messageId\":\"edf19d067f3854d7c54658c1f884f1ac6e264385f525e55259cf95a76b318724\",\"userId\":115540687,\"direction\":\"out\",\"content\":{\"text\":\"продали за 970\",\"offers\":[]},\"createdAt\":\"2024-09-27T05:53:58.465825+00:00\"}]}],\"users\":[{\"userId\":115540687,\"name\":\"Алексей Савин\",\"firstName\":\"Алексей\",\"lastName\":\"Савин\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115540687&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115540687&t=2&p=2\"}}}],\"offers\":[{\"id\":305017367,\"userId\":115540687,\"url\":\"https://cian.ru/sale/suburban/305017367/\",\"externalId\":\"64035747\"}]}",
            "user_id": 17608,
            "agency_id": 14103,
            "created_at": "2024-09-27 08:53:59.147411",
            "update_at": "2024-09-27 08:53:59.147411",
            "create_at_original": "2024-08-09 13:21:42",
            "update_at_original": "2024-09-27 08:53:58",
            "objectId": 64035747
        },
        {
            "id": 83715,
            "chatid": 95449081,
            "messageid": "2f7bae3a9b105a3c024e6e2540f70daff57bedfdcbadd1163d41d4c6ace054f2",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95449081,\"offerId\":306704393,\"createdAt\":\"2024-09-26T11:57:40.586781+00:00\",\"updatedAt\":\"2024-09-26T11:57:40.759322+00:00\",\"messages\":[{\"messageId\":\"2f7bae3a9b105a3c024e6e2540f70daff57bedfdcbadd1163d41d4c6ace054f2\",\"userId\":122248544,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Уточните, пожалуйста, ещё сдаёте?\",\"offers\":[]},\"createdAt\":\"2024-09-26T11:57:40.759322+00:00\"}]}],\"users\":[{\"userId\":122248544,\"name\":\"122248544\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=122248544&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=122248544&t=2&p=2\"}}}],\"offers\":[{\"id\":306704393,\"userId\":114681854,\"url\":\"https://cian.ru/rent/flat/306704393/\",\"title\":\"1-комн. кв., 37,9 м², 2/10 этаж\",\"address\":\"Саратовская область, Энгельс, улица Шурова Гора, 7/7\",\"publishedUserId\":114681854,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2256119804-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2256119804-1.jpg\"},\"price\":\"20 000 ₽/мес.\",\"externalId\":\"71046454\"}]}",
            "user_id": 14236,
            "agency_id": 14103,
            "created_at": "2024-09-26 14:57:41.536701",
            "update_at": "2024-09-26 14:57:41.536701",
            "create_at_original": "2024-09-26 14:57:40",
            "update_at_original": "2024-09-26 14:57:40",
            "objectId": 71046454
        },
        {
            "id": 83653,
            "chatid": 95406674,
            "messageid": "4559f7dd823ecb78ff77edb5f18a7445d8cb9c9ba618a6b56a2d80a5b03f64c2",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95406674,\"offerId\":300865457,\"createdAt\":\"2024-09-26T07:23:04.472583+00:00\",\"updatedAt\":\"2024-09-26T07:24:25.853612+00:00\",\"messages\":[{\"messageId\":\"4559f7dd823ecb78ff77edb5f18a7445d8cb9c9ba618a6b56a2d80a5b03f64c2\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте. Да. \\nЗвоните по номеру 89271686925, договоримся\",\"offers\":[]},\"createdAt\":\"2024-09-26T07:24:25.853612+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300865457,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300865457/\",\"title\":\"Земельный участок, 13 сот.\",\"address\":\"Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148411746-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148411746-1.jpg\"},\"price\":\"550 000 ₽\",\"externalId\":\"63869445\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-26 10:24:27.773693",
            "update_at": "2024-09-26 10:24:27.773693",
            "create_at_original": "2024-09-26 10:23:04",
            "update_at_original": "2024-09-26 10:24:25",
            "objectId": 63869445
        },
        {
            "id": 83650,
            "chatid": 95406674,
            "messageid": "19d38a8c9367e8895862b211b98280c7457648a6a7d7b025ea5d0aa32cf7924c",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95406674,\"offerId\":300865457,\"createdAt\":\"2024-09-26T07:23:04.472583+00:00\",\"updatedAt\":\"2024-09-26T07:23:04.691836+00:00\",\"messages\":[{\"messageId\":\"19d38a8c9367e8895862b211b98280c7457648a6a7d7b025ea5d0aa32cf7924c\",\"userId\":106270336,\"direction\":\"in\",\"content\":{\"text\":\"Добрый день! В воскресенье можно посмотреть?\",\"offers\":[]},\"createdAt\":\"2024-09-26T07:23:04.691836+00:00\"}]}],\"users\":[{\"userId\":106270336,\"name\":\"Олег\",\"firstName\":\"Олег\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=106270336&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=106270336&t=2&p=2\"}}}],\"offers\":[{\"id\":300865457,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300865457/\",\"title\":\"Земельный участок, 13 сот.\",\"address\":\"Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148411746-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148411746-1.jpg\"},\"price\":\"550 000 ₽\",\"externalId\":\"63869445\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-26 10:23:05.621478",
            "update_at": "2024-09-26 10:23:05.621478",
            "create_at_original": "2024-09-26 10:23:04",
            "update_at_original": "2024-09-26 10:23:04",
            "objectId": 63869445
        },
        {
            "id": 83624,
            "chatid": 95386563,
            "messageid": "09c7059460919bec61a7f61611abe684cde9c9035390b32f74da175b1c344d52",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95386563,\"offerId\":300836901,\"createdAt\":\"2024-09-26T03:44:30.749522+00:00\",\"updatedAt\":\"2024-09-26T03:46:41.664418+00:00\",\"messages\":[{\"messageId\":\"09c7059460919bec61a7f61611abe684cde9c9035390b32f74da175b1c344d52\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте. Смотря какая скидка.\\nЗвоните по вопросам по телефону 89271686925 или 89379621718\",\"offers\":[]},\"createdAt\":\"2024-09-26T03:46:41.664418+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300836901,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300836901/\",\"title\":\"Дом, 75,7 м²\",\"address\":\"Саратовская область, Красноармейск муниципальное образование, с. Ревино, улица Садовая, 2\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2150548187-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2150548187-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63973944\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-26 06:46:42.379868",
            "update_at": "2024-09-26 06:46:42.379868",
            "create_at_original": "2024-09-26 06:44:30",
            "update_at_original": "2024-09-26 06:46:41",
            "objectId": 63973944
        },
        {
            "id": 83623,
            "chatid": 95386563,
            "messageid": "ef8561f7065a863dce036c22f706abc5b1124f7f7e285fbd4d0de77df3c54a3d",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95386563,\"offerId\":300836901,\"createdAt\":\"2024-09-26T03:44:30.749522+00:00\",\"updatedAt\":\"2024-09-26T03:44:30.918344+00:00\",\"messages\":[{\"messageId\":\"ef8561f7065a863dce036c22f706abc5b1124f7f7e285fbd4d0de77df3c54a3d\",\"userId\":48504235,\"direction\":\"in\",\"content\":{\"text\":\"Доброе утро, скидка есть на дом .\",\"offers\":[]},\"createdAt\":\"2024-09-26T03:44:30.918344+00:00\"}]}],\"users\":[{\"userId\":48504235,\"name\":\"Надежда Пурим\",\"firstName\":\"Надежда\",\"lastName\":\"Пурим\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=48504235&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=48504235&t=2&p=2\"}}}],\"offers\":[{\"id\":300836901,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300836901/\",\"title\":\"Дом, 75,7 м²\",\"address\":\"Саратовская область, Красноармейск муниципальное образование, с. Ревино, улица Садовая, 2\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2150548187-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2150548187-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63973944\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-26 06:44:31.685437",
            "update_at": "2024-09-26 06:44:31.685437",
            "create_at_original": "2024-09-26 06:44:30",
            "update_at_original": "2024-09-26 06:44:30",
            "objectId": 63973944
        },
        {
            "id": 83534,
            "chatid": 95340801,
            "messageid": "5d80b27a3b4969ee72445ecfeae5002f0bdc3c859a02062b08b69d7d6a7d8944",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95340801,\"offerId\":307755175,\"createdAt\":\"2024-09-25T14:24:09.997268+00:00\",\"updatedAt\":\"2024-09-25T14:50:27.308885+00:00\",\"messages\":[{\"messageId\":\"5d80b27a3b4969ee72445ecfeae5002f0bdc3c859a02062b08b69d7d6a7d8944\",\"userId\":116723160,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте! При продаже остаётся кухня (шкафчики, тумбочки)плита+раковина, вытяжка, кух. уголок, водонагреватель, шкаф купе, стол письменный в комнате. Если что-то нужно ещё, то по договорённости\\nТел. для связи +7964 997 37 26\",\"offers\":[]},\"createdAt\":\"2024-09-25T14:50:27.308885+00:00\"}]}],\"users\":[{\"userId\":116723160,\"name\":\"Мария Тарасова\",\"firstName\":\"Мария\",\"lastName\":\"Тарасова\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=116723160&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=116723160&t=2&p=2\"}}}],\"offers\":[{\"id\":307755175,\"userId\":116723160,\"url\":\"https://cian.ru/sale/flat/307755175/\",\"title\":\"1-комн. кв., 32,4 м², 2/9 этаж\",\"address\":\"Саратовская область, Саратов, Кавказский проезд, 6а\",\"publishedUserId\":116723160,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2279636448-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2279636448-1.jpg\"},\"price\":\"3 600 000 ₽\",\"externalId\":\"72394407\"}]}",
            "user_id": 17920,
            "agency_id": 14103,
            "created_at": "2024-09-25 17:50:28.060383",
            "update_at": "2024-09-25 17:50:28.060383",
            "create_at_original": "2024-09-25 17:24:09",
            "update_at_original": "2024-09-25 17:50:27",
            "objectId": 72394407
        },
        {
            "id": 83524,
            "chatid": 95340801,
            "messageid": "8e2204d600344d6d6d26e81691fd57c0f23afaff8a747ecc71865d2d335da03f",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95340801,\"offerId\":307755175,\"createdAt\":\"2024-09-25T14:24:09.997268+00:00\",\"updatedAt\":\"2024-09-25T14:24:10.120056+00:00\",\"messages\":[{\"messageId\":\"8e2204d600344d6d6d26e81691fd57c0f23afaff8a747ecc71865d2d335da03f\",\"userId\":5540924,\"direction\":\"in\",\"content\":{\"text\":\"Что остается новым жильцам\",\"offers\":[]},\"createdAt\":\"2024-09-25T14:24:10.120056+00:00\"}]}],\"users\":[{\"userId\":5540924,\"name\":\"Елена Фалтейсек\",\"firstName\":\"Елена\",\"lastName\":\"Фалтейсек\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=5540924&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=5540924&t=2&p=2\"}}}],\"offers\":[{\"id\":307755175,\"userId\":116723160,\"url\":\"https://cian.ru/sale/flat/307755175/\",\"title\":\"1-комн. кв., 32,4 м², 2/9 этаж\",\"address\":\"Саратовская область, Саратов, Кавказский проезд, 6а\",\"publishedUserId\":116723160,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2279636448-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2279636448-1.jpg\"},\"price\":\"3 600 000 ₽\",\"externalId\":\"72394407\"}]}",
            "user_id": 17920,
            "agency_id": 14103,
            "created_at": "2024-09-25 17:24:10.949718",
            "update_at": "2024-09-25 17:24:10.949718",
            "create_at_original": "2024-09-25 17:24:09",
            "update_at_original": "2024-09-25 17:24:10",
            "objectId": 72394407
        },
        {
            "id": 83517,
            "chatid": 95337374,
            "messageid": "64e85df4719b6a25437c825e94107831b3c8d25650a2c48da22ee9f3d6f46652",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95337374,\"offerId\":307568517,\"createdAt\":\"2024-09-25T14:00:08.256206+00:00\",\"updatedAt\":\"2024-09-25T14:00:08.426682+00:00\",\"messages\":[{\"messageId\":\"64e85df4719b6a25437c825e94107831b3c8d25650a2c48da22ee9f3d6f46652\",\"userId\":118737792,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Подскажите пожалуйста какой статус дома по документам.\",\"offers\":[]},\"createdAt\":\"2024-09-25T14:00:08.426682+00:00\"}]}],\"users\":[{\"userId\":118737792,\"name\":\"118737792\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=118737792&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=118737792&t=2&p=2\"}}}],\"offers\":[{\"id\":307568517,\"userId\":115760105,\"url\":\"https://cian.ru/sale/suburban/307568517/\",\"title\":\"Дом, 82 м²\",\"address\":\"Саратовская область, Воскресенский район, Елшанское муниципальное образование, Росинка 2002 СТ\",\"publishedUserId\":115760105,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2274322068-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2274322068-1.jpg\"},\"price\":\"1 200 000 ₽\",\"externalId\":\"72127683\"}]}",
            "user_id": 17706,
            "agency_id": 14103,
            "created_at": "2024-09-25 17:00:09.256589",
            "update_at": "2024-09-25 17:00:09.256589",
            "create_at_original": "2024-09-25 17:00:08",
            "update_at_original": "2024-09-25 17:00:08",
            "objectId": 72127683
        },
        {
            "id": 83465,
            "chatid": 95304336,
            "messageid": "ecfd99063c80fa5126b157759d73b1cbd175dfc72f76afd000fd9545f1df516f",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95304336,\"offerId\":300860652,\"createdAt\":\"2024-09-25T10:08:33.084579+00:00\",\"updatedAt\":\"2024-09-25T10:58:36.197982+00:00\",\"messages\":[{\"messageId\":\"ecfd99063c80fa5126b157759d73b1cbd175dfc72f76afd000fd9545f1df516f\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Да, давайте созвонимся ближе к концу недели\",\"offers\":[]},\"createdAt\":\"2024-09-25T10:58:36.197982+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-25 13:58:38.012944",
            "update_at": "2024-09-25 13:58:38.012944",
            "create_at_original": "2024-09-25 13:08:33",
            "update_at_original": "2024-09-25 13:58:36",
            "objectId": 63977339
        },
        {
            "id": 83464,
            "chatid": 95304336,
            "messageid": "7a8b25c419441f3317696cec7ba0038bb11c936b005cb90780503db9a6b857db",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95304336,\"offerId\":300860652,\"createdAt\":\"2024-09-25T10:08:33.084579+00:00\",\"updatedAt\":\"2024-09-25T10:55:22.192937+00:00\",\"messages\":[{\"messageId\":\"7a8b25c419441f3317696cec7ba0038bb11c936b005cb90780503db9a6b857db\",\"userId\":121801162,\"direction\":\"in\",\"content\":{\"text\":\"хорошо .Тогда когда вам позвонить в пт?\",\"offers\":[]},\"createdAt\":\"2024-09-25T10:55:22.192937+00:00\"}]}],\"users\":[{\"userId\":121801162,\"name\":\"Эвелина\",\"firstName\":\"Эвелина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=121801162&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=121801162&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-25 13:55:22.983034",
            "update_at": "2024-09-25 13:55:22.983034",
            "create_at_original": "2024-09-25 13:08:33",
            "update_at_original": "2024-09-25 13:55:22",
            "objectId": 63977339
        },
        {
            "id": 83463,
            "chatid": 95304336,
            "messageid": "77757ffb82181d477cbadbd6f2e6e57e4734532bb854d53cd45e08a24cf2e750",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95304336,\"offerId\":300860652,\"createdAt\":\"2024-09-25T10:08:33.084579+00:00\",\"updatedAt\":\"2024-09-25T10:53:25.329294+00:00\",\"messages\":[{\"messageId\":\"77757ffb82181d477cbadbd6f2e6e57e4734532bb854d53cd45e08a24cf2e750\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Вода в доме есть\",\"offers\":[]},\"createdAt\":\"2024-09-25T10:53:25.329294+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-25 13:53:31.541414",
            "update_at": "2024-09-25 13:53:31.541414",
            "create_at_original": "2024-09-25 13:08:33",
            "update_at_original": "2024-09-25 13:53:25",
            "objectId": 63977339
        },
        {
            "id": 83462,
            "chatid": 95304336,
            "messageid": "6fff9c4e4d549a01b5e368f209da6998f08ec5ca20340ae7802b52dc59d3daf8",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95304336,\"offerId\":300860652,\"createdAt\":\"2024-09-25T10:08:33.084579+00:00\",\"updatedAt\":\"2024-09-25T10:53:03.798765+00:00\",\"messages\":[{\"messageId\":\"6fff9c4e4d549a01b5e368f209da6998f08ec5ca20340ae7802b52dc59d3daf8\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Мой номер для связи 89271686925 и 89379621718\",\"offers\":[]},\"createdAt\":\"2024-09-25T10:53:03.798765+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-25 13:53:04.648597",
            "update_at": "2024-09-25 13:53:04.648597",
            "create_at_original": "2024-09-25 13:08:33",
            "update_at_original": "2024-09-25 13:53:03",
            "objectId": 63977339
        },
        {
            "id": 83461,
            "chatid": 95304336,
            "messageid": "24496a3a47f259cc78f2ffdf1551c4aa62ad125e46b91a25cda7a65519d6b81b",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95304336,\"offerId\":300860652,\"createdAt\":\"2024-09-25T10:08:33.084579+00:00\",\"updatedAt\":\"2024-09-25T10:52:45.590505+00:00\",\"messages\":[{\"messageId\":\"24496a3a47f259cc78f2ffdf1551c4aa62ad125e46b91a25cda7a65519d6b81b\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте. Да, организуем показ в выходные\",\"offers\":[]},\"createdAt\":\"2024-09-25T10:52:45.590505+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-25 13:52:46.186955",
            "update_at": "2024-09-25 13:52:46.186955",
            "create_at_original": "2024-09-25 13:08:33",
            "update_at_original": "2024-09-25 13:52:45",
            "objectId": 63977339
        },
        {
            "id": 83442,
            "chatid": 95304336,
            "messageid": "abcdb739f8a3c45b0e17f865fe628c3dd0342e57bfe87c69049a5fc3dd683920",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95304336,\"offerId\":300860652,\"createdAt\":\"2024-09-25T10:08:33.084579+00:00\",\"updatedAt\":\"2024-09-25T10:10:17.159047+00:00\",\"messages\":[{\"messageId\":\"abcdb739f8a3c45b0e17f865fe628c3dd0342e57bfe87c69049a5fc3dd683920\",\"userId\":121801162,\"direction\":\"in\",\"content\":{\"text\":\"Скажите еще вода проведена в дом ?\",\"offers\":[]},\"createdAt\":\"2024-09-25T10:10:17.159047+00:00\"}]}],\"users\":[{\"userId\":121801162,\"name\":\"Эвелина\",\"firstName\":\"Эвелина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=121801162&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=121801162&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-25 13:10:18.076288",
            "update_at": "2024-09-25 13:10:18.076288",
            "create_at_original": "2024-09-25 13:08:33",
            "update_at_original": "2024-09-25 13:10:17",
            "objectId": 63977339
        },
        {
            "id": 83437,
            "chatid": 95304336,
            "messageid": "2f71df1246dac72c32290015e0840d8eee5bade09f31db774fc2f7e0ac5f4180",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95304336,\"offerId\":300860652,\"createdAt\":\"2024-09-25T10:08:33.084579+00:00\",\"updatedAt\":\"2024-09-25T10:08:33.212548+00:00\",\"messages\":[{\"messageId\":\"2f71df1246dac72c32290015e0840d8eee5bade09f31db774fc2f7e0ac5f4180\",\"userId\":121801162,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте . Скажите дом в собственности ?Можно дом посмотреть в сб или вс?\",\"offers\":[]},\"createdAt\":\"2024-09-25T10:08:33.212548+00:00\"}]}],\"users\":[{\"userId\":121801162,\"name\":\"Эвелина\",\"firstName\":\"Эвелина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=121801162&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=121801162&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-25 13:08:33.914556",
            "update_at": "2024-09-25 13:08:33.914556",
            "create_at_original": "2024-09-25 13:08:33",
            "update_at_original": "2024-09-25 13:08:33",
            "objectId": 63977339
        },
        {
            "id": 83410,
            "chatid": 95292772,
            "messageid": "2d4b3fe320cbcb7b5e852ee4202e1fb2f83fce4339ea1013a2ed38c1eac9f221",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95292772,\"offerId\":307611197,\"createdAt\":\"2024-09-25T08:53:41.808209+00:00\",\"updatedAt\":\"2024-09-25T09:01:56.095358+00:00\",\"messages\":[{\"messageId\":\"2d4b3fe320cbcb7b5e852ee4202e1fb2f83fce4339ea1013a2ed38c1eac9f221\",\"userId\":115540687,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте.  Нет.\",\"offers\":[]},\"createdAt\":\"2024-09-25T09:01:56.095358+00:00\"}]}],\"users\":[{\"userId\":115540687,\"name\":\"Алексей Савин\",\"firstName\":\"Алексей\",\"lastName\":\"Савин\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115540687&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115540687&t=2&p=2\"}}}],\"offers\":[{\"id\":307611197,\"userId\":115540687,\"url\":\"https://cian.ru/sale/commercial/307611197/\",\"title\":\"Офис, 85,3 м², 1/10 этаж\",\"address\":\"Саратовская область, Энгельс, Пролетарская улица, 1\",\"publishedUserId\":115540687,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2275591104-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2275591104-1.jpg\"},\"price\":\"5 990 000 ₽\",\"externalId\":\"64042896\"}]}",
            "user_id": 17608,
            "agency_id": 14103,
            "created_at": "2024-09-25 12:01:56.794721",
            "update_at": "2024-09-25 12:01:56.794721",
            "create_at_original": "2024-09-25 11:53:41",
            "update_at_original": "2024-09-25 12:01:56",
            "objectId": 64042896
        },
        {
            "id": 83408,
            "chatid": 95292772,
            "messageid": "3cda931f17c8d34b4b3a43e4a6166a771310b7b4d22662e43f19991a794dcf36",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95292772,\"offerId\":307611197,\"createdAt\":\"2024-09-25T08:53:41.808209+00:00\",\"updatedAt\":\"2024-09-25T08:53:42.046006+00:00\",\"messages\":[{\"messageId\":\"3cda931f17c8d34b4b3a43e4a6166a771310b7b4d22662e43f19991a794dcf36\",\"userId\":121628381,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте, план есть помещения?\",\"offers\":[]},\"createdAt\":\"2024-09-25T08:53:42.046006+00:00\"}]}],\"users\":[{\"userId\":121628381,\"name\":\"121628381\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=121628381&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=121628381&t=2&p=2\"}}}],\"offers\":[{\"id\":307611197,\"userId\":115540687,\"url\":\"https://cian.ru/sale/commercial/307611197/\",\"title\":\"Офис, 85,3 м², 1/10 этаж\",\"address\":\"Саратовская область, Энгельс, Пролетарская улица, 1\",\"publishedUserId\":115540687,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2275591104-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2275591104-1.jpg\"},\"price\":\"5 990 000 ₽\",\"externalId\":\"64042896\"}]}",
            "user_id": 17608,
            "agency_id": 14103,
            "created_at": "2024-09-25 11:53:44.1646",
            "update_at": "2024-09-25 11:53:44.1646",
            "create_at_original": "2024-09-25 11:53:41",
            "update_at_original": "2024-09-25 11:53:42",
            "objectId": 64042896
        },
        {
            "id": 83369,
            "chatid": 95236018,
            "messageid": "e61ce69095b59dac5db9591de0e767a327413886f90656f227b953c864f19030",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95236018,\"offerId\":307390058,\"createdAt\":\"2024-09-24T18:17:46.641988+00:00\",\"updatedAt\":\"2024-09-25T06:56:40.805904+00:00\",\"messages\":[{\"messageId\":\"e61ce69095b59dac5db9591de0e767a327413886f90656f227b953c864f19030\",\"userId\":104093587,\"direction\":\"out\",\"content\":{\"text\":\"Добрый! \\nАктуально, звоните по номеру 8-937-024-1718 Екатерина\\nЛибо оставьте свой!\",\"offers\":[]},\"createdAt\":\"2024-09-25T06:56:40.805904+00:00\"}]}],\"users\":[{\"userId\":104093587,\"name\":\"Екатерина Гаврилова\",\"firstName\":\"Екатерина\",\"lastName\":\"Гаврилова\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=104093587&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=104093587&t=2&p=2\"}}}],\"offers\":[{\"id\":307390058,\"userId\":104093587,\"url\":\"https://cian.ru/sale/flat/307390058/\",\"title\":\"3-комн. кв., 62,4 м², 2/9 этаж\",\"address\":\"Саратовская область, Энгельс, Степная улица, 39\",\"publishedUserId\":104093587,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2269941194-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2269941194-1.jpg\"},\"price\":\"4 250 000 ₽\",\"externalId\":\"56438609\"}]}",
            "user_id": 14822,
            "agency_id": 14103,
            "created_at": "2024-09-25 09:56:41.579113",
            "update_at": "2024-09-25 09:56:41.579113",
            "create_at_original": "2024-09-24 21:17:46",
            "update_at_original": "2024-09-25 09:56:40",
            "objectId": 56438609
        },
        {
            "id": 83286,
            "chatid": 95236018,
            "messageid": "ea5f03ee3c98d7e2359f53ca45be28b026c3174bd24f86dc85a710260c4ec901",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95236018,\"offerId\":307390058,\"createdAt\":\"2024-09-24T18:17:46.641988+00:00\",\"updatedAt\":\"2024-09-24T18:17:46.753613+00:00\",\"messages\":[{\"messageId\":\"ea5f03ee3c98d7e2359f53ca45be28b026c3174bd24f86dc85a710260c4ec901\",\"userId\":96627846,\"direction\":\"in\",\"content\":{\"text\":\"Добрый день \\nОбъявление еще актуально?\",\"offers\":[]},\"createdAt\":\"2024-09-24T18:17:46.753613+00:00\"}]}],\"users\":[{\"userId\":96627846,\"name\":\"96627846\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=96627846&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=96627846&t=2&p=2\"}}}],\"offers\":[{\"id\":307390058,\"userId\":104093587,\"url\":\"https://cian.ru/sale/flat/307390058/\",\"title\":\"3-комн. кв., 62,4 м², 2/9 этаж\",\"address\":\"Саратовская область, Энгельс, Степная улица, 39\",\"publishedUserId\":104093587,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2269941194-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2269941194-1.jpg\"},\"price\":\"4 250 000 ₽\",\"externalId\":\"56438609\"}]}",
            "user_id": 14822,
            "agency_id": 14103,
            "created_at": "2024-09-24 21:17:53.422785",
            "update_at": "2024-09-24 21:17:53.422785",
            "create_at_original": "2024-09-24 21:17:46",
            "update_at_original": "2024-09-24 21:17:46",
            "objectId": 56438609
        },
        {
            "id": 83119,
            "chatid": 95154597,
            "messageid": "314666f5d893d9c0ffecfdfa0aa09b104cc115ed8dddf8b0a4cd5e86e118d4c3",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95154597,\"offerId\":307616727,\"createdAt\":\"2024-09-24T07:34:12.926257+00:00\",\"updatedAt\":\"2024-09-24T07:39:22.054801+00:00\",\"messages\":[{\"messageId\":\"314666f5d893d9c0ffecfdfa0aa09b104cc115ed8dddf8b0a4cd5e86e118d4c3\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Добрый день! Квартиру сдала, с рекламы сняла\",\"offers\":[]},\"createdAt\":\"2024-09-24T07:39:22.054801+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":307616727,\"userId\":115390423,\"url\":\"https://cian.ru/rent/flat/307616727/\",\"title\":\"1-комн. кв., 35 м², 2/5 этаж\",\"address\":\"Саратовская область, Энгельс, 2-й мкр, 32\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2275682540-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2275682540-1.jpg\"},\"price\":\"15 000 ₽/мес.\",\"externalId\":\"72218418\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-24 10:39:22.872386",
            "update_at": "2024-09-24 10:39:22.872386",
            "create_at_original": "2024-09-24 10:34:12",
            "update_at_original": "2024-09-24 10:39:22",
            "objectId": 72218418
        },
        {
            "id": 83117,
            "chatid": 95154597,
            "messageid": "01840cb68aa194b5051c3d7d58b08c54675d7cef129764de146b261924d640c0",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95154597,\"offerId\":307616727,\"createdAt\":\"2024-09-24T07:34:12.926257+00:00\",\"updatedAt\":\"2024-09-24T07:34:13.141442+00:00\",\"messages\":[{\"messageId\":\"01840cb68aa194b5051c3d7d58b08c54675d7cef129764de146b261924d640c0\",\"userId\":122012636,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте, квартира ещё сдается? хотим снять на месяц 2 человека национальность: русские\",\"offers\":[]},\"createdAt\":\"2024-09-24T07:34:13.141442+00:00\"}]}],\"users\":[{\"userId\":122012636,\"name\":\"122012636\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=122012636&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=122012636&t=2&p=2\"}}}],\"offers\":[{\"id\":307616727,\"userId\":115390423,\"url\":\"https://cian.ru/rent/flat/307616727/\",\"title\":\"1-комн. кв., 35 м², 2/5 этаж\",\"address\":\"Саратовская область, Энгельс, 2-й мкр, 32\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2275682540-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2275682540-1.jpg\"},\"price\":\"15 000 ₽/мес.\",\"externalId\":\"72218418\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-24 10:34:13.905409",
            "update_at": "2024-09-24 10:34:13.905409",
            "create_at_original": "2024-09-24 10:34:12",
            "update_at_original": "2024-09-24 10:34:13",
            "objectId": 72218418
        },
        {
            "id": 82793,
            "chatid": 95048422,
            "messageid": "62adea4ca199ad82658dc455dcf66c6327cfc8f40f0ce2cb139616f21650d920",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95048422,\"offerId\":307215756,\"createdAt\":\"2024-09-23T10:31:22.466255+00:00\",\"updatedAt\":\"2024-09-23T10:31:22.721859+00:00\",\"messages\":[{\"messageId\":\"62adea4ca199ad82658dc455dcf66c6327cfc8f40f0ce2cb139616f21650d920\",\"userId\":15997980,\"direction\":\"in\",\"content\":{\"text\":\"Добрый день, фото с/у есть?\",\"offers\":[]},\"createdAt\":\"2024-09-23T10:31:22.721859+00:00\"}]}],\"users\":[{\"userId\":15997980,\"name\":\"Анастасия Таранова\",\"firstName\":\"Анастасия\",\"lastName\":\"Таранова\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=15997980&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=15997980&t=2&p=2\"}}}],\"offers\":[{\"id\":307215756,\"userId\":104181819,\"url\":\"https://cian.ru/sale/flat/307215756/\",\"title\":\"3-комн. кв., 57 м², 2/9 этаж\",\"address\":\"Саратовская область, Энгельс, Полтавская улица, 44\",\"publishedUserId\":104181819,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2265554607-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2265554607-1.jpg\"},\"price\":\"2 400 000 ₽\",\"externalId\":\"71436941\"}]}",
            "user_id": 14828,
            "agency_id": 14103,
            "created_at": "2024-09-23 13:31:23.610333",
            "update_at": "2024-09-23 13:31:23.610333",
            "create_at_original": "2024-09-23 13:31:22",
            "update_at_original": "2024-09-23 13:31:22",
            "objectId": 71436941
        },
        {
            "id": 82697,
            "chatid": 95010825,
            "messageid": "0cf2a31178bea1676c41a24880aa7a5195bb2fed86166851a3412a2c7612f2e2",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95010825,\"offerId\":307616727,\"createdAt\":\"2024-09-23T05:52:58.819673+00:00\",\"updatedAt\":\"2024-09-23T05:57:03.233548+00:00\",\"messages\":[{\"messageId\":\"0cf2a31178bea1676c41a24880aa7a5195bb2fed86166851a3412a2c7612f2e2\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте. Да, звоните те по номеру 89271686925\",\"offers\":[]},\"createdAt\":\"2024-09-23T05:57:03.233548+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":307616727,\"userId\":115390423,\"url\":\"https://cian.ru/rent/flat/307616727/\",\"title\":\"1-комн. кв., 35 м², 2/5 этаж\",\"address\":\"Саратовская область, Энгельс, 2-й мкр, 32\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2275682540-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2275682540-1.jpg\"},\"price\":\"15 000 ₽/мес.\",\"externalId\":\"72218418\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-23 08:57:09.272703",
            "update_at": "2024-09-23 08:57:09.272703",
            "create_at_original": "2024-09-23 08:52:58",
            "update_at_original": "2024-09-23 08:57:03",
            "objectId": 72218418
        },
        {
            "id": 82696,
            "chatid": 95010825,
            "messageid": "033afc3441e4c77056616dcaa8b4704a8fd680ee5b5257db254bd767d60df1d9",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":95010825,\"offerId\":307616727,\"createdAt\":\"2024-09-23T05:52:58.819673+00:00\",\"updatedAt\":\"2024-09-23T05:52:58.897489+00:00\",\"messages\":[{\"messageId\":\"033afc3441e4c77056616dcaa8b4704a8fd680ee5b5257db254bd767d60df1d9\",\"userId\":122063766,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Уточните, пожалуйста, ещё сдаёте?\",\"offers\":[]},\"createdAt\":\"2024-09-23T05:52:58.897489+00:00\"}]}],\"users\":[{\"userId\":122063766,\"name\":\"122063766\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=122063766&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=122063766&t=2&p=2\"}}}],\"offers\":[{\"id\":307616727,\"userId\":115390423,\"url\":\"https://cian.ru/rent/flat/307616727/\",\"title\":\"1-комн. кв., 35 м², 2/5 этаж\",\"address\":\"Саратовская область, Энгельс, 2-й мкр, 32\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2275682540-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2275682540-1.jpg\"},\"price\":\"15 000 ₽/мес.\",\"externalId\":\"72218418\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-23 08:52:59.617914",
            "update_at": "2024-09-23 08:52:59.617914",
            "create_at_original": "2024-09-23 08:52:58",
            "update_at_original": "2024-09-23 08:52:58",
            "objectId": 72218418
        },
        {
            "id": 82689,
            "chatid": 94984084,
            "messageid": "e56619b880e0a056ffe8cc6fd3f3037731774d512a488f8027fdbc1a508f1f66",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94984084,\"offerId\":307616727,\"createdAt\":\"2024-09-22T19:24:42.499935+00:00\",\"updatedAt\":\"2024-09-23T03:43:44.363998+00:00\",\"messages\":[{\"messageId\":\"e56619b880e0a056ffe8cc6fd3f3037731774d512a488f8027fdbc1a508f1f66\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Звоните по номеру 89271686925\",\"offers\":[]},\"createdAt\":\"2024-09-23T03:43:44.363998+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":307616727,\"userId\":115390423,\"url\":\"https://cian.ru/rent/flat/307616727/\",\"title\":\"1-комн. кв., 35 м², 2/5 этаж\",\"address\":\"Саратовская область, Энгельс, 2-й мкр, 32\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2275682540-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2275682540-1.jpg\"},\"price\":\"15 000 ₽/мес.\",\"externalId\":\"72218418\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-23 06:43:45.027818",
            "update_at": "2024-09-23 06:43:45.027818",
            "create_at_original": "2024-09-22 22:24:42",
            "update_at_original": "2024-09-23 06:43:44",
            "objectId": 72218418
        },
        {
            "id": 82688,
            "chatid": 94984084,
            "messageid": "64ae2762a8f1fdb6bc0b8ce5ff956a340264d32291048e34677cd4cb5f67fbde",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94984084,\"offerId\":307616727,\"createdAt\":\"2024-09-22T19:24:42.499935+00:00\",\"updatedAt\":\"2024-09-23T03:43:20.347539+00:00\",\"messages\":[{\"messageId\":\"64ae2762a8f1fdb6bc0b8ce5ff956a340264d32291048e34677cd4cb5f67fbde\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте. Да\",\"offers\":[]},\"createdAt\":\"2024-09-23T03:43:20.347539+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":307616727,\"userId\":115390423,\"url\":\"https://cian.ru/rent/flat/307616727/\",\"title\":\"1-комн. кв., 35 м², 2/5 этаж\",\"address\":\"Саратовская область, Энгельс, 2-й мкр, 32\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2275682540-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2275682540-1.jpg\"},\"price\":\"15 000 ₽/мес.\",\"externalId\":\"72218418\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-23 06:43:21.054859",
            "update_at": "2024-09-23 06:43:21.054859",
            "create_at_original": "2024-09-22 22:24:42",
            "update_at_original": "2024-09-23 06:43:20",
            "objectId": 72218418
        },
        {
            "id": 82676,
            "chatid": 94984084,
            "messageid": "c96e09e406d3fb50d4168e2417fa72ed336062eda7c84e950a8d09271e58b80e",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94984084,\"offerId\":307616727,\"createdAt\":\"2024-09-22T19:24:42.499935+00:00\",\"updatedAt\":\"2024-09-22T19:24:53.927640+00:00\",\"messages\":[{\"messageId\":\"c96e09e406d3fb50d4168e2417fa72ed336062eda7c84e950a8d09271e58b80e\",\"userId\":96623578,\"direction\":\"in\",\"content\":{\"text\":\"Пожалуйста, позвоните, когда будет удобно. Вот мой номер\\n+79951156863\",\"offers\":[]},\"createdAt\":\"2024-09-22T19:24:53.927640+00:00\"}]}],\"users\":[{\"userId\":96623578,\"name\":\"Игорь  Кембель\",\"firstName\":\"Игорь \",\"lastName\":\"Кембель\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=96623578&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=96623578&t=2&p=2\"}}}],\"offers\":[{\"id\":307616727,\"userId\":115390423,\"url\":\"https://cian.ru/rent/flat/307616727/\",\"title\":\"1-комн. кв., 35 м², 2/5 этаж\",\"address\":\"Саратовская область, Энгельс, 2-й мкр, 32\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2275682540-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2275682540-1.jpg\"},\"price\":\"15 000 ₽/мес.\",\"externalId\":\"72218418\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-22 22:24:54.739955",
            "update_at": "2024-09-22 22:24:54.739955",
            "create_at_original": "2024-09-22 22:24:42",
            "update_at_original": "2024-09-22 22:24:53",
            "objectId": 72218418
        },
        {
            "id": 82675,
            "chatid": 94984084,
            "messageid": "3e5da4b1485ef76b5b1b561060ba094da83e8a1e1ad79ccb6c46b3265ebb2a1c",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94984084,\"offerId\":307616727,\"createdAt\":\"2024-09-22T19:24:42.499935+00:00\",\"updatedAt\":\"2024-09-22T19:24:42.685983+00:00\",\"messages\":[{\"messageId\":\"3e5da4b1485ef76b5b1b561060ba094da83e8a1e1ad79ccb6c46b3265ebb2a1c\",\"userId\":96623578,\"direction\":\"in\",\"content\":{\"text\":\"Анастасия, здравствуйте объявления актуально?\",\"offers\":[]},\"createdAt\":\"2024-09-22T19:24:42.685983+00:00\"}]}],\"users\":[{\"userId\":96623578,\"name\":\"Игорь  Кембель\",\"firstName\":\"Игорь \",\"lastName\":\"Кембель\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=96623578&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=96623578&t=2&p=2\"}}}],\"offers\":[{\"id\":307616727,\"userId\":115390423,\"url\":\"https://cian.ru/rent/flat/307616727/\",\"title\":\"1-комн. кв., 35 м², 2/5 этаж\",\"address\":\"Саратовская область, Энгельс, 2-й мкр, 32\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2275682540-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2275682540-1.jpg\"},\"price\":\"15 000 ₽/мес.\",\"externalId\":\"72218418\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-22 22:24:43.53289",
            "update_at": "2024-09-22 22:24:43.53289",
            "create_at_original": "2024-09-22 22:24:42",
            "update_at_original": "2024-09-22 22:24:42",
            "objectId": 72218418
        },
        {
            "id": 82584,
            "chatid": 94946645,
            "messageid": "7a03188879aac4e2827e5ca214a203890d5336460dbb309d8a323b56c7a0f892",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94946645,\"offerId\":307215756,\"createdAt\":\"2024-09-22T12:44:45.213359+00:00\",\"updatedAt\":\"2024-09-22T13:27:12.553931+00:00\",\"messages\":[{\"messageId\":\"7a03188879aac4e2827e5ca214a203890d5336460dbb309d8a323b56c7a0f892\",\"userId\":60232299,\"direction\":\"in\",\"content\":{\"text\":\"Какой долг?\",\"offers\":[]},\"createdAt\":\"2024-09-22T13:27:12.553931+00:00\"}]}],\"users\":[{\"userId\":60232299,\"name\":\"60232299\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=60232299&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=60232299&t=2&p=2\"}}}],\"offers\":[{\"id\":307215756,\"userId\":104181819,\"url\":\"https://cian.ru/sale/flat/307215756/\",\"title\":\"3-комн. кв., 57 м², 2/9 этаж\",\"address\":\"Саратовская область, Энгельс, Полтавская улица, 44\",\"publishedUserId\":104181819,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2265554607-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2265554607-1.jpg\"},\"price\":\"2 400 000 ₽\",\"externalId\":\"71436941\"}]}",
            "user_id": 14828,
            "agency_id": 14103,
            "created_at": "2024-09-22 16:27:13.365995",
            "update_at": "2024-09-22 16:27:13.365995",
            "create_at_original": "2024-09-22 15:44:45",
            "update_at_original": "2024-09-22 16:27:12",
            "objectId": 71436941
        },
        {
            "id": 82583,
            "chatid": 94946645,
            "messageid": "4327f2dd4c05564ed872a652fb459d86054fcef7d1b6db5ca61b2f80ec58fe70",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94946645,\"offerId\":307215756,\"createdAt\":\"2024-09-22T12:44:45.213359+00:00\",\"updatedAt\":\"2024-09-22T13:24:04.070168+00:00\",\"messages\":[{\"messageId\":\"4327f2dd4c05564ed872a652fb459d86054fcef7d1b6db5ca61b2f80ec58fe70\",\"userId\":104181819,\"direction\":\"out\",\"content\":{\"text\":\"Добрый день \\nПриставы наложили ограничение на регистрационные действия\",\"offers\":[]},\"createdAt\":\"2024-09-22T13:24:04.070168+00:00\"}]}],\"users\":[{\"userId\":104181819,\"name\":\"Сергей Карпович\",\"firstName\":\"Сергей\",\"lastName\":\"Карпович\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=104181819&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=104181819&t=2&p=2\"}}}],\"offers\":[{\"id\":307215756,\"userId\":104181819,\"url\":\"https://cian.ru/sale/flat/307215756/\",\"title\":\"3-комн. кв., 57 м², 2/9 этаж\",\"address\":\"Саратовская область, Энгельс, Полтавская улица, 44\",\"publishedUserId\":104181819,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2265554607-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2265554607-1.jpg\"},\"price\":\"2 400 000 ₽\",\"externalId\":\"71436941\"}]}",
            "user_id": 14828,
            "agency_id": 14103,
            "created_at": "2024-09-22 16:24:06.542262",
            "update_at": "2024-09-22 16:24:06.542262",
            "create_at_original": "2024-09-22 15:44:45",
            "update_at_original": "2024-09-22 16:24:04",
            "objectId": 71436941
        },
        {
            "id": 82566,
            "chatid": 94946645,
            "messageid": "c94d4f01a4ced8e490fcfc9bd0134d47667bf32d54543be2959e92691d5a1905",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94946645,\"offerId\":307215756,\"createdAt\":\"2024-09-22T12:44:45.213359+00:00\",\"updatedAt\":\"2024-09-22T12:44:45.543349+00:00\",\"messages\":[{\"messageId\":\"c94d4f01a4ced8e490fcfc9bd0134d47667bf32d54543be2959e92691d5a1905\",\"userId\":60232299,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Запрет на рег.действия в связи с чем?\",\"offers\":[]},\"createdAt\":\"2024-09-22T12:44:45.543349+00:00\"}]}],\"users\":[{\"userId\":60232299,\"name\":\"60232299\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=60232299&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=60232299&t=2&p=2\"}}}],\"offers\":[{\"id\":307215756,\"userId\":104181819,\"url\":\"https://cian.ru/sale/flat/307215756/\",\"title\":\"3-комн. кв., 57 м², 2/9 этаж\",\"address\":\"Саратовская область, Энгельс, Полтавская улица, 44\",\"publishedUserId\":104181819,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2265554607-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2265554607-1.jpg\"},\"price\":\"2 400 000 ₽\",\"externalId\":\"71436941\"}]}",
            "user_id": 14828,
            "agency_id": 14103,
            "created_at": "2024-09-22 15:44:46.432897",
            "update_at": "2024-09-22 15:44:46.432897",
            "create_at_original": "2024-09-22 15:44:45",
            "update_at_original": "2024-09-22 15:44:45",
            "objectId": 71436941
        },
        {
            "id": 82501,
            "chatid": 94774060,
            "messageid": "cbb60079c7170fa2b4aa9a86475e40e65bb58a63def936bd9f321adc098d73b6",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94774060,\"offerId\":306704393,\"createdAt\":\"2024-09-20T18:45:45.136754+00:00\",\"updatedAt\":\"2024-09-22T08:12:51.531702+00:00\",\"messages\":[{\"messageId\":\"cbb60079c7170fa2b4aa9a86475e40e65bb58a63def936bd9f321adc098d73b6\",\"userId\":114681854,\"direction\":\"out\",\"content\":{\"text\":\"нет\",\"offers\":[]},\"createdAt\":\"2024-09-22T08:12:51.531702+00:00\"}]}],\"users\":[{\"userId\":114681854,\"name\":\"Юлия Наличникова\",\"firstName\":\"Юлия\",\"lastName\":\"Наличникова\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=114681854&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=114681854&t=2&p=2\"}}}],\"offers\":[{\"id\":306704393,\"userId\":114681854,\"url\":\"https://cian.ru/rent/flat/306704393/\",\"title\":\"1-комн. кв., 37,9 м², 2/10 этаж\",\"address\":\"Саратовская область, Энгельс, улица Шурова Гора, 7/7\",\"publishedUserId\":114681854,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2256119804-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2256119804-1.jpg\"},\"price\":\"20 000 ₽/мес.\",\"externalId\":\"71046454\"}]}",
            "user_id": 14236,
            "agency_id": 14103,
            "created_at": "2024-09-22 11:12:52.566224",
            "update_at": "2024-09-22 11:12:52.566224",
            "create_at_original": "2024-09-20 21:45:45",
            "update_at_original": "2024-09-22 11:12:51",
            "objectId": 71046454
        },
        {
            "id": 82488,
            "chatid": 94909233,
            "messageid": "65b7a72c28ed391fbda7074f08eac6b52ec5e7f7dab41e9146894414ff539211",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94909233,\"offerId\":306882986,\"createdAt\":\"2024-09-22T07:03:57.102598+00:00\",\"updatedAt\":\"2024-09-22T07:05:36.560130+00:00\",\"messages\":[{\"messageId\":\"65b7a72c28ed391fbda7074f08eac6b52ec5e7f7dab41e9146894414ff539211\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Номер для связи 89271686925, звоните, договоримся\",\"offers\":[]},\"createdAt\":\"2024-09-22T07:05:36.560130+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":306882986,\"userId\":115390423,\"url\":\"https://cian.ru/sale/flat/306882986/\",\"title\":\"1-комн. кв., 36,9 м², 1/5 этаж\",\"address\":\"Саратовская область, Красноармейск, 1-й мкр, 5\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2259469216-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2259469216-1.jpg\"},\"price\":\"1 050 000 ₽\",\"externalId\":\"71229158\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-22 10:05:36.924626",
            "update_at": "2024-09-22 10:05:36.924626",
            "create_at_original": "2024-09-22 10:03:57",
            "update_at_original": "2024-09-22 10:05:36",
            "objectId": 71229158
        },
        {
            "id": 82487,
            "chatid": 94909233,
            "messageid": "b1922b95f6771e7483e69cc00e1f219cda70f8090cc986d53792e7f8684796ad",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94909233,\"offerId\":306882986,\"createdAt\":\"2024-09-22T07:03:57.102598+00:00\",\"updatedAt\":\"2024-09-22T07:05:22.439710+00:00\",\"messages\":[{\"messageId\":\"b1922b95f6771e7483e69cc00e1f219cda70f8090cc986d53792e7f8684796ad\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте. Сегодня удобно?\",\"offers\":[]},\"createdAt\":\"2024-09-22T07:05:22.439710+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":306882986,\"userId\":115390423,\"url\":\"https://cian.ru/sale/flat/306882986/\",\"title\":\"1-комн. кв., 36,9 м², 1/5 этаж\",\"address\":\"Саратовская область, Красноармейск, 1-й мкр, 5\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2259469216-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2259469216-1.jpg\"},\"price\":\"1 050 000 ₽\",\"externalId\":\"71229158\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-22 10:05:23.095214",
            "update_at": "2024-09-22 10:05:23.095214",
            "create_at_original": "2024-09-22 10:03:57",
            "update_at_original": "2024-09-22 10:05:22",
            "objectId": 71229158
        },
        {
            "id": 82486,
            "chatid": 94909233,
            "messageid": "f60b4ca706520a776fa0f5eb5ef7cedfa732ffa6ddd91a8ea468b7f5abe81c6b",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94909233,\"offerId\":306882986,\"createdAt\":\"2024-09-22T07:03:57.102598+00:00\",\"updatedAt\":\"2024-09-22T07:03:57.225624+00:00\",\"messages\":[{\"messageId\":\"f60b4ca706520a776fa0f5eb5ef7cedfa732ffa6ddd91a8ea468b7f5abe81c6b\",\"userId\":104145407,\"direction\":\"in\",\"content\":{\"text\":\"Добрый день, когда можно посмотреть квартиру?\",\"offers\":[]},\"createdAt\":\"2024-09-22T07:03:57.225624+00:00\"}]}],\"users\":[{\"userId\":104145407,\"name\":\"Илья  Морозов\",\"firstName\":\"Илья \",\"lastName\":\"Морозов \",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=104145407&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=104145407&t=2&p=2\"}}}],\"offers\":[{\"id\":306882986,\"userId\":115390423,\"url\":\"https://cian.ru/sale/flat/306882986/\",\"title\":\"1-комн. кв., 36,9 м², 1/5 этаж\",\"address\":\"Саратовская область, Красноармейск, 1-й мкр, 5\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2259469216-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2259469216-1.jpg\"},\"price\":\"1 050 000 ₽\",\"externalId\":\"71229158\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-22 10:03:58.009403",
            "update_at": "2024-09-22 10:03:58.009403",
            "create_at_original": "2024-09-22 10:03:57",
            "update_at_original": "2024-09-22 10:03:57",
            "objectId": 71229158
        },
        {
            "id": 82205,
            "chatid": 94774060,
            "messageid": "a018032d9d2ea53b2f28562fa18ff9a3392c812207d89219cdb50b57bc10b3a3",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94774060,\"offerId\":306704393,\"createdAt\":\"2024-09-20T18:45:45.136754+00:00\",\"updatedAt\":\"2024-09-20T18:46:21.140718+00:00\",\"messages\":[{\"messageId\":\"a018032d9d2ea53b2f28562fa18ff9a3392c812207d89219cdb50b57bc10b3a3\",\"userId\":121585710,\"direction\":\"in\",\"content\":{\"text\":\"Можно ли платить залог в рассрочку?\",\"offers\":[]},\"createdAt\":\"2024-09-20T18:46:21.140718+00:00\"}]}],\"users\":[{\"userId\":121585710,\"name\":\"Руслан\",\"firstName\":\"Руслан\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=121585710&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=121585710&t=2&p=2\"}}}],\"offers\":[{\"id\":306704393,\"userId\":114681854,\"url\":\"https://cian.ru/rent/flat/306704393/\",\"title\":\"1-комн. кв., 37,9 м², 2/10 этаж\",\"address\":\"Саратовская область, Энгельс, улица Шурова Гора, 7/7\",\"publishedUserId\":114681854,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2256119804-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2256119804-1.jpg\"},\"price\":\"20 000 ₽/мес.\",\"externalId\":\"71046454\"}]}",
            "user_id": 14236,
            "agency_id": 14103,
            "created_at": "2024-09-20 21:46:21.890534",
            "update_at": "2024-09-20 21:46:21.890534",
            "create_at_original": "2024-09-20 21:45:45",
            "update_at_original": "2024-09-20 21:46:21",
            "objectId": 71046454
        },
        {
            "id": 82204,
            "chatid": 94774060,
            "messageid": "fa52578d630ba3babf3b5c4a4422fed24663f1da0828332d2537a30f170d1a96",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94774060,\"offerId\":306704393,\"createdAt\":\"2024-09-20T18:45:45.136754+00:00\",\"updatedAt\":\"2024-09-20T18:45:45.323731+00:00\",\"messages\":[{\"messageId\":\"fa52578d630ba3babf3b5c4a4422fed24663f1da0828332d2537a30f170d1a96\",\"userId\":121585710,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Уточните, пожалуйста, ещё сдаёте?\",\"offers\":[]},\"createdAt\":\"2024-09-20T18:45:45.323731+00:00\"}]}],\"users\":[{\"userId\":121585710,\"name\":\"Руслан\",\"firstName\":\"Руслан\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=121585710&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=121585710&t=2&p=2\"}}}],\"offers\":[{\"id\":306704393,\"userId\":114681854,\"url\":\"https://cian.ru/rent/flat/306704393/\",\"title\":\"1-комн. кв., 37,9 м², 2/10 этаж\",\"address\":\"Саратовская область, Энгельс, улица Шурова Гора, 7/7\",\"publishedUserId\":114681854,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2256119804-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2256119804-1.jpg\"},\"price\":\"20 000 ₽/мес.\",\"externalId\":\"71046454\"}]}",
            "user_id": 14236,
            "agency_id": 14103,
            "created_at": "2024-09-20 21:45:45.874079",
            "update_at": "2024-09-20 21:45:45.874079",
            "create_at_original": "2024-09-20 21:45:45",
            "update_at_original": "2024-09-20 21:45:45",
            "objectId": 71046454
        },
        {
            "id": 82134,
            "chatid": 94741690,
            "messageid": "7f30259c8d45986cb3783d1281772e6c38b464cbf5b4d73ea4e7a973f5f3219e",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94741690,\"offerId\":306704393,\"createdAt\":\"2024-09-20T13:47:11.680439+00:00\",\"updatedAt\":\"2024-09-20T13:47:11.883052+00:00\",\"messages\":[{\"messageId\":\"7f30259c8d45986cb3783d1281772e6c38b464cbf5b4d73ea4e7a973f5f3219e\",\"userId\":99683215,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Уточните, пожалуйста, ещё сдаёте?\",\"offers\":[]},\"createdAt\":\"2024-09-20T13:47:11.883052+00:00\"}]}],\"users\":[{\"userId\":99683215,\"name\":\"Анастасия\",\"firstName\":\"Анастасия \",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=99683215&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=99683215&t=2&p=2\"}}}],\"offers\":[{\"id\":306704393,\"userId\":114681854,\"url\":\"https://cian.ru/rent/flat/306704393/\",\"title\":\"1-комн. кв., 37,9 м², 2/10 этаж\",\"address\":\"Саратовская область, Энгельс, улица Шурова Гора, 7/7\",\"publishedUserId\":114681854,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2256119804-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2256119804-1.jpg\"},\"price\":\"20 000 ₽/мес.\",\"externalId\":\"71046454\"}]}",
            "user_id": 14236,
            "agency_id": 14103,
            "created_at": "2024-09-20 16:47:12.640483",
            "update_at": "2024-09-20 16:47:12.640483",
            "create_at_original": "2024-09-20 16:47:11",
            "update_at_original": "2024-09-20 16:47:11",
            "objectId": 71046454
        },
        {
            "id": 82013,
            "chatid": 94646519,
            "messageid": "92aeaa206c2bcfab24a8c115c5bb207d95e8c14a9e51389ef4f343b7fd8ee81b",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94646519,\"offerId\":306305654,\"createdAt\":\"2024-09-19T18:02:04.878904+00:00\",\"updatedAt\":\"2024-09-20T05:31:51.344215+00:00\",\"messages\":[{\"messageId\":\"92aeaa206c2bcfab24a8c115c5bb207d95e8c14a9e51389ef4f343b7fd8ee81b\",\"userId\":116049241,\"direction\":\"in\",\"content\":{\"text\":\"+79852216317\",\"offers\":[]},\"createdAt\":\"2024-09-20T05:31:51.344215+00:00\"}]}],\"users\":[{\"userId\":116049241,\"name\":\"116049241\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=116049241&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=116049241&t=2&p=2\"}}}],\"offers\":[{\"id\":306305654,\"userId\":115696557,\"url\":\"https://cian.ru/sale/flat/306305654/\",\"title\":\"3-комн. кв., 60 м², 8/12 этаж\",\"address\":\"Москва, улица Исаковского, 8К3\",\"publishedUserId\":115696557,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2249960525-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2249960525-1.jpg\"},\"price\":\"16 500 000 ₽\",\"externalId\":\"70600733\"}]}",
            "user_id": 17588,
            "agency_id": 14103,
            "created_at": "2024-09-20 08:31:52.051747",
            "update_at": "2024-09-20 08:31:52.051747",
            "create_at_original": "2024-09-19 21:02:04",
            "update_at_original": "2024-09-20 08:31:51",
            "objectId": 70600733
        },
        {
            "id": 82012,
            "chatid": 94646519,
            "messageid": "f385b5cae9918ba1b8bc2890a10371d30229b544e832966f7e6115ba7220089a",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94646519,\"offerId\":306305654,\"createdAt\":\"2024-09-19T18:02:04.878904+00:00\",\"updatedAt\":\"2024-09-20T05:30:34.767334+00:00\",\"messages\":[{\"messageId\":\"f385b5cae9918ba1b8bc2890a10371d30229b544e832966f7e6115ba7220089a\",\"userId\":115696557,\"direction\":\"out\",\"content\":{\"text\":\"Доброе утро! Пришлите пожалуйста ваш номер телефона. С уважением!\",\"offers\":[]},\"createdAt\":\"2024-09-20T05:30:34.767334+00:00\"}]}],\"users\":[{\"userId\":115696557,\"name\":\"Ольга Спиркина\",\"firstName\":\"Ольга\",\"lastName\":\"Спиркина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115696557&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115696557&t=2&p=2\"}}}],\"offers\":[{\"id\":306305654,\"userId\":115696557,\"url\":\"https://cian.ru/sale/flat/306305654/\",\"title\":\"3-комн. кв., 60 м², 8/12 этаж\",\"address\":\"Москва, улица Исаковского, 8К3\",\"publishedUserId\":115696557,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2249960525-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2249960525-1.jpg\"},\"price\":\"16 500 000 ₽\",\"externalId\":\"70600733\"}]}",
            "user_id": 17588,
            "agency_id": 14103,
            "created_at": "2024-09-20 08:30:35.520566",
            "update_at": "2024-09-20 08:30:35.520566",
            "create_at_original": "2024-09-19 21:02:04",
            "update_at_original": "2024-09-20 08:30:34",
            "objectId": 70600733
        },
        {
            "id": 81965,
            "chatid": 94646519,
            "messageid": "00734e2d529735f64d228c5593e765fed7481089d2187899a1c524f2ed8a895d",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94646519,\"offerId\":306305654,\"createdAt\":\"2024-09-19T18:02:04.878904+00:00\",\"updatedAt\":\"2024-09-19T18:02:05.148901+00:00\",\"messages\":[{\"messageId\":\"00734e2d529735f64d228c5593e765fed7481089d2187899a1c524f2ed8a895d\",\"userId\":116049241,\"direction\":\"in\",\"content\":{\"text\":\"Ольга добрый вечер! Меня зовут Ярослав, наберите меня пожалуйста\",\"offers\":[]},\"createdAt\":\"2024-09-19T18:02:05.148901+00:00\"}]}],\"users\":[{\"userId\":116049241,\"name\":\"116049241\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=116049241&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=116049241&t=2&p=2\"}}}],\"offers\":[{\"id\":306305654,\"userId\":115696557,\"url\":\"https://cian.ru/sale/flat/306305654/\",\"title\":\"3-комн. кв., 60 м², 8/12 этаж\",\"address\":\"Москва, улица Исаковского, 8К3\",\"publishedUserId\":115696557,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2249960525-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2249960525-1.jpg\"},\"price\":\"16 500 000 ₽\",\"externalId\":\"70600733\"}]}",
            "user_id": 17588,
            "agency_id": 14103,
            "created_at": "2024-09-19 21:02:05.788257",
            "update_at": "2024-09-19 21:02:05.788257",
            "create_at_original": "2024-09-19 21:02:04",
            "update_at_original": "2024-09-19 21:02:05",
            "objectId": 70600733
        },
        {
            "id": 81846,
            "chatid": 94590151,
            "messageid": "80debe87d742b786211294f2251cdbc3d49187a2b6b1356532663c69ec1b6a50",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94590151,\"offerId\":303871676,\"createdAt\":\"2024-09-19T11:10:01.721272+00:00\",\"updatedAt\":\"2024-09-19T11:10:02.080090+00:00\",\"messages\":[{\"messageId\":\"80debe87d742b786211294f2251cdbc3d49187a2b6b1356532663c69ec1b6a50\",\"userId\":117821162,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте! Вы не против небольшого торга? Предлагаю цену 1 820 000? Вы готовы сделать скидку?\",\"offers\":[]},\"createdAt\":\"2024-09-19T11:10:02.080090+00:00\"}]}],\"users\":[{\"userId\":117821162,\"name\":\"117821162\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=117821162&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=117821162&t=2&p=2\"}}}],\"offers\":[{\"id\":303871676,\"userId\":114681854,\"url\":\"https://cian.ru/sale/flat/303871676/\",\"title\":\"2-комн. кв., 47,6 м², 1/4 этаж\",\"address\":\"Саратовская область, Энгельс муниципальное образование, Приволжский рп, улица Пархоменко, 56\",\"publishedUserId\":114681854,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2203900231-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2203900231-1.jpg\"},\"price\":\"1 900 000 ₽\",\"externalId\":\"62837761\"}]}",
            "user_id": 14236,
            "agency_id": 14103,
            "created_at": "2024-09-19 14:10:03.87792",
            "update_at": "2024-09-19 14:10:03.87792",
            "create_at_original": "2024-09-19 14:10:01",
            "update_at_original": "2024-09-19 14:10:02",
            "objectId": 62837761
        },
        {
            "id": 81767,
            "chatid": 94431463,
            "messageid": "1d31c9300287c62428075526bc18d5baf3512a39625fc7ffd2fce76b42922397",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94431463,\"offerId\":300974779,\"createdAt\":\"2024-09-18T08:11:39.358058+00:00\",\"updatedAt\":\"2024-09-19T06:07:53.738966+00:00\",\"messages\":[{\"messageId\":\"1d31c9300287c62428075526bc18d5baf3512a39625fc7ffd2fce76b42922397\",\"userId\":45252043,\"direction\":\"in\",\"content\":{\"text\":\"Напоминаю о  себе, жду план квартиры\",\"offers\":[]},\"createdAt\":\"2024-09-19T06:07:53.738966+00:00\"}]}],\"users\":[{\"userId\":45252043,\"name\":\"Юлия Александровна\",\"firstName\":\"Юлия\",\"lastName\":\"Александровна\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=45252043&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=45252043&t=2&p=2\"}}}],\"offers\":[{\"id\":300974779,\"userId\":115696557,\"url\":\"https://cian.ru/sale/flat/300974779/\",\"title\":\"2-комн. кв., 84 м², 8/10 этаж\",\"address\":\"Саратовская область, Саратов, улица Имени В.Г. Рахова, 61/71\",\"publishedUserId\":115696557,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2150422736-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2150422736-1.jpg\"},\"price\":\"8 100 000 ₽\",\"externalId\":\"63813590\"}]}",
            "user_id": 17588,
            "agency_id": 14103,
            "created_at": "2024-09-19 09:07:54.475981",
            "update_at": "2024-09-19 09:07:54.475981",
            "create_at_original": "2024-09-18 11:11:39",
            "update_at_original": "2024-09-19 09:07:53",
            "objectId": 63813590
        },
        {
            "id": 81766,
            "chatid": 94431463,
            "messageid": "22ceaf9ae5f50329f3deaa3a37414d0a772eba8086d980e698b98bd267b83719",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94431463,\"offerId\":300974779,\"createdAt\":\"2024-09-18T08:11:39.358058+00:00\",\"updatedAt\":\"2024-09-19T06:07:34.689406+00:00\",\"messages\":[{\"messageId\":\"22ceaf9ae5f50329f3deaa3a37414d0a772eba8086d980e698b98bd267b83719\",\"userId\":45252043,\"direction\":\"in\",\"content\":{\"text\":\"Ольга, добрый день\",\"offers\":[]},\"createdAt\":\"2024-09-19T06:07:34.689406+00:00\"}]}],\"users\":[{\"userId\":45252043,\"name\":\"Юлия Александровна\",\"firstName\":\"Юлия\",\"lastName\":\"Александровна\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=45252043&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=45252043&t=2&p=2\"}}}],\"offers\":[{\"id\":300974779,\"userId\":115696557,\"url\":\"https://cian.ru/sale/flat/300974779/\",\"title\":\"2-комн. кв., 84 м², 8/10 этаж\",\"address\":\"Саратовская область, Саратов, улица Имени В.Г. Рахова, 61/71\",\"publishedUserId\":115696557,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2150422736-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2150422736-1.jpg\"},\"price\":\"8 100 000 ₽\",\"externalId\":\"63813590\"}]}",
            "user_id": 17588,
            "agency_id": 14103,
            "created_at": "2024-09-19 09:07:39.63408",
            "update_at": "2024-09-19 09:07:39.63408",
            "create_at_original": "2024-09-18 11:11:39",
            "update_at_original": "2024-09-19 09:07:34",
            "objectId": 63813590
        },
        {
            "id": 81600,
            "chatid": 94431463,
            "messageid": "1aabbfd4e0590a454d34c2ca24ba1607a10fde6f43222e3f21dca58704a11e92",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94431463,\"offerId\":300974779,\"createdAt\":\"2024-09-18T08:11:39.358058+00:00\",\"updatedAt\":\"2024-09-18T12:48:37.746269+00:00\",\"messages\":[{\"messageId\":\"1aabbfd4e0590a454d34c2ca24ba1607a10fde6f43222e3f21dca58704a11e92\",\"userId\":45252043,\"direction\":\"in\",\"content\":{\"text\":\"89379693565\",\"offers\":[]},\"createdAt\":\"2024-09-18T12:48:37.746269+00:00\"}]}],\"users\":[{\"userId\":45252043,\"name\":\"Юлия Александровна\",\"firstName\":\"Юлия\",\"lastName\":\"Александровна\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=45252043&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=45252043&t=2&p=2\"}}}],\"offers\":[{\"id\":300974779,\"userId\":115696557,\"url\":\"https://cian.ru/sale/flat/300974779/\",\"title\":\"2-комн. кв., 84 м², 8/10 этаж\",\"address\":\"Саратовская область, Саратов, улица Имени В.Г. Рахова, 61/71\",\"publishedUserId\":115696557,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2150422736-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2150422736-1.jpg\"},\"price\":\"8 100 000 ₽\",\"externalId\":\"63813590\"}]}",
            "user_id": 17588,
            "agency_id": 14103,
            "created_at": "2024-09-18 15:48:38.552889",
            "update_at": "2024-09-18 15:48:38.552889",
            "create_at_original": "2024-09-18 11:11:39",
            "update_at_original": "2024-09-18 15:48:37",
            "objectId": 63813590
        },
        {
            "id": 81547,
            "chatid": 94445147,
            "messageid": "b04a2f92ea32ccfd49a769d5860c6c26e31951499cd24144f6a61b32394d3791",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94445147,\"offerId\":306305654,\"createdAt\":\"2024-09-18T09:51:33.652510+00:00\",\"updatedAt\":\"2024-09-18T09:51:33.968695+00:00\",\"messages\":[{\"messageId\":\"b04a2f92ea32ccfd49a769d5860c6c26e31951499cd24144f6a61b32394d3791\",\"userId\":97346125,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте, заинтересовало ваше предложение. Подскажите пожалуйста, можно ли посмотреть квартиру на следующей неделе  и в какие дни?\",\"offers\":[]},\"createdAt\":\"2024-09-18T09:51:33.968695+00:00\"}]}],\"users\":[{\"userId\":97346125,\"name\":\"97346125\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=97346125&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=97346125&t=2&p=2\"}}}],\"offers\":[{\"id\":306305654,\"userId\":115696557,\"url\":\"https://cian.ru/sale/flat/306305654/\",\"title\":\"3-комн. кв., 60 м², 8/12 этаж\",\"address\":\"Москва, улица Исаковского, 8К3\",\"publishedUserId\":115696557,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2249960525-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2249960525-1.jpg\"},\"price\":\"16 500 000 ₽\",\"externalId\":\"70600733\"}]}",
            "user_id": 17588,
            "agency_id": 14103,
            "created_at": "2024-09-18 12:51:34.843086",
            "update_at": "2024-09-18 12:51:34.843086",
            "create_at_original": "2024-09-18 12:51:33",
            "update_at_original": "2024-09-18 12:51:33",
            "objectId": 70600733
        },
        {
            "id": 81544,
            "chatid": 94431463,
            "messageid": "b3517f98231e608c90725c6424495005801b7e6ca9fecc09d507bad792fb2ab7",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94431463,\"offerId\":300974779,\"createdAt\":\"2024-09-18T08:11:39.358058+00:00\",\"updatedAt\":\"2024-09-18T09:39:14.803195+00:00\",\"messages\":[{\"messageId\":\"b3517f98231e608c90725c6424495005801b7e6ca9fecc09d507bad792fb2ab7\",\"userId\":115696557,\"direction\":\"out\",\"content\":{\"text\":\"Добрый!\\nНапишите куда вам прислать ваш номер скрыт Авито!!!\",\"offers\":[]},\"createdAt\":\"2024-09-18T09:39:14.803195+00:00\"}]}],\"users\":[{\"userId\":115696557,\"name\":\"Ольга Спиркина\",\"firstName\":\"Ольга\",\"lastName\":\"Спиркина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115696557&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115696557&t=2&p=2\"}}}],\"offers\":[{\"id\":300974779,\"userId\":115696557,\"url\":\"https://cian.ru/sale/flat/300974779/\",\"title\":\"2-комн. кв., 84 м², 8/10 этаж\",\"address\":\"Саратовская область, Саратов, улица Имени В.Г. Рахова, 61/71\",\"publishedUserId\":115696557,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2150422736-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2150422736-1.jpg\"},\"price\":\"8 100 000 ₽\",\"externalId\":\"63813590\"}]}",
            "user_id": 17588,
            "agency_id": 14103,
            "created_at": "2024-09-18 12:39:15.718361",
            "update_at": "2024-09-18 12:39:15.718361",
            "create_at_original": "2024-09-18 11:11:39",
            "update_at_original": "2024-09-18 12:39:14",
            "objectId": 63813590
        },
        {
            "id": 81509,
            "chatid": 94431463,
            "messageid": "d2fa4f4111c83d25ef071477bc3194ec0e676940ad2d4df5638577394e8c65e3",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94431463,\"offerId\":300974779,\"createdAt\":\"2024-09-18T08:11:39.358058+00:00\",\"updatedAt\":\"2024-09-18T08:11:39.503293+00:00\",\"messages\":[{\"messageId\":\"d2fa4f4111c83d25ef071477bc3194ec0e676940ad2d4df5638577394e8c65e3\",\"userId\":45252043,\"direction\":\"in\",\"content\":{\"text\":\"Добрый день, пришлите, пожалуйста, план квартиры\",\"offers\":[]},\"createdAt\":\"2024-09-18T08:11:39.503293+00:00\"}]}],\"users\":[{\"userId\":45252043,\"name\":\"Юлия Александровна\",\"firstName\":\"Юлия\",\"lastName\":\"Александровна\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=45252043&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=45252043&t=2&p=2\"}}}],\"offers\":[{\"id\":300974779,\"userId\":115696557,\"url\":\"https://cian.ru/sale/flat/300974779/\",\"title\":\"2-комн. кв., 84 м², 8/10 этаж\",\"address\":\"Саратовская область, Саратов, улица Имени В.Г. Рахова, 61/71\",\"publishedUserId\":115696557,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2150422736-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2150422736-1.jpg\"},\"price\":\"8 100 000 ₽\",\"externalId\":\"63813590\"}]}",
            "user_id": 17588,
            "agency_id": 14103,
            "created_at": "2024-09-18 11:11:40.29966",
            "update_at": "2024-09-18 11:11:40.29966",
            "create_at_original": "2024-09-18 11:11:39",
            "update_at_original": "2024-09-18 11:11:39",
            "objectId": 63813590
        },
        {
            "id": 81473,
            "chatid": 94354566,
            "messageid": "675073c91fd4bec725faa68f7988f16cf1b763d4b146e4b6ee258e20898e9fae",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94354566,\"offerId\":306305654,\"createdAt\":\"2024-09-17T14:03:53.823648+00:00\",\"updatedAt\":\"2024-09-18T06:46:22.811315+00:00\",\"messages\":[{\"messageId\":\"675073c91fd4bec725faa68f7988f16cf1b763d4b146e4b6ee258e20898e9fae\",\"userId\":115696557,\"direction\":\"out\",\"content\":{\"text\":\"Добрый день , перезвоню вам через 30 мин\",\"offers\":[]},\"createdAt\":\"2024-09-18T06:46:22.811315+00:00\"}]}],\"users\":[{\"userId\":115696557,\"name\":\"Ольга Спиркина\",\"firstName\":\"Ольга\",\"lastName\":\"Спиркина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115696557&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115696557&t=2&p=2\"}}}],\"offers\":[{\"id\":306305654,\"userId\":115696557,\"url\":\"https://cian.ru/sale/flat/306305654/\",\"title\":\"3-комн. кв., 60 м², 8/12 этаж\",\"address\":\"Москва, улица Исаковского, 8К3\",\"publishedUserId\":115696557,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2249960525-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2249960525-1.jpg\"},\"price\":\"16 500 000 ₽\",\"externalId\":\"70600733\"}]}",
            "user_id": 17588,
            "agency_id": 14103,
            "created_at": "2024-09-18 09:46:23.565401",
            "update_at": "2024-09-18 09:46:23.565401",
            "create_at_original": "2024-09-17 17:03:53",
            "update_at_original": "2024-09-18 09:46:22",
            "objectId": 70600733
        },
        {
            "id": 81340,
            "chatid": 94354566,
            "messageid": "11b9e1a969f011389841bdf6dc87761a797ef11939c517dd8d8d8886faf570db",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94354566,\"offerId\":306305654,\"createdAt\":\"2024-09-17T14:03:53.823648+00:00\",\"updatedAt\":\"2024-09-17T14:03:53.912750+00:00\",\"messages\":[{\"messageId\":\"11b9e1a969f011389841bdf6dc87761a797ef11939c517dd8d8d8886faf570db\",\"userId\":106549532,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Подскажите, пожалуйста, когда можно посмотреть квартиру? Моц номер +7925-359-90-45\",\"offers\":[]},\"createdAt\":\"2024-09-17T14:03:53.912750+00:00\"}]}],\"users\":[{\"userId\":106549532,\"name\":\"106549532\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=106549532&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=106549532&t=2&p=2\"}}}],\"offers\":[{\"id\":306305654,\"userId\":115696557,\"url\":\"https://cian.ru/sale/flat/306305654/\",\"title\":\"3-комн. кв., 60 м², 8/12 этаж\",\"address\":\"Москва, улица Исаковского, 8К3\",\"publishedUserId\":115696557,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2249960525-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2249960525-1.jpg\"},\"price\":\"16 500 000 ₽\",\"externalId\":\"70600733\"}]}",
            "user_id": 17588,
            "agency_id": 14103,
            "created_at": "2024-09-17 17:04:00.381254",
            "update_at": "2024-09-17 17:04:00.381254",
            "create_at_original": "2024-09-17 17:03:53",
            "update_at_original": "2024-09-17 17:03:53",
            "objectId": 70600733
        },
        {
            "id": 80653,
            "chatid": 94157435,
            "messageid": "3d0849d23db7ad5bad1c7a4236beb10f700a3e7eaca2accf29b5cbdef6f1fa82",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94157435,\"offerId\":306305654,\"createdAt\":\"2024-09-16T05:48:55.225727+00:00\",\"updatedAt\":\"2024-09-16T05:49:00.858585+00:00\",\"messages\":[{\"messageId\":\"3d0849d23db7ad5bad1c7a4236beb10f700a3e7eaca2accf29b5cbdef6f1fa82\",\"userId\":121829278,\"direction\":\"in\",\"content\":{\"files\":[{\"fileId\":\"f2b77973-b047-4edb-ba07-5c4bd3f9ca19\",\"name\":\"offer_121829278_306305654_f2b77973-b047-4edb-ba07-5c4bd3f9ca19.jpeg\",\"mimeType\":\"image/jpeg\"}],\"offers\":[]},\"createdAt\":\"2024-09-16T05:49:00.858585+00:00\"}]}],\"users\":[{\"userId\":121829278,\"name\":\"121829278\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=121829278&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=121829278&t=2&p=2\"}}}],\"offers\":[{\"id\":306305654,\"userId\":115696557,\"url\":\"https://cian.ru/sale/flat/306305654/\",\"title\":\"3-комн. кв., 60 м², 8/12 этаж\",\"address\":\"Москва, улица Исаковского, 8К3\",\"publishedUserId\":115696557,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2249960525-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2249960525-1.jpg\"},\"price\":\"16 500 000 ₽\",\"externalId\":\"70600733\"}]}",
            "user_id": 17588,
            "agency_id": 14103,
            "created_at": "2024-09-16 08:49:01.521942",
            "update_at": "2024-09-16 08:49:01.521942",
            "create_at_original": "2024-09-16 08:48:55",
            "update_at_original": "2024-09-16 08:49:00",
            "objectId": 70600733
        },
        {
            "id": 80652,
            "chatid": 94157435,
            "messageid": "71eaf19f11a8800e16aef07c1dbc78da6e5d72bc414b959afdea0809391a4117",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94157435,\"offerId\":306305654,\"createdAt\":\"2024-09-16T05:48:55.225727+00:00\",\"updatedAt\":\"2024-09-16T05:48:55.320094+00:00\",\"messages\":[{\"messageId\":\"71eaf19f11a8800e16aef07c1dbc78da6e5d72bc414b959afdea0809391a4117\",\"userId\":121829278,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте, хочу получить информацию о квартире.\",\"offers\":[]},\"createdAt\":\"2024-09-16T05:48:55.320094+00:00\"}]}],\"users\":[{\"userId\":121829278,\"name\":\"121829278\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=121829278&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=121829278&t=2&p=2\"}}}],\"offers\":[{\"id\":306305654,\"userId\":115696557,\"url\":\"https://cian.ru/sale/flat/306305654/\",\"title\":\"3-комн. кв., 60 м², 8/12 этаж\",\"address\":\"Москва, улица Исаковского, 8К3\",\"publishedUserId\":115696557,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2249960525-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2249960525-1.jpg\"},\"price\":\"16 500 000 ₽\",\"externalId\":\"70600733\"}]}",
            "user_id": 17588,
            "agency_id": 14103,
            "created_at": "2024-09-16 08:48:56.060833",
            "update_at": "2024-09-16 08:48:56.060833",
            "create_at_original": "2024-09-16 08:48:55",
            "update_at_original": "2024-09-16 08:48:55",
            "objectId": 70600733
        },
        {
            "id": 80411,
            "chatid": 94071324,
            "messageid": "0ff0eabef586f4f916ee32f3136f9d982bca37d250a088d41d8c2f662f94555c",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94071324,\"offerId\":300860652,\"createdAt\":\"2024-09-15T09:00:21.972400+00:00\",\"updatedAt\":\"2024-09-15T09:15:00.217812+00:00\",\"messages\":[{\"messageId\":\"0ff0eabef586f4f916ee32f3136f9d982bca37d250a088d41d8c2f662f94555c\",\"userId\":120665743,\"direction\":\"in\",\"content\":{\"text\":\"Понятно на деревне количество дома много\",\"offers\":[]},\"createdAt\":\"2024-09-15T09:15:00.217812+00:00\"}]}],\"users\":[{\"userId\":120665743,\"name\":\"120665743\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=120665743&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=120665743&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-15 12:15:01.0062",
            "update_at": "2024-09-15 12:15:01.0062",
            "create_at_original": "2024-09-15 12:00:21",
            "update_at_original": "2024-09-15 12:15:00",
            "objectId": 63977339
        },
        {
            "id": 80407,
            "chatid": 94071324,
            "messageid": "6df000096d4e58fae7423c5cf633a957d3acc45bff2100c67bb3824ebfaa5acb",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94071324,\"offerId\":300860652,\"createdAt\":\"2024-09-15T09:00:21.972400+00:00\",\"updatedAt\":\"2024-09-15T09:07:07.868464+00:00\",\"messages\":[{\"messageId\":\"6df000096d4e58fae7423c5cf633a957d3acc45bff2100c67bb3824ebfaa5acb\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Это деревня Тамбовка, между Самарой и Саратовом\",\"offers\":[]},\"createdAt\":\"2024-09-15T09:07:07.868464+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-15 12:07:08.619603",
            "update_at": "2024-09-15 12:07:08.619603",
            "create_at_original": "2024-09-15 12:00:21",
            "update_at_original": "2024-09-15 12:07:07",
            "objectId": 63977339
        },
        {
            "id": 80406,
            "chatid": 94071324,
            "messageid": "0dc0ed5438a772e9a00674848173aed3d7ec6a61e81fced083bb8f114359670e",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94071324,\"offerId\":300860652,\"createdAt\":\"2024-09-15T09:00:21.972400+00:00\",\"updatedAt\":\"2024-09-15T09:03:53.985339+00:00\",\"messages\":[{\"messageId\":\"0dc0ed5438a772e9a00674848173aed3d7ec6a61e81fced083bb8f114359670e\",\"userId\":120665743,\"direction\":\"in\",\"content\":{\"text\":\"Пожалуйста, позвоните, когда будет удобно. Вот мой номер\\n+79001659680\",\"offers\":[]},\"createdAt\":\"2024-09-15T09:03:53.985339+00:00\"}]}],\"users\":[{\"userId\":120665743,\"name\":\"120665743\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=120665743&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=120665743&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-15 12:03:54.705054",
            "update_at": "2024-09-15 12:03:54.705054",
            "create_at_original": "2024-09-15 12:00:21",
            "update_at_original": "2024-09-15 12:03:53",
            "objectId": 63977339
        },
        {
            "id": 80405,
            "chatid": 94071324,
            "messageid": "e76504c5f0c837cda5b4491c14dfb65283f30a447872acbfd6868df816354554",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94071324,\"offerId\":300860652,\"createdAt\":\"2024-09-15T09:00:21.972400+00:00\",\"updatedAt\":\"2024-09-15T09:03:42.410257+00:00\",\"messages\":[{\"messageId\":\"e76504c5f0c837cda5b4491c14dfb65283f30a447872acbfd6868df816354554\",\"userId\":120665743,\"direction\":\"in\",\"content\":{\"text\":\"Это же деревня правильно от город Тамбов да сколько километров примерно\",\"offers\":[]},\"createdAt\":\"2024-09-15T09:03:42.410257+00:00\"}]}],\"users\":[{\"userId\":120665743,\"name\":\"120665743\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=120665743&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=120665743&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-15 12:03:42.649257",
            "update_at": "2024-09-15 12:03:42.649257",
            "create_at_original": "2024-09-15 12:00:21",
            "update_at_original": "2024-09-15 12:03:42",
            "objectId": 63977339
        },
        {
            "id": 80404,
            "chatid": 94071324,
            "messageid": "a5fbf33b33a8b29979ae2ba124da6f633053ff1d3781b090a22f8367fc484d4c",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94071324,\"offerId\":300860652,\"createdAt\":\"2024-09-15T09:00:21.972400+00:00\",\"updatedAt\":\"2024-09-15T09:01:19.519586+00:00\",\"messages\":[{\"messageId\":\"a5fbf33b33a8b29979ae2ba124da6f633053ff1d3781b090a22f8367fc484d4c\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте. От какого именно?\",\"offers\":[]},\"createdAt\":\"2024-09-15T09:01:19.519586+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-15 12:01:20.284286",
            "update_at": "2024-09-15 12:01:20.284286",
            "create_at_original": "2024-09-15 12:00:21",
            "update_at_original": "2024-09-15 12:01:19",
            "objectId": 63977339
        },
        {
            "id": 80403,
            "chatid": 94071324,
            "messageid": "7e41a8066c9c6dda0f0334b1c95108f216bb4eb79e323ae3038ad29f3381f4a3",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":94071324,\"offerId\":300860652,\"createdAt\":\"2024-09-15T09:00:21.972400+00:00\",\"updatedAt\":\"2024-09-15T09:00:22.308710+00:00\",\"messages\":[{\"messageId\":\"7e41a8066c9c6dda0f0334b1c95108f216bb4eb79e323ae3038ad29f3381f4a3\",\"userId\":120665743,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте это дом от города далеко\",\"offers\":[]},\"createdAt\":\"2024-09-15T09:00:22.308710+00:00\"}]}],\"users\":[{\"userId\":120665743,\"name\":\"120665743\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=120665743&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=120665743&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-15 12:00:23.496325",
            "update_at": "2024-09-15 12:00:23.496325",
            "create_at_original": "2024-09-15 12:00:21",
            "update_at_original": "2024-09-15 12:00:22",
            "objectId": 63977339
        },
        {
            "id": 79951,
            "chatid": 93629259,
            "messageid": "9f1204713964de925217a620a7311c0a1957a39730164d2708cf34a774b011e1",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93629259,\"offerId\":306437694,\"createdAt\":\"2024-09-11T14:39:40.998129+00:00\",\"updatedAt\":\"2024-09-13T13:39:01.850632+00:00\",\"messages\":[{\"messageId\":\"9f1204713964de925217a620a7311c0a1957a39730164d2708cf34a774b011e1\",\"userId\":114681854,\"direction\":\"out\",\"content\":{\"text\":\"Добрый день. В любое время. Кадастровый номер указан в объявлении\",\"offers\":[]},\"createdAt\":\"2024-09-13T13:39:01.850632+00:00\"}]}],\"users\":[{\"userId\":114681854,\"name\":\"Юлия Наличникова\",\"firstName\":\"Юлия\",\"lastName\":\"Наличникова\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=114681854&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=114681854&t=2&p=2\"}}}],\"offers\":[{\"id\":306437694,\"userId\":114681854,\"url\":\"https://cian.ru/sale/suburban/306437694/\",\"title\":\"Земельный участок, 10 сот.\",\"address\":\"Саратовская область, Энгельсский район, Красноярское муниципальное образование, с. Шумейка, Буревестник мкр\",\"publishedUserId\":114681854,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2251652942-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2251652942-1.jpg\"},\"price\":\"1 300 000 ₽\",\"externalId\":\"70498104\"}]}",
            "user_id": 14236,
            "agency_id": 14103,
            "created_at": "2024-09-13 16:39:02.761711",
            "update_at": "2024-09-13 16:39:02.761711",
            "create_at_original": "2024-09-11 17:39:40",
            "update_at_original": "2024-09-13 16:39:01",
            "objectId": 70498104
        },
        {
            "id": 79950,
            "chatid": 93748440,
            "messageid": "a9872eb178fc5ecf76ef84aedcc49d9f2ac7595939015d5b6bf4f33d642834be",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93748440,\"offerId\":303473200,\"createdAt\":\"2024-09-12T13:32:03.635377+00:00\",\"updatedAt\":\"2024-09-13T13:38:43.170416+00:00\",\"messages\":[{\"messageId\":\"a9872eb178fc5ecf76ef84aedcc49d9f2ac7595939015d5b6bf4f33d642834be\",\"userId\":114681854,\"direction\":\"out\",\"content\":{\"text\":\"Добрый день. Там уже проживают квартиранты\",\"offers\":[]},\"createdAt\":\"2024-09-13T13:38:43.170416+00:00\"}]}],\"users\":[{\"userId\":114681854,\"name\":\"Юлия Наличникова\",\"firstName\":\"Юлия\",\"lastName\":\"Наличникова\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=114681854&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=114681854&t=2&p=2\"}}}],\"offers\":[{\"id\":303473200,\"userId\":114681854,\"url\":\"https://cian.ru/sale/suburban/303473200/\",\"title\":\"Дом, 77,7 м²\",\"address\":\"Саратовская область, Энгельсский район, с. Красный Яр, улица Мира, 13\",\"publishedUserId\":114681854,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2194991621-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2194991621-1.jpg\"},\"price\":\"2 499 000 ₽\",\"externalId\":\"67296369\"}]}",
            "user_id": 14236,
            "agency_id": 14103,
            "created_at": "2024-09-13 16:38:47.761355",
            "update_at": "2024-09-13 16:38:47.761355",
            "create_at_original": "2024-09-12 16:32:03",
            "update_at_original": "2024-09-13 16:38:43",
            "objectId": 67296369
        },
        {
            "id": 79949,
            "chatid": 93881495,
            "messageid": "a31723149d3ad63f70b73fbe91e41c2005ae5fd0fa4c507a5774bd3e365ecb96",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93881495,\"offerId\":306305654,\"createdAt\":\"2024-09-13T13:33:49.350795+00:00\",\"updatedAt\":\"2024-09-13T13:34:58.993123+00:00\",\"messages\":[{\"messageId\":\"a31723149d3ad63f70b73fbe91e41c2005ae5fd0fa4c507a5774bd3e365ecb96\",\"userId\":47454243,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Подскажите, пожалуйста, когда можно посмотреть квартиру?\",\"offers\":[]},\"createdAt\":\"2024-09-13T13:34:58.993123+00:00\"}]}],\"users\":[{\"userId\":47454243,\"name\":\"47454243\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=47454243&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=47454243&t=2&p=2\"}}}],\"offers\":[{\"id\":306305654,\"userId\":115696557,\"url\":\"https://cian.ru/sale/flat/306305654/\",\"title\":\"3-комн. кв., 60 м², 8/12 этаж\",\"address\":\"Москва, улица Исаковского, 8К3\",\"publishedUserId\":115696557,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2249960525-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2249960525-1.jpg\"},\"price\":\"16 500 000 ₽\",\"externalId\":\"70600733\"}]}",
            "user_id": 17588,
            "agency_id": 14103,
            "created_at": "2024-09-13 16:34:59.845036",
            "update_at": "2024-09-13 16:34:59.845036",
            "create_at_original": "2024-09-13 16:33:49",
            "update_at_original": "2024-09-13 16:34:58",
            "objectId": 70600733
        },
        {
            "id": 79948,
            "chatid": 93881495,
            "messageid": "2c3799403d5d813c355dccd7bcb93679b4682bc494e8e7f8d389f6ed56d0626c",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93881495,\"offerId\":306305654,\"createdAt\":\"2024-09-13T13:33:49.350795+00:00\",\"updatedAt\":\"2024-09-13T13:33:49.778972+00:00\",\"messages\":[{\"messageId\":\"2c3799403d5d813c355dccd7bcb93679b4682bc494e8e7f8d389f6ed56d0626c\",\"userId\":47454243,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Пожалуйста, свяжитесь со мной +79113264580\",\"offers\":[]},\"createdAt\":\"2024-09-13T13:33:49.778972+00:00\"}]}],\"users\":[{\"userId\":47454243,\"name\":\"47454243\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=47454243&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=47454243&t=2&p=2\"}}}],\"offers\":[{\"id\":306305654,\"userId\":115696557,\"url\":\"https://cian.ru/sale/flat/306305654/\",\"title\":\"3-комн. кв., 60 м², 8/12 этаж\",\"address\":\"Москва, улица Исаковского, 8К3\",\"publishedUserId\":115696557,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2249960525-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2249960525-1.jpg\"},\"price\":\"16 500 000 ₽\",\"externalId\":\"70600733\"}]}",
            "user_id": 17588,
            "agency_id": 14103,
            "created_at": "2024-09-13 16:33:51.184898",
            "update_at": "2024-09-13 16:33:51.184898",
            "create_at_original": "2024-09-13 16:33:49",
            "update_at_original": "2024-09-13 16:33:49",
            "objectId": 70600733
        },
        {
            "id": 79876,
            "chatid": 93859201,
            "messageid": "187585ef625418d86d1789b9e0a741c5ebaf55ef2cda77964de161663b875d8b",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93859201,\"offerId\":307300812,\"createdAt\":\"2024-09-13T10:56:07.026374+00:00\",\"updatedAt\":\"2024-09-13T10:56:07.252659+00:00\",\"messages\":[{\"messageId\":\"187585ef625418d86d1789b9e0a741c5ebaf55ef2cda77964de161663b875d8b\",\"userId\":121084548,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте, понравилась эта квартира хотели бы снять моладая семейная пара 30,31  лет без детей без жывотных\",\"offers\":[]},\"createdAt\":\"2024-09-13T10:56:07.252659+00:00\"}]}],\"users\":[{\"userId\":121084548,\"name\":\"Бабаджан  Маммедов\",\"firstName\":\"Бабаджан \",\"lastName\":\"Маммедов \",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=121084548&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=121084548&t=2&p=2\"}}}],\"offers\":[{\"id\":307300812,\"userId\":120336895,\"url\":\"https://cian.ru/rent/flat/307300812/\",\"title\":\"1-комн. кв., 38 м², 2/10 этаж\",\"address\":\"Саратовская область, Саратов, проезд 2-й имени Блинова Ф.А., 8\",\"publishedUserId\":120336895,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2267557894-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2267557894-1.jpg\"},\"price\":\"20 000 ₽/мес.\",\"externalId\":\"71740637\"}]}",
            "user_id": 18435,
            "agency_id": 14103,
            "created_at": "2024-09-13 13:56:08.072051",
            "update_at": "2024-09-13 13:56:08.072051",
            "create_at_original": "2024-09-13 13:56:07",
            "update_at_original": "2024-09-13 13:56:07",
            "objectId": 71740637
        },
        {
            "id": 79606,
            "chatid": 93748440,
            "messageid": "4a917300eec0ceb41e5bbdf2f5b5e08a204f3657513661e5cdf9917dbbd10115",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93748440,\"offerId\":303473200,\"createdAt\":\"2024-09-12T13:32:03.635377+00:00\",\"updatedAt\":\"2024-09-12T13:32:03.758659+00:00\",\"messages\":[{\"messageId\":\"4a917300eec0ceb41e5bbdf2f5b5e08a204f3657513661e5cdf9917dbbd10115\",\"userId\":107821216,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте,  подскажите,  собственник не желает сдать дом месяца на 2 пока дом продаётся?\",\"offers\":[]},\"createdAt\":\"2024-09-12T13:32:03.758659+00:00\"}]}],\"users\":[{\"userId\":107821216,\"name\":\"107821216\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=107821216&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=107821216&t=2&p=2\"}}}],\"offers\":[{\"id\":303473200,\"userId\":114681854,\"url\":\"https://cian.ru/sale/suburban/303473200/\",\"title\":\"Дом, 77,7 м²\",\"address\":\"Саратовская область, Энгельсский район, с. Красный Яр, улица Мира, 13\",\"publishedUserId\":114681854,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2194991621-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2194991621-1.jpg\"},\"price\":\"2 499 000 ₽\",\"externalId\":\"67296369\"}]}",
            "user_id": 14236,
            "agency_id": 14103,
            "created_at": "2024-09-12 16:32:04.485094",
            "update_at": "2024-09-12 16:32:04.485094",
            "create_at_original": "2024-09-12 16:32:03",
            "update_at_original": "2024-09-12 16:32:03",
            "objectId": 67296369
        },
        {
            "id": 79389,
            "chatid": 93629259,
            "messageid": "3cd22a331ca4410e20cd8c5c2f001c140b32287b89393b003e3d4f3076b8af55",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93629259,\"offerId\":306437694,\"createdAt\":\"2024-09-11T14:39:40.998129+00:00\",\"updatedAt\":\"2024-09-11T14:39:41.131311+00:00\",\"messages\":[{\"messageId\":\"3cd22a331ca4410e20cd8c5c2f001c140b32287b89393b003e3d4f3076b8af55\",\"userId\":74626364,\"direction\":\"in\",\"content\":{\"text\":\"Добрый день, когда можно посмотреть?\",\"offers\":[]},\"createdAt\":\"2024-09-11T14:39:41.131311+00:00\"}]}],\"users\":[{\"userId\":74626364,\"name\":\"74626364\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=74626364&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=74626364&t=2&p=2\"}}}],\"offers\":[{\"id\":306437694,\"userId\":114681854,\"url\":\"https://cian.ru/sale/suburban/306437694/\",\"title\":\"Земельный участок, 10 сот.\",\"address\":\"Саратовская область, Энгельсский район, Красноярское муниципальное образование, с. Шумейка, Буревестник мкр\",\"publishedUserId\":114681854,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2251652942-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2251652942-1.jpg\"},\"price\":\"1 300 000 ₽\",\"externalId\":\"70498104\"}]}",
            "user_id": 14236,
            "agency_id": 14103,
            "created_at": "2024-09-11 17:39:44.098668",
            "update_at": "2024-09-11 17:39:44.098668",
            "create_at_original": "2024-09-11 17:39:40",
            "update_at_original": "2024-09-11 17:39:41",
            "objectId": 70498104
        },
        {
            "id": 79287,
            "chatid": 93584015,
            "messageid": "800fabffcb9f77f3a56dba453d001acdae9867637ae985a433456bf7e43430b6",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93584015,\"offerId\":305568704,\"createdAt\":\"2024-09-11T09:38:49.122905+00:00\",\"updatedAt\":\"2024-09-11T09:38:49.421565+00:00\",\"messages\":[{\"messageId\":\"800fabffcb9f77f3a56dba453d001acdae9867637ae985a433456bf7e43430b6\",\"userId\":120746268,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте, сколько в доме отдельных спален? Есть ли фото планировки?\",\"offers\":[]},\"createdAt\":\"2024-09-11T09:38:49.421565+00:00\"}]}],\"users\":[{\"userId\":120746268,\"name\":\"Анна\",\"firstName\":\"Анна\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=120746268&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=120746268&t=2&p=2\"}}}],\"offers\":[{\"id\":305568704,\"userId\":115760105,\"url\":\"https://cian.ru/sale/suburban/305568704/\",\"title\":\"Дом, 100 м²\",\"address\":\"Саратовская область, Энгельсский район, Красноярское муниципальное образование, Вишневый Сад СНТ\",\"publishedUserId\":115760105,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2252622878-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2252622878-1.jpg\"},\"price\":\"5 650 000 ₽\",\"externalId\":\"69677526\"}]}",
            "user_id": 17706,
            "agency_id": 14103,
            "created_at": "2024-09-11 12:38:49.92262",
            "update_at": "2024-09-11 12:38:49.92262",
            "create_at_original": "2024-09-11 12:38:49",
            "update_at_original": "2024-09-11 12:38:49",
            "objectId": 69677526
        },
        {
            "id": 78974,
            "chatid": 92620086,
            "messageid": "65c53754e6c56fbe8853ad44a8493aca7ea40a4a6abb95f7962d16a0221eaecf",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":92620086,\"offerId\":305858924,\"createdAt\":\"2024-09-03T17:40:42.019194+00:00\",\"updatedAt\":\"2024-09-10T09:09:55.550799+00:00\",\"messages\":[{\"messageId\":\"65c53754e6c56fbe8853ad44a8493aca7ea40a4a6abb95f7962d16a0221eaecf\",\"userId\":118090029,\"direction\":\"in\",\"content\":{\"text\":\"Скажите,  а калитка которая ездит это , что кухня ? Я так понимаю , чтобы войти на кухню нужно калитку открыть и потом закрыть\",\"offers\":[]},\"createdAt\":\"2024-09-10T09:09:55.550799+00:00\"}]}],\"users\":[{\"userId\":118090029,\"name\":\"118090029\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=118090029&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=118090029&t=2&p=2\"}}}],\"offers\":[{\"id\":305858924,\"userId\":120336895,\"url\":\"https://cian.ru/rent/flat/305858924/\",\"title\":\"1-комн. кв., 43 м², 4/25 этаж\",\"address\":\"Саратовская область, Саратов, улица Имени И.В. Мичурина, 18/68\",\"publishedUserId\":120336895,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2241306470-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2241306470-1.jpg\"},\"price\":\"49 000 ₽/мес.\",\"externalId\":\"69859129\"}]}",
            "user_id": 18435,
            "agency_id": 14103,
            "created_at": "2024-09-10 12:09:56.446896",
            "update_at": "2024-09-10 12:09:56.446896",
            "create_at_original": "2024-09-03 20:40:42",
            "update_at_original": "2024-09-10 12:09:55",
            "objectId": 69859129
        },
        {
            "id": 78973,
            "chatid": 92620086,
            "messageid": "83b2b3d929a14441a3fa8537b40bbe27186bef67ae35b6bd1278b11aa33c26ce",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":92620086,\"offerId\":305858924,\"createdAt\":\"2024-09-03T17:40:42.019194+00:00\",\"updatedAt\":\"2024-09-10T09:09:54.931132+00:00\",\"messages\":[{\"messageId\":\"83b2b3d929a14441a3fa8537b40bbe27186bef67ae35b6bd1278b11aa33c26ce\",\"userId\":118090029,\"direction\":\"in\",\"content\":{\"text\":\"Скажите,  а калитка которая ездит это , что кухня ? Я так понимаю , чтобы войти на кухню нужно калитку открыть и потом закрыть\",\"offers\":[]},\"createdAt\":\"2024-09-10T09:09:54.931132+00:00\"}]}],\"users\":[{\"userId\":118090029,\"name\":\"118090029\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=118090029&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=118090029&t=2&p=2\"}}}],\"offers\":[{\"id\":305858924,\"userId\":120336895,\"url\":\"https://cian.ru/rent/flat/305858924/\",\"title\":\"1-комн. кв., 43 м², 4/25 этаж\",\"address\":\"Саратовская область, Саратов, улица Имени И.В. Мичурина, 18/68\",\"publishedUserId\":120336895,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2241306470-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2241306470-1.jpg\"},\"price\":\"49 000 ₽/мес.\",\"externalId\":\"69859129\"}]}",
            "user_id": 18435,
            "agency_id": 14103,
            "created_at": "2024-09-10 12:09:55.831578",
            "update_at": "2024-09-10 12:09:55.831578",
            "create_at_original": "2024-09-03 20:40:42",
            "update_at_original": "2024-09-10 12:09:54",
            "objectId": 69859129
        },
        {
            "id": 78885,
            "chatid": 93418041,
            "messageid": "0b7ec6449e0a60879779b397e3a61ab27c9fe336db36a84e3068f0b08050d92f",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93418041,\"offerId\":306555307,\"createdAt\":\"2024-09-10T06:10:14.133080+00:00\",\"updatedAt\":\"2024-09-10T06:10:14.480206+00:00\",\"messages\":[{\"messageId\":\"0b7ec6449e0a60879779b397e3a61ab27c9fe336db36a84e3068f0b08050d92f\",\"userId\":105460459,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Уточните, пожалуйста, ещё продаёте? И какой вариант обмена предполагается?\",\"offers\":[]},\"createdAt\":\"2024-09-10T06:10:14.480206+00:00\"}]}],\"users\":[{\"userId\":105460459,\"name\":\"Анна Губская\",\"firstName\":\"Анна\",\"lastName\":\"Губская\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=105460459&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=105460459&t=2&p=2\"}}}],\"offers\":[{\"id\":306555307,\"userId\":107424518,\"url\":\"https://cian.ru/sale/suburban/306555307/\",\"title\":\"Дом, 100 м²\",\"address\":\"Саратовская область, Энгельс, улица Республики, 121\",\"publishedUserId\":107424518,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2253640144-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2253640144-1.jpg\"},\"price\":\"7 950 000 ₽\",\"externalId\":\"70889981\"}]}",
            "user_id": 15752,
            "agency_id": 14103,
            "created_at": "2024-09-10 09:10:15.480672",
            "update_at": "2024-09-10 09:10:15.480672",
            "create_at_original": "2024-09-10 09:10:14",
            "update_at_original": "2024-09-10 09:10:14",
            "objectId": 70889981
        },
        {
            "id": 78776,
            "chatid": 93036861,
            "messageid": "f7eda3aad179028ced062f9ff40a5f2f237ea606a18214f8b0fba269dad1f512",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93036861,\"offerId\":301202675,\"createdAt\":\"2024-09-06T18:48:50.763221+00:00\",\"updatedAt\":\"2024-09-09T15:16:51.189428+00:00\",\"messages\":[{\"messageId\":\"f7eda3aad179028ced062f9ff40a5f2f237ea606a18214f8b0fba269dad1f512\",\"userId\":121479329,\"direction\":\"in\",\"content\":{\"text\":\"Александр а что дача снята , мы же договорились что сегодня созвонимся?\",\"offers\":[]},\"createdAt\":\"2024-09-09T15:16:51.189428+00:00\"}]}],\"users\":[{\"userId\":121479329,\"name\":\"Ольга\",\"firstName\":\"Ольга\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=121479329&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=121479329&t=2&p=2\"}}}],\"offers\":[{\"id\":301202675,\"userId\":115760105,\"url\":\"https://cian.ru/sale/suburban/301202675/\",\"title\":\"Дом, 34 м²\",\"address\":\"Саратовская область, Энгельсский район, Красноярское муниципальное образование, Черемушки СНТ\",\"publishedUserId\":115760105,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2155282229-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2155282229-1.jpg\"},\"price\":\"550 000 ₽\",\"externalId\":\"64382251\"}]}",
            "user_id": 17706,
            "agency_id": 14103,
            "created_at": "2024-09-09 18:16:58.187664",
            "update_at": "2024-09-09 18:16:58.187664",
            "create_at_original": "2024-09-06 21:48:50",
            "update_at_original": "2024-09-09 18:16:51",
            "objectId": 64382251
        },
        {
            "id": 78603,
            "chatid": 92757257,
            "messageid": "1e53fc29783a8475405dada439c81a789c4ddb3110e013b6b30703f5fd23a23b",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":92757257,\"offerId\":306542405,\"createdAt\":\"2024-09-04T16:44:22.400432+00:00\",\"updatedAt\":\"2024-09-09T07:28:11.042127+00:00\",\"messages\":[{\"messageId\":\"1e53fc29783a8475405dada439c81a789c4ddb3110e013b6b30703f5fd23a23b\",\"userId\":107424518,\"direction\":\"out\",\"content\":{\"text\":\"я с вами связалась уже\",\"offers\":[]},\"createdAt\":\"2024-09-09T07:28:11.042127+00:00\"}]}],\"users\":[{\"userId\":107424518,\"name\":\"Анжелика Арсенева\",\"firstName\":\"Анжелика\",\"lastName\":\"Арсенева\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=107424518&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=107424518&t=2&p=2\"}}}],\"offers\":[{\"id\":306542405,\"userId\":107424518,\"url\":\"https://cian.ru/sale/flat/306542405/\",\"title\":\"2-комн. кв., 33 м², 9/9 этаж\",\"address\":\"Саратовская область, Энгельс, 1-й мкр, 13А\",\"publishedUserId\":107424518,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2253453175-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2253453175-1.jpg\"},\"price\":\"1 450 000 ₽\",\"externalId\":\"70883702\"}]}",
            "user_id": 15752,
            "agency_id": 14103,
            "created_at": "2024-09-09 10:28:11.849429",
            "update_at": "2024-09-09 10:28:11.849429",
            "create_at_original": "2024-09-04 19:44:22",
            "update_at_original": "2024-09-09 10:28:11",
            "objectId": 70883702
        },
        {
            "id": 78602,
            "chatid": 93289730,
            "messageid": "9f6be987c2d29b63f65d6ffec1b91ded4af62793b4601c4cf410982afddb1e8c",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93289730,\"offerId\":300860652,\"createdAt\":\"2024-09-09T07:25:33.484911+00:00\",\"updatedAt\":\"2024-09-09T07:27:52.353301+00:00\",\"messages\":[{\"messageId\":\"9f6be987c2d29b63f65d6ffec1b91ded4af62793b4601c4cf410982afddb1e8c\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Телефон для связи 89271686925\",\"offers\":[]},\"createdAt\":\"2024-09-09T07:27:52.353301+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-09 10:27:53.119485",
            "update_at": "2024-09-09 10:27:53.119485",
            "create_at_original": "2024-09-09 10:25:33",
            "update_at_original": "2024-09-09 10:27:52",
            "objectId": 63977339
        },
        {
            "id": 78601,
            "chatid": 93289730,
            "messageid": "a6bc834a97917e04e0e5fbc1a5832e8b5640ce57a750dc8dc3e7952a058a4491",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93289730,\"offerId\":300860652,\"createdAt\":\"2024-09-09T07:25:33.484911+00:00\",\"updatedAt\":\"2024-09-09T07:27:38.959525+00:00\",\"messages\":[{\"messageId\":\"a6bc834a97917e04e0e5fbc1a5832e8b5640ce57a750dc8dc3e7952a058a4491\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте. Да\",\"offers\":[]},\"createdAt\":\"2024-09-09T07:27:38.959525+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-09 10:27:39.826319",
            "update_at": "2024-09-09 10:27:39.826319",
            "create_at_original": "2024-09-09 10:25:33",
            "update_at_original": "2024-09-09 10:27:38",
            "objectId": 63977339
        },
        {
            "id": 78600,
            "chatid": 93289730,
            "messageid": "a4ba6a76e22c8f9fc5bdb25b4ca3512cffac08196331ab5d60b95dbb217f29ed",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93289730,\"offerId\":300860652,\"createdAt\":\"2024-09-09T07:25:33.484911+00:00\",\"updatedAt\":\"2024-09-09T07:25:33.673596+00:00\",\"messages\":[{\"messageId\":\"a4ba6a76e22c8f9fc5bdb25b4ca3512cffac08196331ab5d60b95dbb217f29ed\",\"userId\":93637329,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте ещё продается?\",\"offers\":[]},\"createdAt\":\"2024-09-09T07:25:33.673596+00:00\"}]}],\"users\":[{\"userId\":93637329,\"name\":\"93637329\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=93637329&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=93637329&t=2&p=2\"}}}],\"offers\":[{\"id\":300860652,\"userId\":115390423,\"url\":\"https://cian.ru/sale/suburban/300860652/\",\"title\":\"Дом, 73 м²\",\"address\":\"Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148334102-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148334102-1.jpg\"},\"price\":\"400 000 ₽\",\"externalId\":\"63977339\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-09 10:25:34.261723",
            "update_at": "2024-09-09 10:25:34.261723",
            "create_at_original": "2024-09-09 10:25:33",
            "update_at_original": "2024-09-09 10:25:33",
            "objectId": 63977339
        },
        {
            "id": 78595,
            "chatid": 92757257,
            "messageid": "b860342b54857da1f0a80d9aaafeaef98ed9bb66c35917af0e45174dbabe9a9d",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":92757257,\"offerId\":306542405,\"createdAt\":\"2024-09-04T16:44:22.400432+00:00\",\"updatedAt\":\"2024-09-09T06:49:47.876781+00:00\",\"messages\":[{\"messageId\":\"b860342b54857da1f0a80d9aaafeaef98ed9bb66c35917af0e45174dbabe9a9d\",\"userId\":120665117,\"direction\":\"in\",\"content\":{\"text\":\"Вы можете ответить?\",\"offers\":[]},\"createdAt\":\"2024-09-09T06:49:47.876781+00:00\"}]}],\"users\":[{\"userId\":120665117,\"name\":\"Юрий\",\"firstName\":\"Юрий\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=120665117&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=120665117&t=2&p=2\"}}}],\"offers\":[{\"id\":306542405,\"userId\":107424518,\"url\":\"https://cian.ru/sale/flat/306542405/\",\"title\":\"2-комн. кв., 33 м², 9/9 этаж\",\"address\":\"Саратовская область, Энгельс, 1-й мкр, 13А\",\"publishedUserId\":107424518,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2253453175-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2253453175-1.jpg\"},\"price\":\"1 450 000 ₽\",\"externalId\":\"70883702\"}]}",
            "user_id": 15752,
            "agency_id": 14103,
            "created_at": "2024-09-09 09:49:48.668698",
            "update_at": "2024-09-09 09:49:48.668698",
            "create_at_original": "2024-09-04 19:44:22",
            "update_at_original": "2024-09-09 09:49:47",
            "objectId": 70883702
        },
        {
            "id": 78583,
            "chatid": 93171686,
            "messageid": "60b9ed0545e1ac8273c17e02ebafcd120960c09910b5110160f0f6968ce0a2bb",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93171686,\"offerId\":298211630,\"createdAt\":\"2024-09-08T06:17:02.361138+00:00\",\"updatedAt\":\"2024-09-09T05:40:47.674784+00:00\",\"messages\":[{\"messageId\":\"60b9ed0545e1ac8273c17e02ebafcd120960c09910b5110160f0f6968ce0a2bb\",\"userId\":57555348,\"direction\":\"in\",\"content\":{\"text\":\"Пожалуйста, позвоните, когда будет удобно. Вот мой номер\\n+79372627191\",\"offers\":[]},\"createdAt\":\"2024-09-09T05:40:47.674784+00:00\"}]}],\"users\":[{\"userId\":57555348,\"name\":\"Юлия Сидорова\",\"firstName\":\"Юлия\",\"lastName\":\"Сидорова\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=57555348&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=57555348&t=2&p=2\"}}}],\"offers\":[{\"id\":298211630,\"userId\":107946441,\"url\":\"https://cian.ru/sale/flat/298211630/\",\"title\":\"1-комн. кв., 45 м², 1/15 этаж\",\"address\":\"Саратовская область, Саратов, Лунная улица, 25Б\",\"publishedUserId\":107946441,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2094955541-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2094955541-1.jpg\"},\"price\":\"4 470 000 ₽\",\"externalId\":\"61132114\"}]}",
            "user_id": 15882,
            "agency_id": 14103,
            "created_at": "2024-09-09 08:40:48.458249",
            "update_at": "2024-09-09 08:40:48.458249",
            "create_at_original": "2024-09-08 09:17:02",
            "update_at_original": "2024-09-09 08:40:47",
            "objectId": 61132114
        },
        {
            "id": 78476,
            "chatid": 93204153,
            "messageid": "92ceb21a99b11e866a0e48cd90cb6d7db4484d57d4ab6a24c8df04426730ce6b",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93204153,\"offerId\":298864653,\"createdAt\":\"2024-09-08T11:19:02.245810+00:00\",\"updatedAt\":\"2024-09-08T11:19:02.388388+00:00\",\"messages\":[{\"messageId\":\"92ceb21a99b11e866a0e48cd90cb6d7db4484d57d4ab6a24c8df04426730ce6b\",\"userId\":121469726,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Пожалуйста, свяжитесь со мной +79172067112\",\"offers\":[]},\"createdAt\":\"2024-09-08T11:19:02.388388+00:00\"}]}],\"users\":[{\"userId\":121469726,\"name\":\"Марина\",\"firstName\":\"Марина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=121469726&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=121469726&t=2&p=2\"}}}],\"offers\":[{\"id\":298864653,\"userId\":114681854,\"url\":\"https://cian.ru/sale/suburban/298864653/\",\"title\":\"Дом, 99 м²\",\"address\":\"Саратовская область, Энгельсский район, Красноярское муниципальное образование, с. Шумейка, улица Бережная, 58\",\"publishedUserId\":114681854,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2106995175-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2106995175-1.jpg\"},\"price\":\"5 200 000 ₽\",\"externalId\":\"61836066\"}]}",
            "user_id": 14236,
            "agency_id": 14103,
            "created_at": "2024-09-08 14:19:03.3488",
            "update_at": "2024-09-08 14:19:03.3488",
            "create_at_original": "2024-09-08 14:19:02",
            "update_at_original": "2024-09-08 14:19:02",
            "objectId": 61836066
        },
        {
            "id": 78451,
            "chatid": 93171686,
            "messageid": "3398abfffaac5a42283c8f126ffcf39a61c47c79e9440b51a70dae75c2c1a86e",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93171686,\"offerId\":298211630,\"createdAt\":\"2024-09-08T06:17:02.361138+00:00\",\"updatedAt\":\"2024-09-08T07:21:57.803544+00:00\",\"messages\":[{\"messageId\":\"3398abfffaac5a42283c8f126ffcf39a61c47c79e9440b51a70dae75c2c1a86e\",\"userId\":107946441,\"direction\":\"out\",\"content\":{\"text\":\"Юлия, добрый день! Сегодня, к сожалению, нет возможности показать квартиру. Можем договориться на завтра на это время. Позвоните мне, пжл, не могу до вас дозвониться\",\"offers\":[]},\"createdAt\":\"2024-09-08T07:21:57.803544+00:00\"}]}],\"users\":[{\"userId\":107946441,\"name\":\"Евгения Баракова\",\"firstName\":\"Евгения\",\"lastName\":\"Баракова\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=107946441&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=107946441&t=2&p=2\"}}}],\"offers\":[{\"id\":298211630,\"userId\":107946441,\"url\":\"https://cian.ru/sale/flat/298211630/\",\"title\":\"1-комн. кв., 45 м², 1/15 этаж\",\"address\":\"Саратовская область, Саратов, Лунная улица, 25Б\",\"publishedUserId\":107946441,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2094955541-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2094955541-1.jpg\"},\"price\":\"4 470 000 ₽\",\"externalId\":\"61132114\"}]}",
            "user_id": 15882,
            "agency_id": 14103,
            "created_at": "2024-09-08 10:21:58.670271",
            "update_at": "2024-09-08 10:21:58.670271",
            "create_at_original": "2024-09-08 09:17:02",
            "update_at_original": "2024-09-08 10:21:57",
            "objectId": 61132114
        },
        {
            "id": 78438,
            "chatid": 93171686,
            "messageid": "6b333c5e220680b94ebde974f1d660874f615fab5c48b74e1a708345eec94584",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93171686,\"offerId\":298211630,\"createdAt\":\"2024-09-08T06:17:02.361138+00:00\",\"updatedAt\":\"2024-09-08T06:17:02.709053+00:00\",\"messages\":[{\"messageId\":\"6b333c5e220680b94ebde974f1d660874f615fab5c48b74e1a708345eec94584\",\"userId\":57555348,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Подскажите, пожалуйста, сегодня в 14-30 можно посмотреть квартиру?\",\"offers\":[]},\"createdAt\":\"2024-09-08T06:17:02.709053+00:00\"}]}],\"users\":[{\"userId\":57555348,\"name\":\"Юлия Сидорова\",\"firstName\":\"Юлия\",\"lastName\":\"Сидорова\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=57555348&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=57555348&t=2&p=2\"}}}],\"offers\":[{\"id\":298211630,\"userId\":107946441,\"url\":\"https://cian.ru/sale/flat/298211630/\",\"title\":\"1-комн. кв., 45 м², 1/15 этаж\",\"address\":\"Саратовская область, Саратов, Лунная улица, 25Б\",\"publishedUserId\":107946441,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2094955541-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2094955541-1.jpg\"},\"price\":\"4 470 000 ₽\",\"externalId\":\"61132114\"}]}",
            "user_id": 15882,
            "agency_id": 14103,
            "created_at": "2024-09-08 09:17:03.687911",
            "update_at": "2024-09-08 09:17:03.687911",
            "create_at_original": "2024-09-08 09:17:02",
            "update_at_original": "2024-09-08 09:17:02",
            "objectId": 61132114
        },
        {
            "id": 78285,
            "chatid": 93046615,
            "messageid": "f73fa1ff31292cc36f9a79f66ffeaac4ee6b947f7d2e830f3e79add07dc77042",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93046615,\"offerId\":300844100,\"createdAt\":\"2024-09-06T22:28:15.639527+00:00\",\"updatedAt\":\"2024-09-07T07:02:01.768955+00:00\",\"messages\":[{\"messageId\":\"f73fa1ff31292cc36f9a79f66ffeaac4ee6b947f7d2e830f3e79add07dc77042\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"До встречи!\",\"offers\":[]},\"createdAt\":\"2024-09-07T07:02:01.768955+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300844100,\"userId\":115390423,\"url\":\"https://cian.ru/sale/flat/300844100/\",\"title\":\"2-комн. кв., 44,4 м², 1/4 этаж\",\"address\":\"Саратовская область, Красный Октябрь рп, улица Нефтяников, 38\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148052782-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148052782-1.jpg\"},\"price\":\"1 850 000 ₽\",\"externalId\":\"63832575\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-07 10:02:02.356497",
            "update_at": "2024-09-07 10:02:02.356497",
            "create_at_original": "2024-09-07 01:28:15",
            "update_at_original": "2024-09-07 10:02:01",
            "objectId": 63832575
        },
        {
            "id": 78284,
            "chatid": 93046615,
            "messageid": "bc93fd2a24599f5612930c30e5ed9de967378db619cbf9c4aa192ce48d53088a",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93046615,\"offerId\":300844100,\"createdAt\":\"2024-09-06T22:28:15.639527+00:00\",\"updatedAt\":\"2024-09-07T06:58:55.943480+00:00\",\"messages\":[{\"messageId\":\"bc93fd2a24599f5612930c30e5ed9de967378db619cbf9c4aa192ce48d53088a\",\"userId\":44322588,\"direction\":\"in\",\"content\":{\"text\":\"Мы приедем\",\"offers\":[]},\"createdAt\":\"2024-09-07T06:58:55.943480+00:00\"}]}],\"users\":[{\"userId\":44322588,\"name\":\"Юлия Павлович\",\"firstName\":\"Юлия\",\"lastName\":\"Павлович\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=44322588&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=44322588&t=2&p=2\"}}}],\"offers\":[{\"id\":300844100,\"userId\":115390423,\"url\":\"https://cian.ru/sale/flat/300844100/\",\"title\":\"2-комн. кв., 44,4 м², 1/4 этаж\",\"address\":\"Саратовская область, Красный Октябрь рп, улица Нефтяников, 38\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148052782-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148052782-1.jpg\"},\"price\":\"1 850 000 ₽\",\"externalId\":\"63832575\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-07 09:58:56.639494",
            "update_at": "2024-09-07 09:58:56.639494",
            "create_at_original": "2024-09-07 01:28:15",
            "update_at_original": "2024-09-07 09:58:55",
            "objectId": 63832575
        },
        {
            "id": 78283,
            "chatid": 93046615,
            "messageid": "90cafb02402899c1bb609bfe66e077cd43949ad7e4ea63609c4556bf2631ee92",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93046615,\"offerId\":300844100,\"createdAt\":\"2024-09-06T22:28:15.639527+00:00\",\"updatedAt\":\"2024-09-07T06:58:49.785201+00:00\",\"messages\":[{\"messageId\":\"90cafb02402899c1bb609bfe66e077cd43949ad7e4ea63609c4556bf2631ee92\",\"userId\":44322588,\"direction\":\"in\",\"content\":{\"text\":\"Спасибо\",\"offers\":[]},\"createdAt\":\"2024-09-07T06:58:49.785201+00:00\"}]}],\"users\":[{\"userId\":44322588,\"name\":\"Юлия Павлович\",\"firstName\":\"Юлия\",\"lastName\":\"Павлович\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=44322588&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=44322588&t=2&p=2\"}}}],\"offers\":[{\"id\":300844100,\"userId\":115390423,\"url\":\"https://cian.ru/sale/flat/300844100/\",\"title\":\"2-комн. кв., 44,4 м², 1/4 этаж\",\"address\":\"Саратовская область, Красный Октябрь рп, улица Нефтяников, 38\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148052782-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148052782-1.jpg\"},\"price\":\"1 850 000 ₽\",\"externalId\":\"63832575\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-07 09:58:50.538021",
            "update_at": "2024-09-07 09:58:50.538021",
            "create_at_original": "2024-09-07 01:28:15",
            "update_at_original": "2024-09-07 09:58:49",
            "objectId": 63832575
        },
        {
            "id": 78262,
            "chatid": 93046615,
            "messageid": "cea189e22dc6f4373b20d509f35f188caf0ff5138589cd8ec1997c63b6d9562c",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93046615,\"offerId\":300844100,\"createdAt\":\"2024-09-06T22:28:15.639527+00:00\",\"updatedAt\":\"2024-09-07T04:20:46.246870+00:00\",\"messages\":[{\"messageId\":\"cea189e22dc6f4373b20d509f35f188caf0ff5138589cd8ec1997c63b6d9562c\",\"userId\":115390423,\"direction\":\"out\",\"content\":{\"text\":\"Здравствуйте. Мой номер 89271686925.\\nЯ как раз сегодня буду показывать эту квартиру в 13.00\\nПокажем и вам в 15.00\",\"offers\":[]},\"createdAt\":\"2024-09-07T04:20:46.246870+00:00\"}]}],\"users\":[{\"userId\":115390423,\"name\":\"Анастасия Лачугина\",\"firstName\":\"Анастасия\",\"lastName\":\"Лачугина\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2\"}}}],\"offers\":[{\"id\":300844100,\"userId\":115390423,\"url\":\"https://cian.ru/sale/flat/300844100/\",\"title\":\"2-комн. кв., 44,4 м², 1/4 этаж\",\"address\":\"Саратовская область, Красный Октябрь рп, улица Нефтяников, 38\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148052782-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148052782-1.jpg\"},\"price\":\"1 850 000 ₽\",\"externalId\":\"63832575\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-07 07:20:47.118187",
            "update_at": "2024-09-07 07:20:47.118187",
            "create_at_original": "2024-09-07 01:28:15",
            "update_at_original": "2024-09-07 07:20:46",
            "objectId": 63832575
        },
        {
            "id": 78252,
            "chatid": 93046615,
            "messageid": "4307e6ee694425924c50f4cd4698d8db52ba87b610204e22a48040a96a957527",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93046615,\"offerId\":300844100,\"createdAt\":\"2024-09-06T22:28:15.639527+00:00\",\"updatedAt\":\"2024-09-06T22:29:48.121377+00:00\",\"messages\":[{\"messageId\":\"4307e6ee694425924c50f4cd4698d8db52ba87b610204e22a48040a96a957527\",\"userId\":44322588,\"direction\":\"in\",\"content\":{\"text\":\"Пожалуйста, позвоните, когда будет удобно. Вот мой номер\\n+79658891915\",\"offers\":[]},\"createdAt\":\"2024-09-06T22:29:48.121377+00:00\"}]}],\"users\":[{\"userId\":44322588,\"name\":\"Юлия Павлович\",\"firstName\":\"Юлия\",\"lastName\":\"Павлович\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=44322588&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=44322588&t=2&p=2\"}}}],\"offers\":[{\"id\":300844100,\"userId\":115390423,\"url\":\"https://cian.ru/sale/flat/300844100/\",\"title\":\"2-комн. кв., 44,4 м², 1/4 этаж\",\"address\":\"Саратовская область, Красный Октябрь рп, улица Нефтяников, 38\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148052782-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148052782-1.jpg\"},\"price\":\"1 850 000 ₽\",\"externalId\":\"63832575\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-07 01:29:48.878293",
            "update_at": "2024-09-07 01:29:48.878293",
            "create_at_original": "2024-09-07 01:28:15",
            "update_at_original": "2024-09-07 01:29:48",
            "objectId": 63832575
        },
        {
            "id": 78251,
            "chatid": 93046615,
            "messageid": "1ccf14fa3ba2cbdf7313071909c79ef13e4e1ce54ffbf0cb1cd7a33bff24fedb",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93046615,\"offerId\":300844100,\"createdAt\":\"2024-09-06T22:28:15.639527+00:00\",\"updatedAt\":\"2024-09-06T22:28:15.853629+00:00\",\"messages\":[{\"messageId\":\"1ccf14fa3ba2cbdf7313071909c79ef13e4e1ce54ffbf0cb1cd7a33bff24fedb\",\"userId\":44322588,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Подскажите, пожалуйста, когда можно посмотреть квартиру? Мы будем там в 15.00 завтра\",\"offers\":[]},\"createdAt\":\"2024-09-06T22:28:15.853629+00:00\"}]}],\"users\":[{\"userId\":44322588,\"name\":\"Юлия Павлович\",\"firstName\":\"Юлия\",\"lastName\":\"Павлович\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=44322588&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=44322588&t=2&p=2\"}}}],\"offers\":[{\"id\":300844100,\"userId\":115390423,\"url\":\"https://cian.ru/sale/flat/300844100/\",\"title\":\"2-комн. кв., 44,4 м², 1/4 этаж\",\"address\":\"Саратовская область, Красный Октябрь рп, улица Нефтяников, 38\",\"publishedUserId\":115390423,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2148052782-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2148052782-1.jpg\"},\"price\":\"1 850 000 ₽\",\"externalId\":\"63832575\"}]}",
            "user_id": 17575,
            "agency_id": 14103,
            "created_at": "2024-09-07 01:28:16.706187",
            "update_at": "2024-09-07 01:28:16.706187",
            "create_at_original": "2024-09-07 01:28:15",
            "update_at_original": "2024-09-07 01:28:15",
            "objectId": 63832575
        },
        {
            "id": 78245,
            "chatid": 93036861,
            "messageid": "f02d01cececf9d9c2438765f1279a545ce80a4f943c023937fea858116dbc591",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":93036861,\"offerId\":301202675,\"createdAt\":\"2024-09-06T18:48:50.763221+00:00\",\"updatedAt\":\"2024-09-06T18:48:50.841679+00:00\",\"messages\":[{\"messageId\":\"f02d01cececf9d9c2438765f1279a545ce80a4f943c023937fea858116dbc591\",\"userId\":121479329,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте. Подскажите, пожалуйста, когда можно посмотреть?\",\"offers\":[]},\"createdAt\":\"2024-09-06T18:48:50.841679+00:00\"}]}],\"users\":[{\"userId\":121479329,\"name\":\"121479329\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=121479329&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=121479329&t=2&p=2\"}}}],\"offers\":[{\"id\":301202675,\"userId\":115760105,\"url\":\"https://cian.ru/sale/suburban/301202675/\",\"title\":\"Дом, 34 м²\",\"address\":\"Саратовская область, Энгельсский район, Красноярское муниципальное образование, Черемушки СНТ\",\"publishedUserId\":115760105,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2155282229-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2155282229-1.jpg\"},\"price\":\"550 000 ₽\",\"externalId\":\"64382251\"}]}",
            "user_id": 17706,
            "agency_id": 14103,
            "created_at": "2024-09-06 21:48:51.688122",
            "update_at": "2024-09-06 21:48:51.688122",
            "create_at_original": "2024-09-06 21:48:50",
            "update_at_original": "2024-09-06 21:48:50",
            "objectId": 64382251
        },
        {
            "id": 78104,
            "chatid": 92894215,
            "messageid": "c6dc0003194271f5f0e2821f52300f1c40802f4481e61671106c15c48238b6a8",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":92894215,\"offerId\":299630927,\"createdAt\":\"2024-09-05T16:24:05.057646+00:00\",\"updatedAt\":\"2024-09-06T09:03:40.550925+00:00\",\"messages\":[{\"messageId\":\"c6dc0003194271f5f0e2821f52300f1c40802f4481e61671106c15c48238b6a8\",\"userId\":98314202,\"direction\":\"in\",\"content\":{\"text\":\"+79678007777\",\"offers\":[]},\"createdAt\":\"2024-09-06T09:03:40.550925+00:00\"}]}],\"users\":[{\"userId\":98314202,\"name\":\"98314202\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=98314202&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=98314202&t=2&p=2\"}}}],\"offers\":[{\"id\":299630927,\"userId\":104367792,\"url\":\"https://cian.ru/sale/suburban/299630927/\",\"title\":\"Дом, 115 м²\",\"address\":\"Саратовская область, Энгельс, Подгорная улица\",\"publishedUserId\":104367792,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2122943048-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2122943048-1.jpg\"},\"price\":\"3 000 000 ₽\",\"externalId\":\"49370493\"}]}",
            "user_id": 14832,
            "agency_id": 14103,
            "created_at": "2024-09-06 12:03:41.406831",
            "update_at": "2024-09-06 12:03:41.406831",
            "create_at_original": "2024-09-05 19:24:05",
            "update_at_original": "2024-09-06 12:03:40",
            "objectId": 49370493
        },
        {
            "id": 78061,
            "chatid": 92894215,
            "messageid": "b33815cbf0e12c6bf898e7527344b21cfd9c96971d4dc3ec3889fbb8c36f65f6",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":92894215,\"offerId\":299630927,\"createdAt\":\"2024-09-05T16:24:05.057646+00:00\",\"updatedAt\":\"2024-09-06T05:56:38.997800+00:00\",\"messages\":[{\"messageId\":\"b33815cbf0e12c6bf898e7527344b21cfd9c96971d4dc3ec3889fbb8c36f65f6\",\"userId\":104367792,\"direction\":\"out\",\"content\":{\"text\":\"Добрый день, актуально. Пришлите свой номер телефона или позвоните 8-963-1120775 для более подробной информации.\",\"offers\":[]},\"createdAt\":\"2024-09-06T05:56:38.997800+00:00\"}]}],\"users\":[{\"userId\":104367792,\"name\":\"Дамир Алимбеков\",\"firstName\":\"Дамир\",\"lastName\":\"Алимбеков\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=104367792&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=104367792&t=2&p=2\"}}}],\"offers\":[{\"id\":299630927,\"userId\":104367792,\"url\":\"https://cian.ru/sale/suburban/299630927/\",\"title\":\"Дом, 115 м²\",\"address\":\"Саратовская область, Энгельс, Подгорная улица\",\"publishedUserId\":104367792,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2122943048-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2122943048-1.jpg\"},\"price\":\"3 000 000 ₽\",\"externalId\":\"49370493\"}]}",
            "user_id": 14832,
            "agency_id": 14103,
            "created_at": "2024-09-06 08:56:39.768221",
            "update_at": "2024-09-06 08:56:39.768221",
            "create_at_original": "2024-09-05 19:24:05",
            "update_at_original": "2024-09-06 08:56:38",
            "objectId": 49370493
        },
        {
            "id": 78035,
            "chatid": 92911724,
            "messageid": "5572c1b3d146687f61e5c2f721a38c2625c8a1eccf4c5c42a7aa3abd035f8e3f",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":92911724,\"offerId\":306767580,\"createdAt\":\"2024-09-05T19:49:31.821762+00:00\",\"updatedAt\":\"2024-09-05T19:49:31.911302+00:00\",\"messages\":[{\"messageId\":\"5572c1b3d146687f61e5c2f721a38c2625c8a1eccf4c5c42a7aa3abd035f8e3f\",\"userId\":96428038,\"direction\":\"in\",\"content\":{\"text\":\"Добрый вечер, а есть планировка дома?\",\"offers\":[]},\"createdAt\":\"2024-09-05T19:49:31.911302+00:00\"}]}],\"users\":[{\"userId\":96428038,\"name\":\"96428038\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=96428038&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=96428038&t=2&p=2\"}}}],\"offers\":[{\"id\":306767580,\"userId\":114681854,\"url\":\"https://cian.ru/sale/suburban/306767580/\",\"title\":\"Дом, 112,4 м²\",\"address\":\"Саратовская область, Энгельсский район, Новопушкинское муниципальное образование, пос. Пробуждение, улица Весенняя, 16\",\"publishedUserId\":114681854,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2257367207-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2257367207-1.jpg\"},\"price\":\"6 700 000 ₽\",\"externalId\":\"71103623\"}]}",
            "user_id": 14236,
            "agency_id": 14103,
            "created_at": "2024-09-05 22:49:32.719927",
            "update_at": "2024-09-05 22:49:32.719927",
            "create_at_original": "2024-09-05 22:49:31",
            "update_at_original": "2024-09-05 22:49:31",
            "objectId": 71103623
        },
        {
            "id": 77997,
            "chatid": 92894215,
            "messageid": "0763f36ede19e7ddc7e5f08b246ba1cf42818ad0b790f3ca37c959db0fa0007a",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":92894215,\"offerId\":299630927,\"createdAt\":\"2024-09-05T16:24:05.057646+00:00\",\"updatedAt\":\"2024-09-05T16:24:05.271243+00:00\",\"messages\":[{\"messageId\":\"0763f36ede19e7ddc7e5f08b246ba1cf42818ad0b790f3ca37c959db0fa0007a\",\"userId\":98314202,\"direction\":\"in\",\"content\":{\"text\":\"Добрый вечер, объявление актуально?\",\"offers\":[]},\"createdAt\":\"2024-09-05T16:24:05.271243+00:00\"}]}],\"users\":[{\"userId\":98314202,\"name\":\"98314202\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=98314202&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=98314202&t=2&p=2\"}}}],\"offers\":[{\"id\":299630927,\"userId\":104367792,\"url\":\"https://cian.ru/sale/suburban/299630927/\",\"title\":\"Дом, 115 м²\",\"address\":\"Саратовская область, Энгельс, Подгорная улица\",\"publishedUserId\":104367792,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2122943048-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2122943048-1.jpg\"},\"price\":\"3 000 000 ₽\",\"externalId\":\"49370493\"}]}",
            "user_id": 14832,
            "agency_id": 14103,
            "created_at": "2024-09-05 19:24:06.159205",
            "update_at": "2024-09-05 19:24:06.159205",
            "create_at_original": "2024-09-05 19:24:05",
            "update_at_original": "2024-09-05 19:24:05",
            "objectId": 49370493
        },
        {
            "id": 77787,
            "chatid": 92757257,
            "messageid": "0df2328f3d86bcc99aaa18882e8221b844e7162b4df1b39f2786bb13207217ae",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":92757257,\"offerId\":306542405,\"createdAt\":\"2024-09-04T16:44:22.400432+00:00\",\"updatedAt\":\"2024-09-04T16:44:22.524388+00:00\",\"messages\":[{\"messageId\":\"0df2328f3d86bcc99aaa18882e8221b844e7162b4df1b39f2786bb13207217ae\",\"userId\":120665117,\"direction\":\"in\",\"content\":{\"text\":\"Цена окончательная?\",\"offers\":[]},\"createdAt\":\"2024-09-04T16:44:22.524388+00:00\"}]}],\"users\":[{\"userId\":120665117,\"name\":\"Юрий\",\"firstName\":\"Юрий\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=120665117&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=120665117&t=2&p=2\"}}}],\"offers\":[{\"id\":306542405,\"userId\":107424518,\"url\":\"https://cian.ru/sale/flat/306542405/\",\"title\":\"2-комн. кв., 33 м², 9/9 этаж\",\"address\":\"Саратовская область, Энгельс, 1-й мкр, 13А\",\"publishedUserId\":107424518,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2253453175-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2253453175-1.jpg\"},\"price\":\"1 450 000 ₽\",\"externalId\":\"70883702\"}]}",
            "user_id": 15752,
            "agency_id": 14103,
            "created_at": "2024-09-04 19:44:23.349864",
            "update_at": "2024-09-04 19:44:23.349864",
            "create_at_original": "2024-09-04 19:44:22",
            "update_at_original": "2024-09-04 19:44:22",
            "objectId": 70883702
        },
        {
            "id": 77603,
            "chatid": 92620086,
            "messageid": "721d51202371b4a14871fd34f2931a47ce473bb9bc674d8e42eed66f38e7862a",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":92620086,\"offerId\":305858924,\"createdAt\":\"2024-09-03T17:40:42.019194+00:00\",\"updatedAt\":\"2024-09-03T17:41:07.576928+00:00\",\"messages\":[{\"messageId\":\"721d51202371b4a14871fd34f2931a47ce473bb9bc674d8e42eed66f38e7862a\",\"userId\":118090029,\"direction\":\"in\",\"content\":{\"text\":\"Цена 40 или 49 там на сайте 2 обявления\",\"offers\":[]},\"createdAt\":\"2024-09-03T17:41:07.576928+00:00\"}]}],\"users\":[{\"userId\":118090029,\"name\":\"118090029\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=118090029&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=118090029&t=2&p=2\"}}}],\"offers\":[{\"id\":305858924,\"userId\":120336895,\"url\":\"https://cian.ru/rent/flat/305858924/\",\"title\":\"1-комн. кв., 43 м², 4/25 этаж\",\"address\":\"Саратовская область, Саратов, улица Имени И.В. Мичурина, 18/68\",\"publishedUserId\":120336895,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2241306470-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2241306470-1.jpg\"},\"price\":\"49 000 ₽/мес.\",\"externalId\":\"69859129\"}]}",
            "user_id": 0,
            "agency_id": 14103,
            "created_at": "2024-09-03 20:41:08.275895",
            "update_at": "2024-09-03 20:41:08.275895",
            "create_at_original": "2024-09-03 20:40:42",
            "update_at_original": "2024-09-03 20:41:07",
            "objectId": 69859129
        },
        {
            "id": 77602,
            "chatid": 92620086,
            "messageid": "e2bf2f686b322f287963b3b30da053fc4b6d63a49f6ed38af95f29ebcc7c15b5",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":92620086,\"offerId\":305858924,\"createdAt\":\"2024-09-03T17:40:42.019194+00:00\",\"updatedAt\":\"2024-09-03T17:40:42.125000+00:00\",\"messages\":[{\"messageId\":\"e2bf2f686b322f287963b3b30da053fc4b6d63a49f6ed38af95f29ebcc7c15b5\",\"userId\":118090029,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте\",\"offers\":[]},\"createdAt\":\"2024-09-03T17:40:42.125000+00:00\"}]}],\"users\":[{\"userId\":118090029,\"name\":\"118090029\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=118090029&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=118090029&t=2&p=2\"}}}],\"offers\":[{\"id\":305858924,\"userId\":120336895,\"url\":\"https://cian.ru/rent/flat/305858924/\",\"title\":\"1-комн. кв., 43 м², 4/25 этаж\",\"address\":\"Саратовская область, Саратов, улица Имени И.В. Мичурина, 18/68\",\"publishedUserId\":120336895,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2241306470-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2241306470-1.jpg\"},\"price\":\"49 000 ₽/мес.\",\"externalId\":\"69859129\"}]}",
            "user_id": 0,
            "agency_id": 14103,
            "created_at": "2024-09-03 20:40:42.912717",
            "update_at": "2024-09-03 20:40:42.912717",
            "create_at_original": "2024-09-03 20:40:42",
            "update_at_original": "2024-09-03 20:40:42",
            "objectId": 69859129
        },
        {
            "id": 77441,
            "chatid": 92535752,
            "messageid": "e54fc119a3a5d3e727fba2fd8300cd0d689ddc9e87fc4760418b0775c7ec8d0a",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":92535752,\"offerId\":305858924,\"createdAt\":\"2024-09-03T08:54:15.872938+00:00\",\"updatedAt\":\"2024-09-03T08:54:25.869304+00:00\",\"messages\":[{\"messageId\":\"e54fc119a3a5d3e727fba2fd8300cd0d689ddc9e87fc4760418b0775c7ec8d0a\",\"userId\":98183987,\"direction\":\"in\",\"content\":{\"text\":\"Или вы риэлтор?\",\"offers\":[]},\"createdAt\":\"2024-09-03T08:54:25.869304+00:00\"}]}],\"users\":[{\"userId\":98183987,\"name\":\"98183987\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=98183987&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=98183987&t=2&p=2\"}}}],\"offers\":[{\"id\":305858924,\"userId\":120336895,\"url\":\"https://cian.ru/rent/flat/305858924/\",\"title\":\"1-комн. кв., 43 м², 4/25 этаж\",\"address\":\"Саратовская область, Саратов, улица Имени И.В. Мичурина, 18/68\",\"publishedUserId\":120336895,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2241306470-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2241306470-1.jpg\"},\"price\":\"40 000 ₽/мес.\",\"externalId\":\"69859129\"}]}",
            "user_id": 0,
            "agency_id": 14103,
            "created_at": "2024-09-03 11:54:26.635211",
            "update_at": "2024-09-03 11:54:26.635211",
            "create_at_original": "2024-09-03 11:54:15",
            "update_at_original": "2024-09-03 11:54:25",
            "objectId": 69859129
        },
        {
            "id": 77440,
            "chatid": 92535752,
            "messageid": "0bc4b9a5f2347c89e170db170f7d5dca99ff222e388f1591387b639d6d0e62fb",
            "new": 0,
            "type": "cian",
            "details": "{\"webhookType\":\"offersMessages\",\"chats\":[{\"chatId\":92535752,\"offerId\":305858924,\"createdAt\":\"2024-09-03T08:54:15.872938+00:00\",\"updatedAt\":\"2024-09-03T08:54:16.092389+00:00\",\"messages\":[{\"messageId\":\"0bc4b9a5f2347c89e170db170f7d5dca99ff222e388f1591387b639d6d0e62fb\",\"userId\":98183987,\"direction\":\"in\",\"content\":{\"text\":\"Здравствуйте,это ваша квартира ?\",\"offers\":[]},\"createdAt\":\"2024-09-03T08:54:16.092389+00:00\"}]}],\"users\":[{\"userId\":98183987,\"name\":\"98183987\",\"avatar\":{\"images\":{\"small\":\"https://www.cian.ru/avatar.axd?i=98183987&t=2&p=1\",\"big\":\"https://www.cian.ru/avatar.axd?i=98183987&t=2&p=2\"}}}],\"offers\":[{\"id\":305858924,\"userId\":120336895,\"url\":\"https://cian.ru/rent/flat/305858924/\",\"title\":\"1-комн. кв., 43 м², 4/25 этаж\",\"address\":\"Саратовская область, Саратов, улица Имени И.В. Мичурина, 18/68\",\"publishedUserId\":120336895,\"mainPhoto\":{\"miniUrl\":\"https://images.cdn-cian.ru/images/2241306470-3.jpg\",\"url\":\"https://images.cdn-cian.ru/images/2241306470-1.jpg\"},\"price\":\"40 000 ₽/мес.\",\"externalId\":\"69859129\"}]}",
            "user_id": 0,
            "agency_id": 14103,
            "created_at": "2024-09-03 11:54:17.399918",
            "update_at": "2024-09-03 11:54:17.399918",
            "create_at_original": "2024-09-03 11:54:15",
            "update_at_original": "2024-09-03 11:54:16",
            "objectId": 69859129
        }
    ],
    "details": {
        "77440": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 92535752,
                    "offerId": 305858924,
                    "createdAt": "2024-09-03T08:54:15.872938+00:00",
                    "updatedAt": "2024-09-03T08:54:16.092389+00:00",
                    "messages": [
                        {
                            "messageId": "0bc4b9a5f2347c89e170db170f7d5dca99ff222e388f1591387b639d6d0e62fb",
                            "userId": 98183987,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте,это ваша квартира ?",
                                "offers": []
                            },
                            "createdAt": "03.09.2024 10:54",
                            "new": 0,
                            "userName": "Пользователь ID 98183987"
                        }
                    ],
                    "update_at": "03.09.2024 10:54"
                }
            ],
            "users": [
                {
                    "userId": 98183987,
                    "name": "98183987",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=98183987&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=98183987&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 305858924,
                    "userId": 120336895,
                    "url": "https://cian.ru/rent/flat/305858924/",
                    "title": "1-комн. кв., 43 м², 4/25 этаж",
                    "address": "Саратовская область, Саратов, улица Имени И.В. Мичурина, 18/68",
                    "publishedUserId": 120336895,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2241306470-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2241306470-1.jpg"
                    },
                    "price": "40 000 ₽/мес.",
                    "externalId": "69859129"
                }
            ],
            "objectId": 69859129
        },
        "77441": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 92535752,
                    "offerId": 305858924,
                    "createdAt": "2024-09-03T08:54:15.872938+00:00",
                    "updatedAt": "2024-09-03T08:54:25.869304+00:00",
                    "messages": [
                        {
                            "messageId": "e54fc119a3a5d3e727fba2fd8300cd0d689ddc9e87fc4760418b0775c7ec8d0a",
                            "userId": 98183987,
                            "direction": "in",
                            "content": {
                                "text": "Или вы риэлтор?",
                                "offers": []
                            },
                            "createdAt": "03.09.2024 10:54",
                            "new": 0,
                            "userName": "Пользователь ID 98183987"
                        }
                    ],
                    "update_at": "03.09.2024 10:54"
                }
            ],
            "users": [
                {
                    "userId": 98183987,
                    "name": "98183987",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=98183987&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=98183987&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 305858924,
                    "userId": 120336895,
                    "url": "https://cian.ru/rent/flat/305858924/",
                    "title": "1-комн. кв., 43 м², 4/25 этаж",
                    "address": "Саратовская область, Саратов, улица Имени И.В. Мичурина, 18/68",
                    "publishedUserId": 120336895,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2241306470-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2241306470-1.jpg"
                    },
                    "price": "40 000 ₽/мес.",
                    "externalId": "69859129"
                }
            ],
            "objectId": 69859129
        },
        "77602": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 92620086,
                    "offerId": 305858924,
                    "createdAt": "2024-09-03T17:40:42.019194+00:00",
                    "updatedAt": "2024-09-03T17:40:42.125000+00:00",
                    "messages": [
                        {
                            "messageId": "e2bf2f686b322f287963b3b30da053fc4b6d63a49f6ed38af95f29ebcc7c15b5",
                            "userId": 118090029,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте",
                                "offers": []
                            },
                            "createdAt": "03.09.2024 19:40",
                            "new": 0,
                            "userName": "Пользователь ID 118090029"
                        }
                    ],
                    "update_at": "03.09.2024 19:40"
                }
            ],
            "users": [
                {
                    "userId": 118090029,
                    "name": "118090029",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=118090029&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=118090029&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 305858924,
                    "userId": 120336895,
                    "url": "https://cian.ru/rent/flat/305858924/",
                    "title": "1-комн. кв., 43 м², 4/25 этаж",
                    "address": "Саратовская область, Саратов, улица Имени И.В. Мичурина, 18/68",
                    "publishedUserId": 120336895,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2241306470-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2241306470-1.jpg"
                    },
                    "price": "49 000 ₽/мес.",
                    "externalId": "69859129"
                }
            ],
            "objectId": 69859129
        },
        "77603": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 92620086,
                    "offerId": 305858924,
                    "createdAt": "2024-09-03T17:40:42.019194+00:00",
                    "updatedAt": "2024-09-03T17:41:07.576928+00:00",
                    "messages": [
                        {
                            "messageId": "721d51202371b4a14871fd34f2931a47ce473bb9bc674d8e42eed66f38e7862a",
                            "userId": 118090029,
                            "direction": "in",
                            "content": {
                                "text": "Цена 40 или 49 там на сайте 2 обявления",
                                "offers": []
                            },
                            "createdAt": "03.09.2024 19:41",
                            "new": 0,
                            "userName": "Пользователь ID 118090029"
                        }
                    ],
                    "update_at": "03.09.2024 19:41"
                }
            ],
            "users": [
                {
                    "userId": 118090029,
                    "name": "118090029",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=118090029&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=118090029&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 305858924,
                    "userId": 120336895,
                    "url": "https://cian.ru/rent/flat/305858924/",
                    "title": "1-комн. кв., 43 м², 4/25 этаж",
                    "address": "Саратовская область, Саратов, улица Имени И.В. Мичурина, 18/68",
                    "publishedUserId": 120336895,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2241306470-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2241306470-1.jpg"
                    },
                    "price": "49 000 ₽/мес.",
                    "externalId": "69859129"
                }
            ],
            "objectId": 69859129
        },
        "77787": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 92757257,
                    "offerId": 306542405,
                    "createdAt": "2024-09-04T16:44:22.400432+00:00",
                    "updatedAt": "2024-09-04T16:44:22.524388+00:00",
                    "messages": [
                        {
                            "messageId": "0df2328f3d86bcc99aaa18882e8221b844e7162b4df1b39f2786bb13207217ae",
                            "userId": 120665117,
                            "direction": "in",
                            "content": {
                                "text": "Цена окончательная?",
                                "offers": []
                            },
                            "createdAt": "04.09.2024 18:44",
                            "new": 0,
                            "userName": "Юрий"
                        }
                    ],
                    "update_at": "04.09.2024 18:44"
                }
            ],
            "users": [
                {
                    "userId": 120665117,
                    "name": "Юрий",
                    "firstName": "Юрий",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=120665117&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=120665117&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306542405,
                    "userId": 107424518,
                    "url": "https://cian.ru/sale/flat/306542405/",
                    "title": "2-комн. кв., 33 м², 9/9 этаж",
                    "address": "Саратовская область, Энгельс, 1-й мкр, 13А",
                    "publishedUserId": 107424518,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2253453175-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2253453175-1.jpg"
                    },
                    "price": "1 450 000 ₽",
                    "externalId": "70883702"
                }
            ],
            "objectId": 70883702
        },
        "77997": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 92894215,
                    "offerId": 299630927,
                    "createdAt": "2024-09-05T16:24:05.057646+00:00",
                    "updatedAt": "2024-09-05T16:24:05.271243+00:00",
                    "messages": [
                        {
                            "messageId": "0763f36ede19e7ddc7e5f08b246ba1cf42818ad0b790f3ca37c959db0fa0007a",
                            "userId": 98314202,
                            "direction": "in",
                            "content": {
                                "text": "Добрый вечер, объявление актуально?",
                                "offers": []
                            },
                            "createdAt": "05.09.2024 18:24",
                            "new": 0,
                            "userName": "Пользователь ID 98314202"
                        }
                    ],
                    "update_at": "05.09.2024 18:24"
                }
            ],
            "users": [
                {
                    "userId": 98314202,
                    "name": "98314202",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=98314202&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=98314202&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 299630927,
                    "userId": 104367792,
                    "url": "https://cian.ru/sale/suburban/299630927/",
                    "title": "Дом, 115 м²",
                    "address": "Саратовская область, Энгельс, Подгорная улица",
                    "publishedUserId": 104367792,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2122943048-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2122943048-1.jpg"
                    },
                    "price": "3 000 000 ₽",
                    "externalId": "49370493"
                }
            ],
            "objectId": 49370493
        },
        "78035": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 92911724,
                    "offerId": 306767580,
                    "createdAt": "2024-09-05T19:49:31.821762+00:00",
                    "updatedAt": "2024-09-05T19:49:31.911302+00:00",
                    "messages": [
                        {
                            "messageId": "5572c1b3d146687f61e5c2f721a38c2625c8a1eccf4c5c42a7aa3abd035f8e3f",
                            "userId": 96428038,
                            "direction": "in",
                            "content": {
                                "text": "Добрый вечер, а есть планировка дома?",
                                "offers": []
                            },
                            "createdAt": "05.09.2024 21:49",
                            "new": 0,
                            "userName": "Пользователь ID 96428038"
                        }
                    ],
                    "update_at": "05.09.2024 21:49"
                }
            ],
            "users": [
                {
                    "userId": 96428038,
                    "name": "96428038",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=96428038&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=96428038&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306767580,
                    "userId": 114681854,
                    "url": "https://cian.ru/sale/suburban/306767580/",
                    "title": "Дом, 112,4 м²",
                    "address": "Саратовская область, Энгельсский район, Новопушкинское муниципальное образование, пос. Пробуждение, улица Весенняя, 16",
                    "publishedUserId": 114681854,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2257367207-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2257367207-1.jpg"
                    },
                    "price": "6 700 000 ₽",
                    "externalId": "71103623"
                }
            ],
            "objectId": 71103623
        },
        "78061": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 92894215,
                    "offerId": 299630927,
                    "createdAt": "2024-09-05T16:24:05.057646+00:00",
                    "updatedAt": "2024-09-06T05:56:38.997800+00:00",
                    "messages": [
                        {
                            "messageId": "b33815cbf0e12c6bf898e7527344b21cfd9c96971d4dc3ec3889fbb8c36f65f6",
                            "userId": 104367792,
                            "direction": "out",
                            "content": {
                                "text": "Добрый день, актуально. Пришлите свой номер телефона или позвоните 8-963-1120775 для более подробной информации.",
                                "offers": []
                            },
                            "createdAt": "06.09.2024 07:56",
                            "new": 0,
                            "userName": "Дамир Алимбеков"
                        }
                    ],
                    "update_at": "06.09.2024 07:56"
                }
            ],
            "users": [
                {
                    "userId": 104367792,
                    "name": "Дамир Алимбеков",
                    "firstName": "Дамир",
                    "lastName": "Алимбеков",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=104367792&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=104367792&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 299630927,
                    "userId": 104367792,
                    "url": "https://cian.ru/sale/suburban/299630927/",
                    "title": "Дом, 115 м²",
                    "address": "Саратовская область, Энгельс, Подгорная улица",
                    "publishedUserId": 104367792,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2122943048-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2122943048-1.jpg"
                    },
                    "price": "3 000 000 ₽",
                    "externalId": "49370493"
                }
            ],
            "objectId": 49370493
        },
        "78104": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 92894215,
                    "offerId": 299630927,
                    "createdAt": "2024-09-05T16:24:05.057646+00:00",
                    "updatedAt": "2024-09-06T09:03:40.550925+00:00",
                    "messages": [
                        {
                            "messageId": "c6dc0003194271f5f0e2821f52300f1c40802f4481e61671106c15c48238b6a8",
                            "userId": 98314202,
                            "direction": "in",
                            "content": {
                                "text": "+79678007777",
                                "offers": []
                            },
                            "createdAt": "06.09.2024 11:03",
                            "new": 0,
                            "userName": "Пользователь ID 98314202"
                        }
                    ],
                    "update_at": "06.09.2024 11:03"
                }
            ],
            "users": [
                {
                    "userId": 98314202,
                    "name": "98314202",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=98314202&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=98314202&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 299630927,
                    "userId": 104367792,
                    "url": "https://cian.ru/sale/suburban/299630927/",
                    "title": "Дом, 115 м²",
                    "address": "Саратовская область, Энгельс, Подгорная улица",
                    "publishedUserId": 104367792,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2122943048-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2122943048-1.jpg"
                    },
                    "price": "3 000 000 ₽",
                    "externalId": "49370493"
                }
            ],
            "objectId": 49370493
        },
        "78245": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93036861,
                    "offerId": 301202675,
                    "createdAt": "2024-09-06T18:48:50.763221+00:00",
                    "updatedAt": "2024-09-06T18:48:50.841679+00:00",
                    "messages": [
                        {
                            "messageId": "f02d01cececf9d9c2438765f1279a545ce80a4f943c023937fea858116dbc591",
                            "userId": 121479329,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Подскажите, пожалуйста, когда можно посмотреть?",
                                "offers": []
                            },
                            "createdAt": "06.09.2024 20:48",
                            "new": 0,
                            "userName": "Пользователь ID 121479329"
                        }
                    ],
                    "update_at": "06.09.2024 20:48"
                }
            ],
            "users": [
                {
                    "userId": 121479329,
                    "name": "121479329",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=121479329&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=121479329&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 301202675,
                    "userId": 115760105,
                    "url": "https://cian.ru/sale/suburban/301202675/",
                    "title": "Дом, 34 м²",
                    "address": "Саратовская область, Энгельсский район, Красноярское муниципальное образование, Черемушки СНТ",
                    "publishedUserId": 115760105,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2155282229-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2155282229-1.jpg"
                    },
                    "price": "550 000 ₽",
                    "externalId": "64382251"
                }
            ],
            "objectId": 64382251
        },
        "78251": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93046615,
                    "offerId": 300844100,
                    "createdAt": "2024-09-06T22:28:15.639527+00:00",
                    "updatedAt": "2024-09-06T22:28:15.853629+00:00",
                    "messages": [
                        {
                            "messageId": "1ccf14fa3ba2cbdf7313071909c79ef13e4e1ce54ffbf0cb1cd7a33bff24fedb",
                            "userId": 44322588,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Подскажите, пожалуйста, когда можно посмотреть квартиру? Мы будем там в 15.00 завтра",
                                "offers": []
                            },
                            "createdAt": "07.09.2024 00:28",
                            "new": 0,
                            "userName": "Юлия Павлович"
                        }
                    ],
                    "update_at": "07.09.2024 00:28"
                }
            ],
            "users": [
                {
                    "userId": 44322588,
                    "name": "Юлия Павлович",
                    "firstName": "Юлия",
                    "lastName": "Павлович",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=44322588&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=44322588&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300844100,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/flat/300844100/",
                    "title": "2-комн. кв., 44,4 м², 1/4 этаж",
                    "address": "Саратовская область, Красный Октябрь рп, улица Нефтяников, 38",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148052782-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148052782-1.jpg"
                    },
                    "price": "1 850 000 ₽",
                    "externalId": "63832575"
                }
            ],
            "objectId": 63832575
        },
        "78252": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93046615,
                    "offerId": 300844100,
                    "createdAt": "2024-09-06T22:28:15.639527+00:00",
                    "updatedAt": "2024-09-06T22:29:48.121377+00:00",
                    "messages": [
                        {
                            "messageId": "4307e6ee694425924c50f4cd4698d8db52ba87b610204e22a48040a96a957527",
                            "userId": 44322588,
                            "direction": "in",
                            "content": {
                                "text": "Пожалуйста, позвоните, когда будет удобно. Вот мой номер\n+79658891915",
                                "offers": []
                            },
                            "createdAt": "07.09.2024 00:29",
                            "new": 0,
                            "userName": "Юлия Павлович"
                        }
                    ],
                    "update_at": "07.09.2024 00:29"
                }
            ],
            "users": [
                {
                    "userId": 44322588,
                    "name": "Юлия Павлович",
                    "firstName": "Юлия",
                    "lastName": "Павлович",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=44322588&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=44322588&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300844100,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/flat/300844100/",
                    "title": "2-комн. кв., 44,4 м², 1/4 этаж",
                    "address": "Саратовская область, Красный Октябрь рп, улица Нефтяников, 38",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148052782-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148052782-1.jpg"
                    },
                    "price": "1 850 000 ₽",
                    "externalId": "63832575"
                }
            ],
            "objectId": 63832575
        },
        "78262": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93046615,
                    "offerId": 300844100,
                    "createdAt": "2024-09-06T22:28:15.639527+00:00",
                    "updatedAt": "2024-09-07T04:20:46.246870+00:00",
                    "messages": [
                        {
                            "messageId": "cea189e22dc6f4373b20d509f35f188caf0ff5138589cd8ec1997c63b6d9562c",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте. Мой номер 89271686925.\nЯ как раз сегодня буду показывать эту квартиру в 13.00\nПокажем и вам в 15.00",
                                "offers": []
                            },
                            "createdAt": "07.09.2024 06:20",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "07.09.2024 06:20"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300844100,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/flat/300844100/",
                    "title": "2-комн. кв., 44,4 м², 1/4 этаж",
                    "address": "Саратовская область, Красный Октябрь рп, улица Нефтяников, 38",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148052782-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148052782-1.jpg"
                    },
                    "price": "1 850 000 ₽",
                    "externalId": "63832575"
                }
            ],
            "objectId": 63832575
        },
        "78283": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93046615,
                    "offerId": 300844100,
                    "createdAt": "2024-09-06T22:28:15.639527+00:00",
                    "updatedAt": "2024-09-07T06:58:49.785201+00:00",
                    "messages": [
                        {
                            "messageId": "90cafb02402899c1bb609bfe66e077cd43949ad7e4ea63609c4556bf2631ee92",
                            "userId": 44322588,
                            "direction": "in",
                            "content": {
                                "text": "Спасибо",
                                "offers": []
                            },
                            "createdAt": "07.09.2024 08:58",
                            "new": 0,
                            "userName": "Юлия Павлович"
                        }
                    ],
                    "update_at": "07.09.2024 08:58"
                }
            ],
            "users": [
                {
                    "userId": 44322588,
                    "name": "Юлия Павлович",
                    "firstName": "Юлия",
                    "lastName": "Павлович",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=44322588&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=44322588&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300844100,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/flat/300844100/",
                    "title": "2-комн. кв., 44,4 м², 1/4 этаж",
                    "address": "Саратовская область, Красный Октябрь рп, улица Нефтяников, 38",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148052782-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148052782-1.jpg"
                    },
                    "price": "1 850 000 ₽",
                    "externalId": "63832575"
                }
            ],
            "objectId": 63832575
        },
        "78284": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93046615,
                    "offerId": 300844100,
                    "createdAt": "2024-09-06T22:28:15.639527+00:00",
                    "updatedAt": "2024-09-07T06:58:55.943480+00:00",
                    "messages": [
                        {
                            "messageId": "bc93fd2a24599f5612930c30e5ed9de967378db619cbf9c4aa192ce48d53088a",
                            "userId": 44322588,
                            "direction": "in",
                            "content": {
                                "text": "Мы приедем",
                                "offers": []
                            },
                            "createdAt": "07.09.2024 08:58",
                            "new": 0,
                            "userName": "Юлия Павлович"
                        }
                    ],
                    "update_at": "07.09.2024 08:58"
                }
            ],
            "users": [
                {
                    "userId": 44322588,
                    "name": "Юлия Павлович",
                    "firstName": "Юлия",
                    "lastName": "Павлович",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=44322588&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=44322588&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300844100,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/flat/300844100/",
                    "title": "2-комн. кв., 44,4 м², 1/4 этаж",
                    "address": "Саратовская область, Красный Октябрь рп, улица Нефтяников, 38",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148052782-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148052782-1.jpg"
                    },
                    "price": "1 850 000 ₽",
                    "externalId": "63832575"
                }
            ],
            "objectId": 63832575
        },
        "78285": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93046615,
                    "offerId": 300844100,
                    "createdAt": "2024-09-06T22:28:15.639527+00:00",
                    "updatedAt": "2024-09-07T07:02:01.768955+00:00",
                    "messages": [
                        {
                            "messageId": "f73fa1ff31292cc36f9a79f66ffeaac4ee6b947f7d2e830f3e79add07dc77042",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "До встречи!",
                                "offers": []
                            },
                            "createdAt": "07.09.2024 09:02",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "07.09.2024 09:02"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300844100,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/flat/300844100/",
                    "title": "2-комн. кв., 44,4 м², 1/4 этаж",
                    "address": "Саратовская область, Красный Октябрь рп, улица Нефтяников, 38",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148052782-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148052782-1.jpg"
                    },
                    "price": "1 850 000 ₽",
                    "externalId": "63832575"
                }
            ],
            "objectId": 63832575
        },
        "78438": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93171686,
                    "offerId": 298211630,
                    "createdAt": "2024-09-08T06:17:02.361138+00:00",
                    "updatedAt": "2024-09-08T06:17:02.709053+00:00",
                    "messages": [
                        {
                            "messageId": "6b333c5e220680b94ebde974f1d660874f615fab5c48b74e1a708345eec94584",
                            "userId": 57555348,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Подскажите, пожалуйста, сегодня в 14-30 можно посмотреть квартиру?",
                                "offers": []
                            },
                            "createdAt": "08.09.2024 08:17",
                            "new": 0,
                            "userName": "Юлия Сидорова"
                        }
                    ],
                    "update_at": "08.09.2024 08:17"
                }
            ],
            "users": [
                {
                    "userId": 57555348,
                    "name": "Юлия Сидорова",
                    "firstName": "Юлия",
                    "lastName": "Сидорова",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=57555348&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=57555348&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 298211630,
                    "userId": 107946441,
                    "url": "https://cian.ru/sale/flat/298211630/",
                    "title": "1-комн. кв., 45 м², 1/15 этаж",
                    "address": "Саратовская область, Саратов, Лунная улица, 25Б",
                    "publishedUserId": 107946441,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2094955541-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2094955541-1.jpg"
                    },
                    "price": "4 470 000 ₽",
                    "externalId": "61132114"
                }
            ],
            "objectId": 61132114
        },
        "78451": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93171686,
                    "offerId": 298211630,
                    "createdAt": "2024-09-08T06:17:02.361138+00:00",
                    "updatedAt": "2024-09-08T07:21:57.803544+00:00",
                    "messages": [
                        {
                            "messageId": "3398abfffaac5a42283c8f126ffcf39a61c47c79e9440b51a70dae75c2c1a86e",
                            "userId": 107946441,
                            "direction": "out",
                            "content": {
                                "text": "Юлия, добрый день! Сегодня, к сожалению, нет возможности показать квартиру. Можем договориться на завтра на это время. Позвоните мне, пжл, не могу до вас дозвониться",
                                "offers": []
                            },
                            "createdAt": "08.09.2024 09:21",
                            "new": 0,
                            "userName": "Евгения Баракова"
                        }
                    ],
                    "update_at": "08.09.2024 09:21"
                }
            ],
            "users": [
                {
                    "userId": 107946441,
                    "name": "Евгения Баракова",
                    "firstName": "Евгения",
                    "lastName": "Баракова",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=107946441&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=107946441&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 298211630,
                    "userId": 107946441,
                    "url": "https://cian.ru/sale/flat/298211630/",
                    "title": "1-комн. кв., 45 м², 1/15 этаж",
                    "address": "Саратовская область, Саратов, Лунная улица, 25Б",
                    "publishedUserId": 107946441,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2094955541-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2094955541-1.jpg"
                    },
                    "price": "4 470 000 ₽",
                    "externalId": "61132114"
                }
            ],
            "objectId": 61132114
        },
        "78476": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93204153,
                    "offerId": 298864653,
                    "createdAt": "2024-09-08T11:19:02.245810+00:00",
                    "updatedAt": "2024-09-08T11:19:02.388388+00:00",
                    "messages": [
                        {
                            "messageId": "92ceb21a99b11e866a0e48cd90cb6d7db4484d57d4ab6a24c8df04426730ce6b",
                            "userId": 121469726,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Пожалуйста, свяжитесь со мной +79172067112",
                                "offers": []
                            },
                            "createdAt": "08.09.2024 13:19",
                            "new": 0,
                            "userName": "Марина"
                        }
                    ],
                    "update_at": "08.09.2024 13:19"
                }
            ],
            "users": [
                {
                    "userId": 121469726,
                    "name": "Марина",
                    "firstName": "Марина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=121469726&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=121469726&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 298864653,
                    "userId": 114681854,
                    "url": "https://cian.ru/sale/suburban/298864653/",
                    "title": "Дом, 99 м²",
                    "address": "Саратовская область, Энгельсский район, Красноярское муниципальное образование, с. Шумейка, улица Бережная, 58",
                    "publishedUserId": 114681854,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2106995175-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2106995175-1.jpg"
                    },
                    "price": "5 200 000 ₽",
                    "externalId": "61836066"
                }
            ],
            "objectId": 61836066
        },
        "78583": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93171686,
                    "offerId": 298211630,
                    "createdAt": "2024-09-08T06:17:02.361138+00:00",
                    "updatedAt": "2024-09-09T05:40:47.674784+00:00",
                    "messages": [
                        {
                            "messageId": "60b9ed0545e1ac8273c17e02ebafcd120960c09910b5110160f0f6968ce0a2bb",
                            "userId": 57555348,
                            "direction": "in",
                            "content": {
                                "text": "Пожалуйста, позвоните, когда будет удобно. Вот мой номер\n+79372627191",
                                "offers": []
                            },
                            "createdAt": "09.09.2024 07:40",
                            "new": 0,
                            "userName": "Юлия Сидорова"
                        }
                    ],
                    "update_at": "09.09.2024 07:40"
                }
            ],
            "users": [
                {
                    "userId": 57555348,
                    "name": "Юлия Сидорова",
                    "firstName": "Юлия",
                    "lastName": "Сидорова",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=57555348&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=57555348&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 298211630,
                    "userId": 107946441,
                    "url": "https://cian.ru/sale/flat/298211630/",
                    "title": "1-комн. кв., 45 м², 1/15 этаж",
                    "address": "Саратовская область, Саратов, Лунная улица, 25Б",
                    "publishedUserId": 107946441,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2094955541-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2094955541-1.jpg"
                    },
                    "price": "4 470 000 ₽",
                    "externalId": "61132114"
                }
            ],
            "objectId": 61132114
        },
        "78595": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 92757257,
                    "offerId": 306542405,
                    "createdAt": "2024-09-04T16:44:22.400432+00:00",
                    "updatedAt": "2024-09-09T06:49:47.876781+00:00",
                    "messages": [
                        {
                            "messageId": "b860342b54857da1f0a80d9aaafeaef98ed9bb66c35917af0e45174dbabe9a9d",
                            "userId": 120665117,
                            "direction": "in",
                            "content": {
                                "text": "Вы можете ответить?",
                                "offers": []
                            },
                            "createdAt": "09.09.2024 08:49",
                            "new": 0,
                            "userName": "Юрий"
                        }
                    ],
                    "update_at": "09.09.2024 08:49"
                }
            ],
            "users": [
                {
                    "userId": 120665117,
                    "name": "Юрий",
                    "firstName": "Юрий",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=120665117&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=120665117&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306542405,
                    "userId": 107424518,
                    "url": "https://cian.ru/sale/flat/306542405/",
                    "title": "2-комн. кв., 33 м², 9/9 этаж",
                    "address": "Саратовская область, Энгельс, 1-й мкр, 13А",
                    "publishedUserId": 107424518,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2253453175-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2253453175-1.jpg"
                    },
                    "price": "1 450 000 ₽",
                    "externalId": "70883702"
                }
            ],
            "objectId": 70883702
        },
        "78600": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93289730,
                    "offerId": 300860652,
                    "createdAt": "2024-09-09T07:25:33.484911+00:00",
                    "updatedAt": "2024-09-09T07:25:33.673596+00:00",
                    "messages": [
                        {
                            "messageId": "a4ba6a76e22c8f9fc5bdb25b4ca3512cffac08196331ab5d60b95dbb217f29ed",
                            "userId": 93637329,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте ещё продается?",
                                "offers": []
                            },
                            "createdAt": "09.09.2024 09:25",
                            "new": 0,
                            "userName": "Пользователь ID 93637329"
                        }
                    ],
                    "update_at": "09.09.2024 09:25"
                }
            ],
            "users": [
                {
                    "userId": 93637329,
                    "name": "93637329",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=93637329&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=93637329&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "78601": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93289730,
                    "offerId": 300860652,
                    "createdAt": "2024-09-09T07:25:33.484911+00:00",
                    "updatedAt": "2024-09-09T07:27:38.959525+00:00",
                    "messages": [
                        {
                            "messageId": "a6bc834a97917e04e0e5fbc1a5832e8b5640ce57a750dc8dc3e7952a058a4491",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте. Да",
                                "offers": []
                            },
                            "createdAt": "09.09.2024 09:27",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "09.09.2024 09:27"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "78602": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93289730,
                    "offerId": 300860652,
                    "createdAt": "2024-09-09T07:25:33.484911+00:00",
                    "updatedAt": "2024-09-09T07:27:52.353301+00:00",
                    "messages": [
                        {
                            "messageId": "9f6be987c2d29b63f65d6ffec1b91ded4af62793b4601c4cf410982afddb1e8c",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Телефон для связи 89271686925",
                                "offers": []
                            },
                            "createdAt": "09.09.2024 09:27",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "09.09.2024 09:27"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "78603": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 92757257,
                    "offerId": 306542405,
                    "createdAt": "2024-09-04T16:44:22.400432+00:00",
                    "updatedAt": "2024-09-09T07:28:11.042127+00:00",
                    "messages": [
                        {
                            "messageId": "1e53fc29783a8475405dada439c81a789c4ddb3110e013b6b30703f5fd23a23b",
                            "userId": 107424518,
                            "direction": "out",
                            "content": {
                                "text": "я с вами связалась уже",
                                "offers": []
                            },
                            "createdAt": "09.09.2024 09:28",
                            "new": 0,
                            "userName": "Анжелика Арсенева"
                        }
                    ],
                    "update_at": "09.09.2024 09:28"
                }
            ],
            "users": [
                {
                    "userId": 107424518,
                    "name": "Анжелика Арсенева",
                    "firstName": "Анжелика",
                    "lastName": "Арсенева",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=107424518&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=107424518&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306542405,
                    "userId": 107424518,
                    "url": "https://cian.ru/sale/flat/306542405/",
                    "title": "2-комн. кв., 33 м², 9/9 этаж",
                    "address": "Саратовская область, Энгельс, 1-й мкр, 13А",
                    "publishedUserId": 107424518,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2253453175-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2253453175-1.jpg"
                    },
                    "price": "1 450 000 ₽",
                    "externalId": "70883702"
                }
            ],
            "objectId": 70883702
        },
        "78776": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93036861,
                    "offerId": 301202675,
                    "createdAt": "2024-09-06T18:48:50.763221+00:00",
                    "updatedAt": "2024-09-09T15:16:51.189428+00:00",
                    "messages": [
                        {
                            "messageId": "f7eda3aad179028ced062f9ff40a5f2f237ea606a18214f8b0fba269dad1f512",
                            "userId": 121479329,
                            "direction": "in",
                            "content": {
                                "text": "Александр а что дача снята , мы же договорились что сегодня созвонимся?",
                                "offers": []
                            },
                            "createdAt": "09.09.2024 17:16",
                            "new": 0,
                            "userName": "Ольга"
                        }
                    ],
                    "update_at": "09.09.2024 17:16"
                }
            ],
            "users": [
                {
                    "userId": 121479329,
                    "name": "Ольга",
                    "firstName": "Ольга",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=121479329&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=121479329&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 301202675,
                    "userId": 115760105,
                    "url": "https://cian.ru/sale/suburban/301202675/",
                    "title": "Дом, 34 м²",
                    "address": "Саратовская область, Энгельсский район, Красноярское муниципальное образование, Черемушки СНТ",
                    "publishedUserId": 115760105,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2155282229-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2155282229-1.jpg"
                    },
                    "price": "550 000 ₽",
                    "externalId": "64382251"
                }
            ],
            "objectId": 64382251
        },
        "78885": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93418041,
                    "offerId": 306555307,
                    "createdAt": "2024-09-10T06:10:14.133080+00:00",
                    "updatedAt": "2024-09-10T06:10:14.480206+00:00",
                    "messages": [
                        {
                            "messageId": "0b7ec6449e0a60879779b397e3a61ab27c9fe336db36a84e3068f0b08050d92f",
                            "userId": 105460459,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Уточните, пожалуйста, ещё продаёте? И какой вариант обмена предполагается?",
                                "offers": []
                            },
                            "createdAt": "10.09.2024 08:10",
                            "new": 0,
                            "userName": "Анна Губская"
                        }
                    ],
                    "update_at": "10.09.2024 08:10"
                }
            ],
            "users": [
                {
                    "userId": 105460459,
                    "name": "Анна Губская",
                    "firstName": "Анна",
                    "lastName": "Губская",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=105460459&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=105460459&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306555307,
                    "userId": 107424518,
                    "url": "https://cian.ru/sale/suburban/306555307/",
                    "title": "Дом, 100 м²",
                    "address": "Саратовская область, Энгельс, улица Республики, 121",
                    "publishedUserId": 107424518,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2253640144-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2253640144-1.jpg"
                    },
                    "price": "7 950 000 ₽",
                    "externalId": "70889981"
                }
            ],
            "objectId": 70889981
        },
        "78973": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 92620086,
                    "offerId": 305858924,
                    "createdAt": "2024-09-03T17:40:42.019194+00:00",
                    "updatedAt": "2024-09-10T09:09:54.931132+00:00",
                    "messages": [
                        {
                            "messageId": "83b2b3d929a14441a3fa8537b40bbe27186bef67ae35b6bd1278b11aa33c26ce",
                            "userId": 118090029,
                            "direction": "in",
                            "content": {
                                "text": "Скажите,  а калитка которая ездит это , что кухня ? Я так понимаю , чтобы войти на кухню нужно калитку открыть и потом закрыть",
                                "offers": []
                            },
                            "createdAt": "10.09.2024 11:09",
                            "new": 0,
                            "userName": "Пользователь ID 118090029"
                        }
                    ],
                    "update_at": "10.09.2024 11:09"
                }
            ],
            "users": [
                {
                    "userId": 118090029,
                    "name": "118090029",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=118090029&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=118090029&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 305858924,
                    "userId": 120336895,
                    "url": "https://cian.ru/rent/flat/305858924/",
                    "title": "1-комн. кв., 43 м², 4/25 этаж",
                    "address": "Саратовская область, Саратов, улица Имени И.В. Мичурина, 18/68",
                    "publishedUserId": 120336895,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2241306470-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2241306470-1.jpg"
                    },
                    "price": "49 000 ₽/мес.",
                    "externalId": "69859129"
                }
            ],
            "objectId": 69859129
        },
        "78974": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 92620086,
                    "offerId": 305858924,
                    "createdAt": "2024-09-03T17:40:42.019194+00:00",
                    "updatedAt": "2024-09-10T09:09:55.550799+00:00",
                    "messages": [
                        {
                            "messageId": "65c53754e6c56fbe8853ad44a8493aca7ea40a4a6abb95f7962d16a0221eaecf",
                            "userId": 118090029,
                            "direction": "in",
                            "content": {
                                "text": "Скажите,  а калитка которая ездит это , что кухня ? Я так понимаю , чтобы войти на кухню нужно калитку открыть и потом закрыть",
                                "offers": []
                            },
                            "createdAt": "10.09.2024 11:09",
                            "new": 0,
                            "userName": "Пользователь ID 118090029"
                        }
                    ],
                    "update_at": "10.09.2024 11:09"
                }
            ],
            "users": [
                {
                    "userId": 118090029,
                    "name": "118090029",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=118090029&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=118090029&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 305858924,
                    "userId": 120336895,
                    "url": "https://cian.ru/rent/flat/305858924/",
                    "title": "1-комн. кв., 43 м², 4/25 этаж",
                    "address": "Саратовская область, Саратов, улица Имени И.В. Мичурина, 18/68",
                    "publishedUserId": 120336895,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2241306470-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2241306470-1.jpg"
                    },
                    "price": "49 000 ₽/мес.",
                    "externalId": "69859129"
                }
            ],
            "objectId": 69859129
        },
        "79287": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93584015,
                    "offerId": 305568704,
                    "createdAt": "2024-09-11T09:38:49.122905+00:00",
                    "updatedAt": "2024-09-11T09:38:49.421565+00:00",
                    "messages": [
                        {
                            "messageId": "800fabffcb9f77f3a56dba453d001acdae9867637ae985a433456bf7e43430b6",
                            "userId": 120746268,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте, сколько в доме отдельных спален? Есть ли фото планировки?",
                                "offers": []
                            },
                            "createdAt": "11.09.2024 11:38",
                            "new": 0,
                            "userName": "Анна"
                        }
                    ],
                    "update_at": "11.09.2024 11:38"
                }
            ],
            "users": [
                {
                    "userId": 120746268,
                    "name": "Анна",
                    "firstName": "Анна",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=120746268&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=120746268&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 305568704,
                    "userId": 115760105,
                    "url": "https://cian.ru/sale/suburban/305568704/",
                    "title": "Дом, 100 м²",
                    "address": "Саратовская область, Энгельсский район, Красноярское муниципальное образование, Вишневый Сад СНТ",
                    "publishedUserId": 115760105,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2252622878-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2252622878-1.jpg"
                    },
                    "price": "5 650 000 ₽",
                    "externalId": "69677526"
                }
            ],
            "objectId": 69677526
        },
        "79389": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93629259,
                    "offerId": 306437694,
                    "createdAt": "2024-09-11T14:39:40.998129+00:00",
                    "updatedAt": "2024-09-11T14:39:41.131311+00:00",
                    "messages": [
                        {
                            "messageId": "3cd22a331ca4410e20cd8c5c2f001c140b32287b89393b003e3d4f3076b8af55",
                            "userId": 74626364,
                            "direction": "in",
                            "content": {
                                "text": "Добрый день, когда можно посмотреть?",
                                "offers": []
                            },
                            "createdAt": "11.09.2024 16:39",
                            "new": 0,
                            "userName": "Пользователь ID 74626364"
                        }
                    ],
                    "update_at": "11.09.2024 16:39"
                }
            ],
            "users": [
                {
                    "userId": 74626364,
                    "name": "74626364",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=74626364&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=74626364&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306437694,
                    "userId": 114681854,
                    "url": "https://cian.ru/sale/suburban/306437694/",
                    "title": "Земельный участок, 10 сот.",
                    "address": "Саратовская область, Энгельсский район, Красноярское муниципальное образование, с. Шумейка, Буревестник мкр",
                    "publishedUserId": 114681854,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2251652942-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2251652942-1.jpg"
                    },
                    "price": "1 300 000 ₽",
                    "externalId": "70498104"
                }
            ],
            "objectId": 70498104
        },
        "79606": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93748440,
                    "offerId": 303473200,
                    "createdAt": "2024-09-12T13:32:03.635377+00:00",
                    "updatedAt": "2024-09-12T13:32:03.758659+00:00",
                    "messages": [
                        {
                            "messageId": "4a917300eec0ceb41e5bbdf2f5b5e08a204f3657513661e5cdf9917dbbd10115",
                            "userId": 107821216,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте,  подскажите,  собственник не желает сдать дом месяца на 2 пока дом продаётся?",
                                "offers": []
                            },
                            "createdAt": "12.09.2024 15:32",
                            "new": 0,
                            "userName": "Пользователь ID 107821216"
                        }
                    ],
                    "update_at": "12.09.2024 15:32"
                }
            ],
            "users": [
                {
                    "userId": 107821216,
                    "name": "107821216",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=107821216&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=107821216&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 303473200,
                    "userId": 114681854,
                    "url": "https://cian.ru/sale/suburban/303473200/",
                    "title": "Дом, 77,7 м²",
                    "address": "Саратовская область, Энгельсский район, с. Красный Яр, улица Мира, 13",
                    "publishedUserId": 114681854,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2194991621-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2194991621-1.jpg"
                    },
                    "price": "2 499 000 ₽",
                    "externalId": "67296369"
                }
            ],
            "objectId": 67296369
        },
        "79876": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93859201,
                    "offerId": 307300812,
                    "createdAt": "2024-09-13T10:56:07.026374+00:00",
                    "updatedAt": "2024-09-13T10:56:07.252659+00:00",
                    "messages": [
                        {
                            "messageId": "187585ef625418d86d1789b9e0a741c5ebaf55ef2cda77964de161663b875d8b",
                            "userId": 121084548,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте, понравилась эта квартира хотели бы снять моладая семейная пара 30,31  лет без детей без жывотных",
                                "offers": []
                            },
                            "createdAt": "13.09.2024 12:56",
                            "new": 0,
                            "userName": "Бабаджан  Маммедов"
                        }
                    ],
                    "update_at": "13.09.2024 12:56"
                }
            ],
            "users": [
                {
                    "userId": 121084548,
                    "name": "Бабаджан  Маммедов",
                    "firstName": "Бабаджан ",
                    "lastName": "Маммедов ",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=121084548&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=121084548&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307300812,
                    "userId": 120336895,
                    "url": "https://cian.ru/rent/flat/307300812/",
                    "title": "1-комн. кв., 38 м², 2/10 этаж",
                    "address": "Саратовская область, Саратов, проезд 2-й имени Блинова Ф.А., 8",
                    "publishedUserId": 120336895,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2267557894-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2267557894-1.jpg"
                    },
                    "price": "20 000 ₽/мес.",
                    "externalId": "71740637"
                }
            ],
            "objectId": 71740637
        },
        "79948": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93881495,
                    "offerId": 306305654,
                    "createdAt": "2024-09-13T13:33:49.350795+00:00",
                    "updatedAt": "2024-09-13T13:33:49.778972+00:00",
                    "messages": [
                        {
                            "messageId": "2c3799403d5d813c355dccd7bcb93679b4682bc494e8e7f8d389f6ed56d0626c",
                            "userId": 47454243,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Пожалуйста, свяжитесь со мной +79113264580",
                                "offers": []
                            },
                            "createdAt": "13.09.2024 15:33",
                            "new": 0,
                            "userName": "Пользователь ID 47454243"
                        }
                    ],
                    "update_at": "13.09.2024 15:33"
                }
            ],
            "users": [
                {
                    "userId": 47454243,
                    "name": "47454243",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=47454243&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=47454243&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306305654,
                    "userId": 115696557,
                    "url": "https://cian.ru/sale/flat/306305654/",
                    "title": "3-комн. кв., 60 м², 8/12 этаж",
                    "address": "Москва, улица Исаковского, 8К3",
                    "publishedUserId": 115696557,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2249960525-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2249960525-1.jpg"
                    },
                    "price": "16 500 000 ₽",
                    "externalId": "70600733"
                }
            ],
            "objectId": 70600733
        },
        "79949": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93881495,
                    "offerId": 306305654,
                    "createdAt": "2024-09-13T13:33:49.350795+00:00",
                    "updatedAt": "2024-09-13T13:34:58.993123+00:00",
                    "messages": [
                        {
                            "messageId": "a31723149d3ad63f70b73fbe91e41c2005ae5fd0fa4c507a5774bd3e365ecb96",
                            "userId": 47454243,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Подскажите, пожалуйста, когда можно посмотреть квартиру?",
                                "offers": []
                            },
                            "createdAt": "13.09.2024 15:34",
                            "new": 0,
                            "userName": "Пользователь ID 47454243"
                        }
                    ],
                    "update_at": "13.09.2024 15:34"
                }
            ],
            "users": [
                {
                    "userId": 47454243,
                    "name": "47454243",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=47454243&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=47454243&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306305654,
                    "userId": 115696557,
                    "url": "https://cian.ru/sale/flat/306305654/",
                    "title": "3-комн. кв., 60 м², 8/12 этаж",
                    "address": "Москва, улица Исаковского, 8К3",
                    "publishedUserId": 115696557,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2249960525-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2249960525-1.jpg"
                    },
                    "price": "16 500 000 ₽",
                    "externalId": "70600733"
                }
            ],
            "objectId": 70600733
        },
        "79950": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93748440,
                    "offerId": 303473200,
                    "createdAt": "2024-09-12T13:32:03.635377+00:00",
                    "updatedAt": "2024-09-13T13:38:43.170416+00:00",
                    "messages": [
                        {
                            "messageId": "a9872eb178fc5ecf76ef84aedcc49d9f2ac7595939015d5b6bf4f33d642834be",
                            "userId": 114681854,
                            "direction": "out",
                            "content": {
                                "text": "Добрый день. Там уже проживают квартиранты",
                                "offers": []
                            },
                            "createdAt": "13.09.2024 15:38",
                            "new": 0,
                            "userName": "Юлия Наличникова"
                        }
                    ],
                    "update_at": "13.09.2024 15:38"
                }
            ],
            "users": [
                {
                    "userId": 114681854,
                    "name": "Юлия Наличникова",
                    "firstName": "Юлия",
                    "lastName": "Наличникова",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=114681854&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=114681854&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 303473200,
                    "userId": 114681854,
                    "url": "https://cian.ru/sale/suburban/303473200/",
                    "title": "Дом, 77,7 м²",
                    "address": "Саратовская область, Энгельсский район, с. Красный Яр, улица Мира, 13",
                    "publishedUserId": 114681854,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2194991621-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2194991621-1.jpg"
                    },
                    "price": "2 499 000 ₽",
                    "externalId": "67296369"
                }
            ],
            "objectId": 67296369
        },
        "79951": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 93629259,
                    "offerId": 306437694,
                    "createdAt": "2024-09-11T14:39:40.998129+00:00",
                    "updatedAt": "2024-09-13T13:39:01.850632+00:00",
                    "messages": [
                        {
                            "messageId": "9f1204713964de925217a620a7311c0a1957a39730164d2708cf34a774b011e1",
                            "userId": 114681854,
                            "direction": "out",
                            "content": {
                                "text": "Добрый день. В любое время. Кадастровый номер указан в объявлении",
                                "offers": []
                            },
                            "createdAt": "13.09.2024 15:39",
                            "new": 0,
                            "userName": "Юлия Наличникова"
                        }
                    ],
                    "update_at": "13.09.2024 15:39"
                }
            ],
            "users": [
                {
                    "userId": 114681854,
                    "name": "Юлия Наличникова",
                    "firstName": "Юлия",
                    "lastName": "Наличникова",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=114681854&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=114681854&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306437694,
                    "userId": 114681854,
                    "url": "https://cian.ru/sale/suburban/306437694/",
                    "title": "Земельный участок, 10 сот.",
                    "address": "Саратовская область, Энгельсский район, Красноярское муниципальное образование, с. Шумейка, Буревестник мкр",
                    "publishedUserId": 114681854,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2251652942-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2251652942-1.jpg"
                    },
                    "price": "1 300 000 ₽",
                    "externalId": "70498104"
                }
            ],
            "objectId": 70498104
        },
        "80403": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94071324,
                    "offerId": 300860652,
                    "createdAt": "2024-09-15T09:00:21.972400+00:00",
                    "updatedAt": "2024-09-15T09:00:22.308710+00:00",
                    "messages": [
                        {
                            "messageId": "7e41a8066c9c6dda0f0334b1c95108f216bb4eb79e323ae3038ad29f3381f4a3",
                            "userId": 120665743,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте это дом от города далеко",
                                "offers": []
                            },
                            "createdAt": "15.09.2024 11:00",
                            "new": 0,
                            "userName": "Пользователь ID 120665743"
                        }
                    ],
                    "update_at": "15.09.2024 11:00"
                }
            ],
            "users": [
                {
                    "userId": 120665743,
                    "name": "120665743",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=120665743&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=120665743&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "80404": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94071324,
                    "offerId": 300860652,
                    "createdAt": "2024-09-15T09:00:21.972400+00:00",
                    "updatedAt": "2024-09-15T09:01:19.519586+00:00",
                    "messages": [
                        {
                            "messageId": "a5fbf33b33a8b29979ae2ba124da6f633053ff1d3781b090a22f8367fc484d4c",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте. От какого именно?",
                                "offers": []
                            },
                            "createdAt": "15.09.2024 11:01",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "15.09.2024 11:01"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "80405": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94071324,
                    "offerId": 300860652,
                    "createdAt": "2024-09-15T09:00:21.972400+00:00",
                    "updatedAt": "2024-09-15T09:03:42.410257+00:00",
                    "messages": [
                        {
                            "messageId": "e76504c5f0c837cda5b4491c14dfb65283f30a447872acbfd6868df816354554",
                            "userId": 120665743,
                            "direction": "in",
                            "content": {
                                "text": "Это же деревня правильно от город Тамбов да сколько километров примерно",
                                "offers": []
                            },
                            "createdAt": "15.09.2024 11:03",
                            "new": 0,
                            "userName": "Пользователь ID 120665743"
                        }
                    ],
                    "update_at": "15.09.2024 11:03"
                }
            ],
            "users": [
                {
                    "userId": 120665743,
                    "name": "120665743",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=120665743&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=120665743&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "80406": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94071324,
                    "offerId": 300860652,
                    "createdAt": "2024-09-15T09:00:21.972400+00:00",
                    "updatedAt": "2024-09-15T09:03:53.985339+00:00",
                    "messages": [
                        {
                            "messageId": "0dc0ed5438a772e9a00674848173aed3d7ec6a61e81fced083bb8f114359670e",
                            "userId": 120665743,
                            "direction": "in",
                            "content": {
                                "text": "Пожалуйста, позвоните, когда будет удобно. Вот мой номер\n+79001659680",
                                "offers": []
                            },
                            "createdAt": "15.09.2024 11:03",
                            "new": 0,
                            "userName": "Пользователь ID 120665743"
                        }
                    ],
                    "update_at": "15.09.2024 11:03"
                }
            ],
            "users": [
                {
                    "userId": 120665743,
                    "name": "120665743",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=120665743&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=120665743&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "80407": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94071324,
                    "offerId": 300860652,
                    "createdAt": "2024-09-15T09:00:21.972400+00:00",
                    "updatedAt": "2024-09-15T09:07:07.868464+00:00",
                    "messages": [
                        {
                            "messageId": "6df000096d4e58fae7423c5cf633a957d3acc45bff2100c67bb3824ebfaa5acb",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Это деревня Тамбовка, между Самарой и Саратовом",
                                "offers": []
                            },
                            "createdAt": "15.09.2024 11:07",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "15.09.2024 11:07"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "80411": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94071324,
                    "offerId": 300860652,
                    "createdAt": "2024-09-15T09:00:21.972400+00:00",
                    "updatedAt": "2024-09-15T09:15:00.217812+00:00",
                    "messages": [
                        {
                            "messageId": "0ff0eabef586f4f916ee32f3136f9d982bca37d250a088d41d8c2f662f94555c",
                            "userId": 120665743,
                            "direction": "in",
                            "content": {
                                "text": "Понятно на деревне количество дома много",
                                "offers": []
                            },
                            "createdAt": "15.09.2024 11:15",
                            "new": 0,
                            "userName": "Пользователь ID 120665743"
                        }
                    ],
                    "update_at": "15.09.2024 11:15"
                }
            ],
            "users": [
                {
                    "userId": 120665743,
                    "name": "120665743",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=120665743&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=120665743&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "80652": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94157435,
                    "offerId": 306305654,
                    "createdAt": "2024-09-16T05:48:55.225727+00:00",
                    "updatedAt": "2024-09-16T05:48:55.320094+00:00",
                    "messages": [
                        {
                            "messageId": "71eaf19f11a8800e16aef07c1dbc78da6e5d72bc414b959afdea0809391a4117",
                            "userId": 121829278,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте, хочу получить информацию о квартире.",
                                "offers": []
                            },
                            "createdAt": "16.09.2024 07:48",
                            "new": 0,
                            "userName": "Пользователь ID 121829278"
                        }
                    ],
                    "update_at": "16.09.2024 07:48"
                }
            ],
            "users": [
                {
                    "userId": 121829278,
                    "name": "121829278",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=121829278&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=121829278&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306305654,
                    "userId": 115696557,
                    "url": "https://cian.ru/sale/flat/306305654/",
                    "title": "3-комн. кв., 60 м², 8/12 этаж",
                    "address": "Москва, улица Исаковского, 8К3",
                    "publishedUserId": 115696557,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2249960525-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2249960525-1.jpg"
                    },
                    "price": "16 500 000 ₽",
                    "externalId": "70600733"
                }
            ],
            "objectId": 70600733
        },
        "80653": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94157435,
                    "offerId": 306305654,
                    "createdAt": "2024-09-16T05:48:55.225727+00:00",
                    "updatedAt": "2024-09-16T05:49:00.858585+00:00",
                    "messages": [
                        {
                            "messageId": "3d0849d23db7ad5bad1c7a4236beb10f700a3e7eaca2accf29b5cbdef6f1fa82",
                            "userId": 121829278,
                            "direction": "in",
                            "content": {
                                "files": [
                                    {
                                        "fileId": "f2b77973-b047-4edb-ba07-5c4bd3f9ca19",
                                        "name": "offer_121829278_306305654_f2b77973-b047-4edb-ba07-5c4bd3f9ca19.jpeg",
                                        "mimeType": "image/jpeg"
                                    }
                                ],
                                "offers": []
                            },
                            "createdAt": "16.09.2024 07:49",
                            "new": 0,
                            "userName": "Пользователь ID 121829278"
                        }
                    ],
                    "update_at": "16.09.2024 07:49"
                }
            ],
            "users": [
                {
                    "userId": 121829278,
                    "name": "121829278",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=121829278&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=121829278&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306305654,
                    "userId": 115696557,
                    "url": "https://cian.ru/sale/flat/306305654/",
                    "title": "3-комн. кв., 60 м², 8/12 этаж",
                    "address": "Москва, улица Исаковского, 8К3",
                    "publishedUserId": 115696557,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2249960525-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2249960525-1.jpg"
                    },
                    "price": "16 500 000 ₽",
                    "externalId": "70600733"
                }
            ],
            "objectId": 70600733
        },
        "81340": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94354566,
                    "offerId": 306305654,
                    "createdAt": "2024-09-17T14:03:53.823648+00:00",
                    "updatedAt": "2024-09-17T14:03:53.912750+00:00",
                    "messages": [
                        {
                            "messageId": "11b9e1a969f011389841bdf6dc87761a797ef11939c517dd8d8d8886faf570db",
                            "userId": 106549532,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Подскажите, пожалуйста, когда можно посмотреть квартиру? Моц номер +7925-359-90-45",
                                "offers": []
                            },
                            "createdAt": "17.09.2024 16:03",
                            "new": 0,
                            "userName": "Пользователь ID 106549532"
                        }
                    ],
                    "update_at": "17.09.2024 16:03"
                }
            ],
            "users": [
                {
                    "userId": 106549532,
                    "name": "106549532",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=106549532&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=106549532&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306305654,
                    "userId": 115696557,
                    "url": "https://cian.ru/sale/flat/306305654/",
                    "title": "3-комн. кв., 60 м², 8/12 этаж",
                    "address": "Москва, улица Исаковского, 8К3",
                    "publishedUserId": 115696557,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2249960525-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2249960525-1.jpg"
                    },
                    "price": "16 500 000 ₽",
                    "externalId": "70600733"
                }
            ],
            "objectId": 70600733
        },
        "81473": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94354566,
                    "offerId": 306305654,
                    "createdAt": "2024-09-17T14:03:53.823648+00:00",
                    "updatedAt": "2024-09-18T06:46:22.811315+00:00",
                    "messages": [
                        {
                            "messageId": "675073c91fd4bec725faa68f7988f16cf1b763d4b146e4b6ee258e20898e9fae",
                            "userId": 115696557,
                            "direction": "out",
                            "content": {
                                "text": "Добрый день , перезвоню вам через 30 мин",
                                "offers": []
                            },
                            "createdAt": "18.09.2024 08:46",
                            "new": 0,
                            "userName": "Ольга Спиркина"
                        }
                    ],
                    "update_at": "18.09.2024 08:46"
                }
            ],
            "users": [
                {
                    "userId": 115696557,
                    "name": "Ольга Спиркина",
                    "firstName": "Ольга",
                    "lastName": "Спиркина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115696557&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115696557&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306305654,
                    "userId": 115696557,
                    "url": "https://cian.ru/sale/flat/306305654/",
                    "title": "3-комн. кв., 60 м², 8/12 этаж",
                    "address": "Москва, улица Исаковского, 8К3",
                    "publishedUserId": 115696557,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2249960525-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2249960525-1.jpg"
                    },
                    "price": "16 500 000 ₽",
                    "externalId": "70600733"
                }
            ],
            "objectId": 70600733
        },
        "81509": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94431463,
                    "offerId": 300974779,
                    "createdAt": "2024-09-18T08:11:39.358058+00:00",
                    "updatedAt": "2024-09-18T08:11:39.503293+00:00",
                    "messages": [
                        {
                            "messageId": "d2fa4f4111c83d25ef071477bc3194ec0e676940ad2d4df5638577394e8c65e3",
                            "userId": 45252043,
                            "direction": "in",
                            "content": {
                                "text": "Добрый день, пришлите, пожалуйста, план квартиры",
                                "offers": []
                            },
                            "createdAt": "18.09.2024 10:11",
                            "new": 0,
                            "userName": "Юлия Александровна"
                        }
                    ],
                    "update_at": "18.09.2024 10:11"
                }
            ],
            "users": [
                {
                    "userId": 45252043,
                    "name": "Юлия Александровна",
                    "firstName": "Юлия",
                    "lastName": "Александровна",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=45252043&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=45252043&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300974779,
                    "userId": 115696557,
                    "url": "https://cian.ru/sale/flat/300974779/",
                    "title": "2-комн. кв., 84 м², 8/10 этаж",
                    "address": "Саратовская область, Саратов, улица Имени В.Г. Рахова, 61/71",
                    "publishedUserId": 115696557,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2150422736-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2150422736-1.jpg"
                    },
                    "price": "8 100 000 ₽",
                    "externalId": "63813590"
                }
            ],
            "objectId": 63813590
        },
        "81544": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94431463,
                    "offerId": 300974779,
                    "createdAt": "2024-09-18T08:11:39.358058+00:00",
                    "updatedAt": "2024-09-18T09:39:14.803195+00:00",
                    "messages": [
                        {
                            "messageId": "b3517f98231e608c90725c6424495005801b7e6ca9fecc09d507bad792fb2ab7",
                            "userId": 115696557,
                            "direction": "out",
                            "content": {
                                "text": "Добрый!\nНапишите куда вам прислать ваш номер скрыт Авито!!!",
                                "offers": []
                            },
                            "createdAt": "18.09.2024 11:39",
                            "new": 0,
                            "userName": "Ольга Спиркина"
                        }
                    ],
                    "update_at": "18.09.2024 11:39"
                }
            ],
            "users": [
                {
                    "userId": 115696557,
                    "name": "Ольга Спиркина",
                    "firstName": "Ольга",
                    "lastName": "Спиркина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115696557&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115696557&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300974779,
                    "userId": 115696557,
                    "url": "https://cian.ru/sale/flat/300974779/",
                    "title": "2-комн. кв., 84 м², 8/10 этаж",
                    "address": "Саратовская область, Саратов, улица Имени В.Г. Рахова, 61/71",
                    "publishedUserId": 115696557,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2150422736-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2150422736-1.jpg"
                    },
                    "price": "8 100 000 ₽",
                    "externalId": "63813590"
                }
            ],
            "objectId": 63813590
        },
        "81547": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94445147,
                    "offerId": 306305654,
                    "createdAt": "2024-09-18T09:51:33.652510+00:00",
                    "updatedAt": "2024-09-18T09:51:33.968695+00:00",
                    "messages": [
                        {
                            "messageId": "b04a2f92ea32ccfd49a769d5860c6c26e31951499cd24144f6a61b32394d3791",
                            "userId": 97346125,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте, заинтересовало ваше предложение. Подскажите пожалуйста, можно ли посмотреть квартиру на следующей неделе  и в какие дни?",
                                "offers": []
                            },
                            "createdAt": "18.09.2024 11:51",
                            "new": 0,
                            "userName": "Пользователь ID 97346125"
                        }
                    ],
                    "update_at": "18.09.2024 11:51"
                }
            ],
            "users": [
                {
                    "userId": 97346125,
                    "name": "97346125",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=97346125&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=97346125&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306305654,
                    "userId": 115696557,
                    "url": "https://cian.ru/sale/flat/306305654/",
                    "title": "3-комн. кв., 60 м², 8/12 этаж",
                    "address": "Москва, улица Исаковского, 8К3",
                    "publishedUserId": 115696557,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2249960525-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2249960525-1.jpg"
                    },
                    "price": "16 500 000 ₽",
                    "externalId": "70600733"
                }
            ],
            "objectId": 70600733
        },
        "81600": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94431463,
                    "offerId": 300974779,
                    "createdAt": "2024-09-18T08:11:39.358058+00:00",
                    "updatedAt": "2024-09-18T12:48:37.746269+00:00",
                    "messages": [
                        {
                            "messageId": "1aabbfd4e0590a454d34c2ca24ba1607a10fde6f43222e3f21dca58704a11e92",
                            "userId": 45252043,
                            "direction": "in",
                            "content": {
                                "text": "89379693565",
                                "offers": []
                            },
                            "createdAt": "18.09.2024 14:48",
                            "new": 0,
                            "userName": "Юлия Александровна"
                        }
                    ],
                    "update_at": "18.09.2024 14:48"
                }
            ],
            "users": [
                {
                    "userId": 45252043,
                    "name": "Юлия Александровна",
                    "firstName": "Юлия",
                    "lastName": "Александровна",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=45252043&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=45252043&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300974779,
                    "userId": 115696557,
                    "url": "https://cian.ru/sale/flat/300974779/",
                    "title": "2-комн. кв., 84 м², 8/10 этаж",
                    "address": "Саратовская область, Саратов, улица Имени В.Г. Рахова, 61/71",
                    "publishedUserId": 115696557,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2150422736-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2150422736-1.jpg"
                    },
                    "price": "8 100 000 ₽",
                    "externalId": "63813590"
                }
            ],
            "objectId": 63813590
        },
        "81766": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94431463,
                    "offerId": 300974779,
                    "createdAt": "2024-09-18T08:11:39.358058+00:00",
                    "updatedAt": "2024-09-19T06:07:34.689406+00:00",
                    "messages": [
                        {
                            "messageId": "22ceaf9ae5f50329f3deaa3a37414d0a772eba8086d980e698b98bd267b83719",
                            "userId": 45252043,
                            "direction": "in",
                            "content": {
                                "text": "Ольга, добрый день",
                                "offers": []
                            },
                            "createdAt": "19.09.2024 08:07",
                            "new": 0,
                            "userName": "Юлия Александровна"
                        }
                    ],
                    "update_at": "19.09.2024 08:07"
                }
            ],
            "users": [
                {
                    "userId": 45252043,
                    "name": "Юлия Александровна",
                    "firstName": "Юлия",
                    "lastName": "Александровна",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=45252043&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=45252043&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300974779,
                    "userId": 115696557,
                    "url": "https://cian.ru/sale/flat/300974779/",
                    "title": "2-комн. кв., 84 м², 8/10 этаж",
                    "address": "Саратовская область, Саратов, улица Имени В.Г. Рахова, 61/71",
                    "publishedUserId": 115696557,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2150422736-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2150422736-1.jpg"
                    },
                    "price": "8 100 000 ₽",
                    "externalId": "63813590"
                }
            ],
            "objectId": 63813590
        },
        "81767": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94431463,
                    "offerId": 300974779,
                    "createdAt": "2024-09-18T08:11:39.358058+00:00",
                    "updatedAt": "2024-09-19T06:07:53.738966+00:00",
                    "messages": [
                        {
                            "messageId": "1d31c9300287c62428075526bc18d5baf3512a39625fc7ffd2fce76b42922397",
                            "userId": 45252043,
                            "direction": "in",
                            "content": {
                                "text": "Напоминаю о  себе, жду план квартиры",
                                "offers": []
                            },
                            "createdAt": "19.09.2024 08:07",
                            "new": 0,
                            "userName": "Юлия Александровна"
                        }
                    ],
                    "update_at": "19.09.2024 08:07"
                }
            ],
            "users": [
                {
                    "userId": 45252043,
                    "name": "Юлия Александровна",
                    "firstName": "Юлия",
                    "lastName": "Александровна",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=45252043&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=45252043&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300974779,
                    "userId": 115696557,
                    "url": "https://cian.ru/sale/flat/300974779/",
                    "title": "2-комн. кв., 84 м², 8/10 этаж",
                    "address": "Саратовская область, Саратов, улица Имени В.Г. Рахова, 61/71",
                    "publishedUserId": 115696557,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2150422736-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2150422736-1.jpg"
                    },
                    "price": "8 100 000 ₽",
                    "externalId": "63813590"
                }
            ],
            "objectId": 63813590
        },
        "81846": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94590151,
                    "offerId": 303871676,
                    "createdAt": "2024-09-19T11:10:01.721272+00:00",
                    "updatedAt": "2024-09-19T11:10:02.080090+00:00",
                    "messages": [
                        {
                            "messageId": "80debe87d742b786211294f2251cdbc3d49187a2b6b1356532663c69ec1b6a50",
                            "userId": 117821162,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте! Вы не против небольшого торга? Предлагаю цену 1 820 000? Вы готовы сделать скидку?",
                                "offers": []
                            },
                            "createdAt": "19.09.2024 13:10",
                            "new": 0,
                            "userName": "Пользователь ID 117821162"
                        }
                    ],
                    "update_at": "19.09.2024 13:10"
                }
            ],
            "users": [
                {
                    "userId": 117821162,
                    "name": "117821162",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=117821162&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=117821162&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 303871676,
                    "userId": 114681854,
                    "url": "https://cian.ru/sale/flat/303871676/",
                    "title": "2-комн. кв., 47,6 м², 1/4 этаж",
                    "address": "Саратовская область, Энгельс муниципальное образование, Приволжский рп, улица Пархоменко, 56",
                    "publishedUserId": 114681854,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2203900231-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2203900231-1.jpg"
                    },
                    "price": "1 900 000 ₽",
                    "externalId": "62837761"
                }
            ],
            "objectId": 62837761
        },
        "81965": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94646519,
                    "offerId": 306305654,
                    "createdAt": "2024-09-19T18:02:04.878904+00:00",
                    "updatedAt": "2024-09-19T18:02:05.148901+00:00",
                    "messages": [
                        {
                            "messageId": "00734e2d529735f64d228c5593e765fed7481089d2187899a1c524f2ed8a895d",
                            "userId": 116049241,
                            "direction": "in",
                            "content": {
                                "text": "Ольга добрый вечер! Меня зовут Ярослав, наберите меня пожалуйста",
                                "offers": []
                            },
                            "createdAt": "19.09.2024 20:02",
                            "new": 0,
                            "userName": "Пользователь ID 116049241"
                        }
                    ],
                    "update_at": "19.09.2024 20:02"
                }
            ],
            "users": [
                {
                    "userId": 116049241,
                    "name": "116049241",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=116049241&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=116049241&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306305654,
                    "userId": 115696557,
                    "url": "https://cian.ru/sale/flat/306305654/",
                    "title": "3-комн. кв., 60 м², 8/12 этаж",
                    "address": "Москва, улица Исаковского, 8К3",
                    "publishedUserId": 115696557,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2249960525-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2249960525-1.jpg"
                    },
                    "price": "16 500 000 ₽",
                    "externalId": "70600733"
                }
            ],
            "objectId": 70600733
        },
        "82012": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94646519,
                    "offerId": 306305654,
                    "createdAt": "2024-09-19T18:02:04.878904+00:00",
                    "updatedAt": "2024-09-20T05:30:34.767334+00:00",
                    "messages": [
                        {
                            "messageId": "f385b5cae9918ba1b8bc2890a10371d30229b544e832966f7e6115ba7220089a",
                            "userId": 115696557,
                            "direction": "out",
                            "content": {
                                "text": "Доброе утро! Пришлите пожалуйста ваш номер телефона. С уважением!",
                                "offers": []
                            },
                            "createdAt": "20.09.2024 07:30",
                            "new": 0,
                            "userName": "Ольга Спиркина"
                        }
                    ],
                    "update_at": "20.09.2024 07:30"
                }
            ],
            "users": [
                {
                    "userId": 115696557,
                    "name": "Ольга Спиркина",
                    "firstName": "Ольга",
                    "lastName": "Спиркина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115696557&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115696557&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306305654,
                    "userId": 115696557,
                    "url": "https://cian.ru/sale/flat/306305654/",
                    "title": "3-комн. кв., 60 м², 8/12 этаж",
                    "address": "Москва, улица Исаковского, 8К3",
                    "publishedUserId": 115696557,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2249960525-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2249960525-1.jpg"
                    },
                    "price": "16 500 000 ₽",
                    "externalId": "70600733"
                }
            ],
            "objectId": 70600733
        },
        "82013": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94646519,
                    "offerId": 306305654,
                    "createdAt": "2024-09-19T18:02:04.878904+00:00",
                    "updatedAt": "2024-09-20T05:31:51.344215+00:00",
                    "messages": [
                        {
                            "messageId": "92aeaa206c2bcfab24a8c115c5bb207d95e8c14a9e51389ef4f343b7fd8ee81b",
                            "userId": 116049241,
                            "direction": "in",
                            "content": {
                                "text": "+79852216317",
                                "offers": []
                            },
                            "createdAt": "20.09.2024 07:31",
                            "new": 0,
                            "userName": "Пользователь ID 116049241"
                        }
                    ],
                    "update_at": "20.09.2024 07:31"
                }
            ],
            "users": [
                {
                    "userId": 116049241,
                    "name": "116049241",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=116049241&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=116049241&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306305654,
                    "userId": 115696557,
                    "url": "https://cian.ru/sale/flat/306305654/",
                    "title": "3-комн. кв., 60 м², 8/12 этаж",
                    "address": "Москва, улица Исаковского, 8К3",
                    "publishedUserId": 115696557,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2249960525-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2249960525-1.jpg"
                    },
                    "price": "16 500 000 ₽",
                    "externalId": "70600733"
                }
            ],
            "objectId": 70600733
        },
        "82134": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94741690,
                    "offerId": 306704393,
                    "createdAt": "2024-09-20T13:47:11.680439+00:00",
                    "updatedAt": "2024-09-20T13:47:11.883052+00:00",
                    "messages": [
                        {
                            "messageId": "7f30259c8d45986cb3783d1281772e6c38b464cbf5b4d73ea4e7a973f5f3219e",
                            "userId": 99683215,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Уточните, пожалуйста, ещё сдаёте?",
                                "offers": []
                            },
                            "createdAt": "20.09.2024 15:47",
                            "new": 0,
                            "userName": "Анастасия"
                        }
                    ],
                    "update_at": "20.09.2024 15:47"
                }
            ],
            "users": [
                {
                    "userId": 99683215,
                    "name": "Анастасия",
                    "firstName": "Анастасия ",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=99683215&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=99683215&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306704393,
                    "userId": 114681854,
                    "url": "https://cian.ru/rent/flat/306704393/",
                    "title": "1-комн. кв., 37,9 м², 2/10 этаж",
                    "address": "Саратовская область, Энгельс, улица Шурова Гора, 7/7",
                    "publishedUserId": 114681854,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2256119804-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2256119804-1.jpg"
                    },
                    "price": "20 000 ₽/мес.",
                    "externalId": "71046454"
                }
            ],
            "objectId": 71046454
        },
        "82204": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94774060,
                    "offerId": 306704393,
                    "createdAt": "2024-09-20T18:45:45.136754+00:00",
                    "updatedAt": "2024-09-20T18:45:45.323731+00:00",
                    "messages": [
                        {
                            "messageId": "fa52578d630ba3babf3b5c4a4422fed24663f1da0828332d2537a30f170d1a96",
                            "userId": 121585710,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Уточните, пожалуйста, ещё сдаёте?",
                                "offers": []
                            },
                            "createdAt": "20.09.2024 20:45",
                            "new": 0,
                            "userName": "Руслан"
                        }
                    ],
                    "update_at": "20.09.2024 20:45"
                }
            ],
            "users": [
                {
                    "userId": 121585710,
                    "name": "Руслан",
                    "firstName": "Руслан",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=121585710&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=121585710&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306704393,
                    "userId": 114681854,
                    "url": "https://cian.ru/rent/flat/306704393/",
                    "title": "1-комн. кв., 37,9 м², 2/10 этаж",
                    "address": "Саратовская область, Энгельс, улица Шурова Гора, 7/7",
                    "publishedUserId": 114681854,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2256119804-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2256119804-1.jpg"
                    },
                    "price": "20 000 ₽/мес.",
                    "externalId": "71046454"
                }
            ],
            "objectId": 71046454
        },
        "82205": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94774060,
                    "offerId": 306704393,
                    "createdAt": "2024-09-20T18:45:45.136754+00:00",
                    "updatedAt": "2024-09-20T18:46:21.140718+00:00",
                    "messages": [
                        {
                            "messageId": "a018032d9d2ea53b2f28562fa18ff9a3392c812207d89219cdb50b57bc10b3a3",
                            "userId": 121585710,
                            "direction": "in",
                            "content": {
                                "text": "Можно ли платить залог в рассрочку?",
                                "offers": []
                            },
                            "createdAt": "20.09.2024 20:46",
                            "new": 0,
                            "userName": "Руслан"
                        }
                    ],
                    "update_at": "20.09.2024 20:46"
                }
            ],
            "users": [
                {
                    "userId": 121585710,
                    "name": "Руслан",
                    "firstName": "Руслан",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=121585710&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=121585710&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306704393,
                    "userId": 114681854,
                    "url": "https://cian.ru/rent/flat/306704393/",
                    "title": "1-комн. кв., 37,9 м², 2/10 этаж",
                    "address": "Саратовская область, Энгельс, улица Шурова Гора, 7/7",
                    "publishedUserId": 114681854,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2256119804-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2256119804-1.jpg"
                    },
                    "price": "20 000 ₽/мес.",
                    "externalId": "71046454"
                }
            ],
            "objectId": 71046454
        },
        "82486": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94909233,
                    "offerId": 306882986,
                    "createdAt": "2024-09-22T07:03:57.102598+00:00",
                    "updatedAt": "2024-09-22T07:03:57.225624+00:00",
                    "messages": [
                        {
                            "messageId": "f60b4ca706520a776fa0f5eb5ef7cedfa732ffa6ddd91a8ea468b7f5abe81c6b",
                            "userId": 104145407,
                            "direction": "in",
                            "content": {
                                "text": "Добрый день, когда можно посмотреть квартиру?",
                                "offers": []
                            },
                            "createdAt": "22.09.2024 09:03",
                            "new": 0,
                            "userName": "Илья  Морозов"
                        }
                    ],
                    "update_at": "22.09.2024 09:03"
                }
            ],
            "users": [
                {
                    "userId": 104145407,
                    "name": "Илья  Морозов",
                    "firstName": "Илья ",
                    "lastName": "Морозов ",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=104145407&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=104145407&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306882986,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/flat/306882986/",
                    "title": "1-комн. кв., 36,9 м², 1/5 этаж",
                    "address": "Саратовская область, Красноармейск, 1-й мкр, 5",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2259469216-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2259469216-1.jpg"
                    },
                    "price": "1 050 000 ₽",
                    "externalId": "71229158"
                }
            ],
            "objectId": 71229158
        },
        "82487": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94909233,
                    "offerId": 306882986,
                    "createdAt": "2024-09-22T07:03:57.102598+00:00",
                    "updatedAt": "2024-09-22T07:05:22.439710+00:00",
                    "messages": [
                        {
                            "messageId": "b1922b95f6771e7483e69cc00e1f219cda70f8090cc986d53792e7f8684796ad",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте. Сегодня удобно?",
                                "offers": []
                            },
                            "createdAt": "22.09.2024 09:05",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "22.09.2024 09:05"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306882986,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/flat/306882986/",
                    "title": "1-комн. кв., 36,9 м², 1/5 этаж",
                    "address": "Саратовская область, Красноармейск, 1-й мкр, 5",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2259469216-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2259469216-1.jpg"
                    },
                    "price": "1 050 000 ₽",
                    "externalId": "71229158"
                }
            ],
            "objectId": 71229158
        },
        "82488": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94909233,
                    "offerId": 306882986,
                    "createdAt": "2024-09-22T07:03:57.102598+00:00",
                    "updatedAt": "2024-09-22T07:05:36.560130+00:00",
                    "messages": [
                        {
                            "messageId": "65b7a72c28ed391fbda7074f08eac6b52ec5e7f7dab41e9146894414ff539211",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Номер для связи 89271686925, звоните, договоримся",
                                "offers": []
                            },
                            "createdAt": "22.09.2024 09:05",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "22.09.2024 09:05"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306882986,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/flat/306882986/",
                    "title": "1-комн. кв., 36,9 м², 1/5 этаж",
                    "address": "Саратовская область, Красноармейск, 1-й мкр, 5",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2259469216-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2259469216-1.jpg"
                    },
                    "price": "1 050 000 ₽",
                    "externalId": "71229158"
                }
            ],
            "objectId": 71229158
        },
        "82501": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94774060,
                    "offerId": 306704393,
                    "createdAt": "2024-09-20T18:45:45.136754+00:00",
                    "updatedAt": "2024-09-22T08:12:51.531702+00:00",
                    "messages": [
                        {
                            "messageId": "cbb60079c7170fa2b4aa9a86475e40e65bb58a63def936bd9f321adc098d73b6",
                            "userId": 114681854,
                            "direction": "out",
                            "content": {
                                "text": "нет",
                                "offers": []
                            },
                            "createdAt": "22.09.2024 10:12",
                            "new": 0,
                            "userName": "Юлия Наличникова"
                        }
                    ],
                    "update_at": "22.09.2024 10:12"
                }
            ],
            "users": [
                {
                    "userId": 114681854,
                    "name": "Юлия Наличникова",
                    "firstName": "Юлия",
                    "lastName": "Наличникова",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=114681854&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=114681854&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306704393,
                    "userId": 114681854,
                    "url": "https://cian.ru/rent/flat/306704393/",
                    "title": "1-комн. кв., 37,9 м², 2/10 этаж",
                    "address": "Саратовская область, Энгельс, улица Шурова Гора, 7/7",
                    "publishedUserId": 114681854,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2256119804-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2256119804-1.jpg"
                    },
                    "price": "20 000 ₽/мес.",
                    "externalId": "71046454"
                }
            ],
            "objectId": 71046454
        },
        "82566": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94946645,
                    "offerId": 307215756,
                    "createdAt": "2024-09-22T12:44:45.213359+00:00",
                    "updatedAt": "2024-09-22T12:44:45.543349+00:00",
                    "messages": [
                        {
                            "messageId": "c94d4f01a4ced8e490fcfc9bd0134d47667bf32d54543be2959e92691d5a1905",
                            "userId": 60232299,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Запрет на рег.действия в связи с чем?",
                                "offers": []
                            },
                            "createdAt": "22.09.2024 14:44",
                            "new": 0,
                            "userName": "Пользователь ID 60232299"
                        }
                    ],
                    "update_at": "22.09.2024 14:44"
                }
            ],
            "users": [
                {
                    "userId": 60232299,
                    "name": "60232299",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=60232299&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=60232299&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307215756,
                    "userId": 104181819,
                    "url": "https://cian.ru/sale/flat/307215756/",
                    "title": "3-комн. кв., 57 м², 2/9 этаж",
                    "address": "Саратовская область, Энгельс, Полтавская улица, 44",
                    "publishedUserId": 104181819,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2265554607-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2265554607-1.jpg"
                    },
                    "price": "2 400 000 ₽",
                    "externalId": "71436941"
                }
            ],
            "objectId": 71436941
        },
        "82583": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94946645,
                    "offerId": 307215756,
                    "createdAt": "2024-09-22T12:44:45.213359+00:00",
                    "updatedAt": "2024-09-22T13:24:04.070168+00:00",
                    "messages": [
                        {
                            "messageId": "4327f2dd4c05564ed872a652fb459d86054fcef7d1b6db5ca61b2f80ec58fe70",
                            "userId": 104181819,
                            "direction": "out",
                            "content": {
                                "text": "Добрый день \nПриставы наложили ограничение на регистрационные действия",
                                "offers": []
                            },
                            "createdAt": "22.09.2024 15:24",
                            "new": 0,
                            "userName": "Сергей Карпович"
                        }
                    ],
                    "update_at": "22.09.2024 15:24"
                }
            ],
            "users": [
                {
                    "userId": 104181819,
                    "name": "Сергей Карпович",
                    "firstName": "Сергей",
                    "lastName": "Карпович",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=104181819&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=104181819&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307215756,
                    "userId": 104181819,
                    "url": "https://cian.ru/sale/flat/307215756/",
                    "title": "3-комн. кв., 57 м², 2/9 этаж",
                    "address": "Саратовская область, Энгельс, Полтавская улица, 44",
                    "publishedUserId": 104181819,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2265554607-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2265554607-1.jpg"
                    },
                    "price": "2 400 000 ₽",
                    "externalId": "71436941"
                }
            ],
            "objectId": 71436941
        },
        "82584": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94946645,
                    "offerId": 307215756,
                    "createdAt": "2024-09-22T12:44:45.213359+00:00",
                    "updatedAt": "2024-09-22T13:27:12.553931+00:00",
                    "messages": [
                        {
                            "messageId": "7a03188879aac4e2827e5ca214a203890d5336460dbb309d8a323b56c7a0f892",
                            "userId": 60232299,
                            "direction": "in",
                            "content": {
                                "text": "Какой долг?",
                                "offers": []
                            },
                            "createdAt": "22.09.2024 15:27",
                            "new": 0,
                            "userName": "Пользователь ID 60232299"
                        }
                    ],
                    "update_at": "22.09.2024 15:27"
                }
            ],
            "users": [
                {
                    "userId": 60232299,
                    "name": "60232299",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=60232299&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=60232299&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307215756,
                    "userId": 104181819,
                    "url": "https://cian.ru/sale/flat/307215756/",
                    "title": "3-комн. кв., 57 м², 2/9 этаж",
                    "address": "Саратовская область, Энгельс, Полтавская улица, 44",
                    "publishedUserId": 104181819,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2265554607-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2265554607-1.jpg"
                    },
                    "price": "2 400 000 ₽",
                    "externalId": "71436941"
                }
            ],
            "objectId": 71436941
        },
        "82675": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94984084,
                    "offerId": 307616727,
                    "createdAt": "2024-09-22T19:24:42.499935+00:00",
                    "updatedAt": "2024-09-22T19:24:42.685983+00:00",
                    "messages": [
                        {
                            "messageId": "3e5da4b1485ef76b5b1b561060ba094da83e8a1e1ad79ccb6c46b3265ebb2a1c",
                            "userId": 96623578,
                            "direction": "in",
                            "content": {
                                "text": "Анастасия, здравствуйте объявления актуально?",
                                "offers": []
                            },
                            "createdAt": "22.09.2024 21:24",
                            "new": 0,
                            "userName": "Игорь  Кембель"
                        }
                    ],
                    "update_at": "22.09.2024 21:24"
                }
            ],
            "users": [
                {
                    "userId": 96623578,
                    "name": "Игорь  Кембель",
                    "firstName": "Игорь ",
                    "lastName": "Кембель",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=96623578&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=96623578&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307616727,
                    "userId": 115390423,
                    "url": "https://cian.ru/rent/flat/307616727/",
                    "title": "1-комн. кв., 35 м², 2/5 этаж",
                    "address": "Саратовская область, Энгельс, 2-й мкр, 32",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2275682540-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2275682540-1.jpg"
                    },
                    "price": "15 000 ₽/мес.",
                    "externalId": "72218418"
                }
            ],
            "objectId": 72218418
        },
        "82676": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94984084,
                    "offerId": 307616727,
                    "createdAt": "2024-09-22T19:24:42.499935+00:00",
                    "updatedAt": "2024-09-22T19:24:53.927640+00:00",
                    "messages": [
                        {
                            "messageId": "c96e09e406d3fb50d4168e2417fa72ed336062eda7c84e950a8d09271e58b80e",
                            "userId": 96623578,
                            "direction": "in",
                            "content": {
                                "text": "Пожалуйста, позвоните, когда будет удобно. Вот мой номер\n+79951156863",
                                "offers": []
                            },
                            "createdAt": "22.09.2024 21:24",
                            "new": 0,
                            "userName": "Игорь  Кембель"
                        }
                    ],
                    "update_at": "22.09.2024 21:24"
                }
            ],
            "users": [
                {
                    "userId": 96623578,
                    "name": "Игорь  Кембель",
                    "firstName": "Игорь ",
                    "lastName": "Кембель",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=96623578&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=96623578&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307616727,
                    "userId": 115390423,
                    "url": "https://cian.ru/rent/flat/307616727/",
                    "title": "1-комн. кв., 35 м², 2/5 этаж",
                    "address": "Саратовская область, Энгельс, 2-й мкр, 32",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2275682540-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2275682540-1.jpg"
                    },
                    "price": "15 000 ₽/мес.",
                    "externalId": "72218418"
                }
            ],
            "objectId": 72218418
        },
        "82688": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94984084,
                    "offerId": 307616727,
                    "createdAt": "2024-09-22T19:24:42.499935+00:00",
                    "updatedAt": "2024-09-23T03:43:20.347539+00:00",
                    "messages": [
                        {
                            "messageId": "64ae2762a8f1fdb6bc0b8ce5ff956a340264d32291048e34677cd4cb5f67fbde",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте. Да",
                                "offers": []
                            },
                            "createdAt": "23.09.2024 05:43",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "23.09.2024 05:43"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307616727,
                    "userId": 115390423,
                    "url": "https://cian.ru/rent/flat/307616727/",
                    "title": "1-комн. кв., 35 м², 2/5 этаж",
                    "address": "Саратовская область, Энгельс, 2-й мкр, 32",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2275682540-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2275682540-1.jpg"
                    },
                    "price": "15 000 ₽/мес.",
                    "externalId": "72218418"
                }
            ],
            "objectId": 72218418
        },
        "82689": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 94984084,
                    "offerId": 307616727,
                    "createdAt": "2024-09-22T19:24:42.499935+00:00",
                    "updatedAt": "2024-09-23T03:43:44.363998+00:00",
                    "messages": [
                        {
                            "messageId": "e56619b880e0a056ffe8cc6fd3f3037731774d512a488f8027fdbc1a508f1f66",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Звоните по номеру 89271686925",
                                "offers": []
                            },
                            "createdAt": "23.09.2024 05:43",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "23.09.2024 05:43"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307616727,
                    "userId": 115390423,
                    "url": "https://cian.ru/rent/flat/307616727/",
                    "title": "1-комн. кв., 35 м², 2/5 этаж",
                    "address": "Саратовская область, Энгельс, 2-й мкр, 32",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2275682540-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2275682540-1.jpg"
                    },
                    "price": "15 000 ₽/мес.",
                    "externalId": "72218418"
                }
            ],
            "objectId": 72218418
        },
        "82696": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95010825,
                    "offerId": 307616727,
                    "createdAt": "2024-09-23T05:52:58.819673+00:00",
                    "updatedAt": "2024-09-23T05:52:58.897489+00:00",
                    "messages": [
                        {
                            "messageId": "033afc3441e4c77056616dcaa8b4704a8fd680ee5b5257db254bd767d60df1d9",
                            "userId": 122063766,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Уточните, пожалуйста, ещё сдаёте?",
                                "offers": []
                            },
                            "createdAt": "23.09.2024 07:52",
                            "new": 0,
                            "userName": "Пользователь ID 122063766"
                        }
                    ],
                    "update_at": "23.09.2024 07:52"
                }
            ],
            "users": [
                {
                    "userId": 122063766,
                    "name": "122063766",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=122063766&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=122063766&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307616727,
                    "userId": 115390423,
                    "url": "https://cian.ru/rent/flat/307616727/",
                    "title": "1-комн. кв., 35 м², 2/5 этаж",
                    "address": "Саратовская область, Энгельс, 2-й мкр, 32",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2275682540-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2275682540-1.jpg"
                    },
                    "price": "15 000 ₽/мес.",
                    "externalId": "72218418"
                }
            ],
            "objectId": 72218418
        },
        "82697": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95010825,
                    "offerId": 307616727,
                    "createdAt": "2024-09-23T05:52:58.819673+00:00",
                    "updatedAt": "2024-09-23T05:57:03.233548+00:00",
                    "messages": [
                        {
                            "messageId": "0cf2a31178bea1676c41a24880aa7a5195bb2fed86166851a3412a2c7612f2e2",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте. Да, звоните те по номеру 89271686925",
                                "offers": []
                            },
                            "createdAt": "23.09.2024 07:57",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "23.09.2024 07:57"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307616727,
                    "userId": 115390423,
                    "url": "https://cian.ru/rent/flat/307616727/",
                    "title": "1-комн. кв., 35 м², 2/5 этаж",
                    "address": "Саратовская область, Энгельс, 2-й мкр, 32",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2275682540-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2275682540-1.jpg"
                    },
                    "price": "15 000 ₽/мес.",
                    "externalId": "72218418"
                }
            ],
            "objectId": 72218418
        },
        "82793": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95048422,
                    "offerId": 307215756,
                    "createdAt": "2024-09-23T10:31:22.466255+00:00",
                    "updatedAt": "2024-09-23T10:31:22.721859+00:00",
                    "messages": [
                        {
                            "messageId": "62adea4ca199ad82658dc455dcf66c6327cfc8f40f0ce2cb139616f21650d920",
                            "userId": 15997980,
                            "direction": "in",
                            "content": {
                                "text": "Добрый день, фото с/у есть?",
                                "offers": []
                            },
                            "createdAt": "23.09.2024 12:31",
                            "new": 0,
                            "userName": "Анастасия Таранова"
                        }
                    ],
                    "update_at": "23.09.2024 12:31"
                }
            ],
            "users": [
                {
                    "userId": 15997980,
                    "name": "Анастасия Таранова",
                    "firstName": "Анастасия",
                    "lastName": "Таранова",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=15997980&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=15997980&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307215756,
                    "userId": 104181819,
                    "url": "https://cian.ru/sale/flat/307215756/",
                    "title": "3-комн. кв., 57 м², 2/9 этаж",
                    "address": "Саратовская область, Энгельс, Полтавская улица, 44",
                    "publishedUserId": 104181819,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2265554607-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2265554607-1.jpg"
                    },
                    "price": "2 400 000 ₽",
                    "externalId": "71436941"
                }
            ],
            "objectId": 71436941
        },
        "83117": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95154597,
                    "offerId": 307616727,
                    "createdAt": "2024-09-24T07:34:12.926257+00:00",
                    "updatedAt": "2024-09-24T07:34:13.141442+00:00",
                    "messages": [
                        {
                            "messageId": "01840cb68aa194b5051c3d7d58b08c54675d7cef129764de146b261924d640c0",
                            "userId": 122012636,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте, квартира ещё сдается? хотим снять на месяц 2 человека национальность: русские",
                                "offers": []
                            },
                            "createdAt": "24.09.2024 09:34",
                            "new": 0,
                            "userName": "Пользователь ID 122012636"
                        }
                    ],
                    "update_at": "24.09.2024 09:34"
                }
            ],
            "users": [
                {
                    "userId": 122012636,
                    "name": "122012636",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=122012636&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=122012636&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307616727,
                    "userId": 115390423,
                    "url": "https://cian.ru/rent/flat/307616727/",
                    "title": "1-комн. кв., 35 м², 2/5 этаж",
                    "address": "Саратовская область, Энгельс, 2-й мкр, 32",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2275682540-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2275682540-1.jpg"
                    },
                    "price": "15 000 ₽/мес.",
                    "externalId": "72218418"
                }
            ],
            "objectId": 72218418
        },
        "83119": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95154597,
                    "offerId": 307616727,
                    "createdAt": "2024-09-24T07:34:12.926257+00:00",
                    "updatedAt": "2024-09-24T07:39:22.054801+00:00",
                    "messages": [
                        {
                            "messageId": "314666f5d893d9c0ffecfdfa0aa09b104cc115ed8dddf8b0a4cd5e86e118d4c3",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Добрый день! Квартиру сдала, с рекламы сняла",
                                "offers": []
                            },
                            "createdAt": "24.09.2024 09:39",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "24.09.2024 09:39"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307616727,
                    "userId": 115390423,
                    "url": "https://cian.ru/rent/flat/307616727/",
                    "title": "1-комн. кв., 35 м², 2/5 этаж",
                    "address": "Саратовская область, Энгельс, 2-й мкр, 32",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2275682540-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2275682540-1.jpg"
                    },
                    "price": "15 000 ₽/мес.",
                    "externalId": "72218418"
                }
            ],
            "objectId": 72218418
        },
        "83286": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95236018,
                    "offerId": 307390058,
                    "createdAt": "2024-09-24T18:17:46.641988+00:00",
                    "updatedAt": "2024-09-24T18:17:46.753613+00:00",
                    "messages": [
                        {
                            "messageId": "ea5f03ee3c98d7e2359f53ca45be28b026c3174bd24f86dc85a710260c4ec901",
                            "userId": 96627846,
                            "direction": "in",
                            "content": {
                                "text": "Добрый день \nОбъявление еще актуально?",
                                "offers": []
                            },
                            "createdAt": "24.09.2024 20:17",
                            "new": 0,
                            "userName": "Пользователь ID 96627846"
                        }
                    ],
                    "update_at": "24.09.2024 20:17"
                }
            ],
            "users": [
                {
                    "userId": 96627846,
                    "name": "96627846",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=96627846&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=96627846&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307390058,
                    "userId": 104093587,
                    "url": "https://cian.ru/sale/flat/307390058/",
                    "title": "3-комн. кв., 62,4 м², 2/9 этаж",
                    "address": "Саратовская область, Энгельс, Степная улица, 39",
                    "publishedUserId": 104093587,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2269941194-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2269941194-1.jpg"
                    },
                    "price": "4 250 000 ₽",
                    "externalId": "56438609"
                }
            ],
            "objectId": 56438609
        },
        "83369": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95236018,
                    "offerId": 307390058,
                    "createdAt": "2024-09-24T18:17:46.641988+00:00",
                    "updatedAt": "2024-09-25T06:56:40.805904+00:00",
                    "messages": [
                        {
                            "messageId": "e61ce69095b59dac5db9591de0e767a327413886f90656f227b953c864f19030",
                            "userId": 104093587,
                            "direction": "out",
                            "content": {
                                "text": "Добрый! \nАктуально, звоните по номеру 8-937-024-1718 Екатерина\nЛибо оставьте свой!",
                                "offers": []
                            },
                            "createdAt": "25.09.2024 08:56",
                            "new": 0,
                            "userName": "Екатерина Гаврилова"
                        }
                    ],
                    "update_at": "25.09.2024 08:56"
                }
            ],
            "users": [
                {
                    "userId": 104093587,
                    "name": "Екатерина Гаврилова",
                    "firstName": "Екатерина",
                    "lastName": "Гаврилова",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=104093587&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=104093587&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307390058,
                    "userId": 104093587,
                    "url": "https://cian.ru/sale/flat/307390058/",
                    "title": "3-комн. кв., 62,4 м², 2/9 этаж",
                    "address": "Саратовская область, Энгельс, Степная улица, 39",
                    "publishedUserId": 104093587,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2269941194-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2269941194-1.jpg"
                    },
                    "price": "4 250 000 ₽",
                    "externalId": "56438609"
                }
            ],
            "objectId": 56438609
        },
        "83408": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95292772,
                    "offerId": 307611197,
                    "createdAt": "2024-09-25T08:53:41.808209+00:00",
                    "updatedAt": "2024-09-25T08:53:42.046006+00:00",
                    "messages": [
                        {
                            "messageId": "3cda931f17c8d34b4b3a43e4a6166a771310b7b4d22662e43f19991a794dcf36",
                            "userId": 121628381,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте, план есть помещения?",
                                "offers": []
                            },
                            "createdAt": "25.09.2024 10:53",
                            "new": 0,
                            "userName": "Пользователь ID 121628381"
                        }
                    ],
                    "update_at": "25.09.2024 10:53"
                }
            ],
            "users": [
                {
                    "userId": 121628381,
                    "name": "121628381",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=121628381&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=121628381&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307611197,
                    "userId": 115540687,
                    "url": "https://cian.ru/sale/commercial/307611197/",
                    "title": "Офис, 85,3 м², 1/10 этаж",
                    "address": "Саратовская область, Энгельс, Пролетарская улица, 1",
                    "publishedUserId": 115540687,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2275591104-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2275591104-1.jpg"
                    },
                    "price": "5 990 000 ₽",
                    "externalId": "64042896"
                }
            ],
            "objectId": 64042896
        },
        "83410": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95292772,
                    "offerId": 307611197,
                    "createdAt": "2024-09-25T08:53:41.808209+00:00",
                    "updatedAt": "2024-09-25T09:01:56.095358+00:00",
                    "messages": [
                        {
                            "messageId": "2d4b3fe320cbcb7b5e852ee4202e1fb2f83fce4339ea1013a2ed38c1eac9f221",
                            "userId": 115540687,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте.  Нет.",
                                "offers": []
                            },
                            "createdAt": "25.09.2024 11:01",
                            "new": 0,
                            "userName": "Алексей Савин"
                        }
                    ],
                    "update_at": "25.09.2024 11:01"
                }
            ],
            "users": [
                {
                    "userId": 115540687,
                    "name": "Алексей Савин",
                    "firstName": "Алексей",
                    "lastName": "Савин",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115540687&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115540687&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307611197,
                    "userId": 115540687,
                    "url": "https://cian.ru/sale/commercial/307611197/",
                    "title": "Офис, 85,3 м², 1/10 этаж",
                    "address": "Саратовская область, Энгельс, Пролетарская улица, 1",
                    "publishedUserId": 115540687,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2275591104-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2275591104-1.jpg"
                    },
                    "price": "5 990 000 ₽",
                    "externalId": "64042896"
                }
            ],
            "objectId": 64042896
        },
        "83437": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95304336,
                    "offerId": 300860652,
                    "createdAt": "2024-09-25T10:08:33.084579+00:00",
                    "updatedAt": "2024-09-25T10:08:33.212548+00:00",
                    "messages": [
                        {
                            "messageId": "2f71df1246dac72c32290015e0840d8eee5bade09f31db774fc2f7e0ac5f4180",
                            "userId": 121801162,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте . Скажите дом в собственности ?Можно дом посмотреть в сб или вс?",
                                "offers": []
                            },
                            "createdAt": "25.09.2024 12:08",
                            "new": 0,
                            "userName": "Эвелина"
                        }
                    ],
                    "update_at": "25.09.2024 12:08"
                }
            ],
            "users": [
                {
                    "userId": 121801162,
                    "name": "Эвелина",
                    "firstName": "Эвелина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=121801162&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=121801162&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "83442": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95304336,
                    "offerId": 300860652,
                    "createdAt": "2024-09-25T10:08:33.084579+00:00",
                    "updatedAt": "2024-09-25T10:10:17.159047+00:00",
                    "messages": [
                        {
                            "messageId": "abcdb739f8a3c45b0e17f865fe628c3dd0342e57bfe87c69049a5fc3dd683920",
                            "userId": 121801162,
                            "direction": "in",
                            "content": {
                                "text": "Скажите еще вода проведена в дом ?",
                                "offers": []
                            },
                            "createdAt": "25.09.2024 12:10",
                            "new": 0,
                            "userName": "Эвелина"
                        }
                    ],
                    "update_at": "25.09.2024 12:10"
                }
            ],
            "users": [
                {
                    "userId": 121801162,
                    "name": "Эвелина",
                    "firstName": "Эвелина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=121801162&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=121801162&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "83461": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95304336,
                    "offerId": 300860652,
                    "createdAt": "2024-09-25T10:08:33.084579+00:00",
                    "updatedAt": "2024-09-25T10:52:45.590505+00:00",
                    "messages": [
                        {
                            "messageId": "24496a3a47f259cc78f2ffdf1551c4aa62ad125e46b91a25cda7a65519d6b81b",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте. Да, организуем показ в выходные",
                                "offers": []
                            },
                            "createdAt": "25.09.2024 12:52",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "25.09.2024 12:52"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "83462": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95304336,
                    "offerId": 300860652,
                    "createdAt": "2024-09-25T10:08:33.084579+00:00",
                    "updatedAt": "2024-09-25T10:53:03.798765+00:00",
                    "messages": [
                        {
                            "messageId": "6fff9c4e4d549a01b5e368f209da6998f08ec5ca20340ae7802b52dc59d3daf8",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Мой номер для связи 89271686925 и 89379621718",
                                "offers": []
                            },
                            "createdAt": "25.09.2024 12:53",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "25.09.2024 12:53"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "83463": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95304336,
                    "offerId": 300860652,
                    "createdAt": "2024-09-25T10:08:33.084579+00:00",
                    "updatedAt": "2024-09-25T10:53:25.329294+00:00",
                    "messages": [
                        {
                            "messageId": "77757ffb82181d477cbadbd6f2e6e57e4734532bb854d53cd45e08a24cf2e750",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Вода в доме есть",
                                "offers": []
                            },
                            "createdAt": "25.09.2024 12:53",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "25.09.2024 12:53"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "83464": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95304336,
                    "offerId": 300860652,
                    "createdAt": "2024-09-25T10:08:33.084579+00:00",
                    "updatedAt": "2024-09-25T10:55:22.192937+00:00",
                    "messages": [
                        {
                            "messageId": "7a8b25c419441f3317696cec7ba0038bb11c936b005cb90780503db9a6b857db",
                            "userId": 121801162,
                            "direction": "in",
                            "content": {
                                "text": "хорошо .Тогда когда вам позвонить в пт?",
                                "offers": []
                            },
                            "createdAt": "25.09.2024 12:55",
                            "new": 0,
                            "userName": "Эвелина"
                        }
                    ],
                    "update_at": "25.09.2024 12:55"
                }
            ],
            "users": [
                {
                    "userId": 121801162,
                    "name": "Эвелина",
                    "firstName": "Эвелина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=121801162&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=121801162&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "83465": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95304336,
                    "offerId": 300860652,
                    "createdAt": "2024-09-25T10:08:33.084579+00:00",
                    "updatedAt": "2024-09-25T10:58:36.197982+00:00",
                    "messages": [
                        {
                            "messageId": "ecfd99063c80fa5126b157759d73b1cbd175dfc72f76afd000fd9545f1df516f",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Да, давайте созвонимся ближе к концу недели",
                                "offers": []
                            },
                            "createdAt": "25.09.2024 12:58",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "25.09.2024 12:58"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "83517": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95337374,
                    "offerId": 307568517,
                    "createdAt": "2024-09-25T14:00:08.256206+00:00",
                    "updatedAt": "2024-09-25T14:00:08.426682+00:00",
                    "messages": [
                        {
                            "messageId": "64e85df4719b6a25437c825e94107831b3c8d25650a2c48da22ee9f3d6f46652",
                            "userId": 118737792,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Подскажите пожалуйста какой статус дома по документам.",
                                "offers": []
                            },
                            "createdAt": "25.09.2024 16:00",
                            "new": 0,
                            "userName": "Пользователь ID 118737792"
                        }
                    ],
                    "update_at": "25.09.2024 16:00"
                }
            ],
            "users": [
                {
                    "userId": 118737792,
                    "name": "118737792",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=118737792&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=118737792&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307568517,
                    "userId": 115760105,
                    "url": "https://cian.ru/sale/suburban/307568517/",
                    "title": "Дом, 82 м²",
                    "address": "Саратовская область, Воскресенский район, Елшанское муниципальное образование, Росинка 2002 СТ",
                    "publishedUserId": 115760105,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2274322068-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2274322068-1.jpg"
                    },
                    "price": "1 200 000 ₽",
                    "externalId": "72127683"
                }
            ],
            "objectId": 72127683
        },
        "83524": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95340801,
                    "offerId": 307755175,
                    "createdAt": "2024-09-25T14:24:09.997268+00:00",
                    "updatedAt": "2024-09-25T14:24:10.120056+00:00",
                    "messages": [
                        {
                            "messageId": "8e2204d600344d6d6d26e81691fd57c0f23afaff8a747ecc71865d2d335da03f",
                            "userId": 5540924,
                            "direction": "in",
                            "content": {
                                "text": "Что остается новым жильцам",
                                "offers": []
                            },
                            "createdAt": "25.09.2024 16:24",
                            "new": 0,
                            "userName": "Елена Фалтейсек"
                        }
                    ],
                    "update_at": "25.09.2024 16:24"
                }
            ],
            "users": [
                {
                    "userId": 5540924,
                    "name": "Елена Фалтейсек",
                    "firstName": "Елена",
                    "lastName": "Фалтейсек",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=5540924&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=5540924&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307755175,
                    "userId": 116723160,
                    "url": "https://cian.ru/sale/flat/307755175/",
                    "title": "1-комн. кв., 32,4 м², 2/9 этаж",
                    "address": "Саратовская область, Саратов, Кавказский проезд, 6а",
                    "publishedUserId": 116723160,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2279636448-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2279636448-1.jpg"
                    },
                    "price": "3 600 000 ₽",
                    "externalId": "72394407"
                }
            ],
            "objectId": 72394407
        },
        "83534": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95340801,
                    "offerId": 307755175,
                    "createdAt": "2024-09-25T14:24:09.997268+00:00",
                    "updatedAt": "2024-09-25T14:50:27.308885+00:00",
                    "messages": [
                        {
                            "messageId": "5d80b27a3b4969ee72445ecfeae5002f0bdc3c859a02062b08b69d7d6a7d8944",
                            "userId": 116723160,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте! При продаже остаётся кухня (шкафчики, тумбочки)плита+раковина, вытяжка, кух. уголок, водонагреватель, шкаф купе, стол письменный в комнате. Если что-то нужно ещё, то по договорённости\nТел. для связи +7964 997 37 26",
                                "offers": []
                            },
                            "createdAt": "25.09.2024 16:50",
                            "new": 0,
                            "userName": "Мария Тарасова"
                        }
                    ],
                    "update_at": "25.09.2024 16:50"
                }
            ],
            "users": [
                {
                    "userId": 116723160,
                    "name": "Мария Тарасова",
                    "firstName": "Мария",
                    "lastName": "Тарасова",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=116723160&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=116723160&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307755175,
                    "userId": 116723160,
                    "url": "https://cian.ru/sale/flat/307755175/",
                    "title": "1-комн. кв., 32,4 м², 2/9 этаж",
                    "address": "Саратовская область, Саратов, Кавказский проезд, 6а",
                    "publishedUserId": 116723160,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2279636448-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2279636448-1.jpg"
                    },
                    "price": "3 600 000 ₽",
                    "externalId": "72394407"
                }
            ],
            "objectId": 72394407
        },
        "83623": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95386563,
                    "offerId": 300836901,
                    "createdAt": "2024-09-26T03:44:30.749522+00:00",
                    "updatedAt": "2024-09-26T03:44:30.918344+00:00",
                    "messages": [
                        {
                            "messageId": "ef8561f7065a863dce036c22f706abc5b1124f7f7e285fbd4d0de77df3c54a3d",
                            "userId": 48504235,
                            "direction": "in",
                            "content": {
                                "text": "Доброе утро, скидка есть на дом .",
                                "offers": []
                            },
                            "createdAt": "26.09.2024 05:44",
                            "new": 0,
                            "userName": "Надежда Пурим"
                        }
                    ],
                    "update_at": "26.09.2024 05:44"
                }
            ],
            "users": [
                {
                    "userId": 48504235,
                    "name": "Надежда Пурим",
                    "firstName": "Надежда",
                    "lastName": "Пурим",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=48504235&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=48504235&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300836901,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300836901/",
                    "title": "Дом, 75,7 м²",
                    "address": "Саратовская область, Красноармейск муниципальное образование, с. Ревино, улица Садовая, 2",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2150548187-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2150548187-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63973944"
                }
            ],
            "objectId": 63973944
        },
        "83624": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95386563,
                    "offerId": 300836901,
                    "createdAt": "2024-09-26T03:44:30.749522+00:00",
                    "updatedAt": "2024-09-26T03:46:41.664418+00:00",
                    "messages": [
                        {
                            "messageId": "09c7059460919bec61a7f61611abe684cde9c9035390b32f74da175b1c344d52",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте. Смотря какая скидка.\nЗвоните по вопросам по телефону 89271686925 или 89379621718",
                                "offers": []
                            },
                            "createdAt": "26.09.2024 05:46",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "26.09.2024 05:46"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300836901,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300836901/",
                    "title": "Дом, 75,7 м²",
                    "address": "Саратовская область, Красноармейск муниципальное образование, с. Ревино, улица Садовая, 2",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2150548187-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2150548187-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63973944"
                }
            ],
            "objectId": 63973944
        },
        "83650": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95406674,
                    "offerId": 300865457,
                    "createdAt": "2024-09-26T07:23:04.472583+00:00",
                    "updatedAt": "2024-09-26T07:23:04.691836+00:00",
                    "messages": [
                        {
                            "messageId": "19d38a8c9367e8895862b211b98280c7457648a6a7d7b025ea5d0aa32cf7924c",
                            "userId": 106270336,
                            "direction": "in",
                            "content": {
                                "text": "Добрый день! В воскресенье можно посмотреть?",
                                "offers": []
                            },
                            "createdAt": "26.09.2024 09:23",
                            "new": 0,
                            "userName": "Олег"
                        }
                    ],
                    "update_at": "26.09.2024 09:23"
                }
            ],
            "users": [
                {
                    "userId": 106270336,
                    "name": "Олег",
                    "firstName": "Олег",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=106270336&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=106270336&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300865457,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300865457/",
                    "title": "Земельный участок, 13 сот.",
                    "address": "Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148411746-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148411746-1.jpg"
                    },
                    "price": "550 000 ₽",
                    "externalId": "63869445"
                }
            ],
            "objectId": 63869445
        },
        "83653": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95406674,
                    "offerId": 300865457,
                    "createdAt": "2024-09-26T07:23:04.472583+00:00",
                    "updatedAt": "2024-09-26T07:24:25.853612+00:00",
                    "messages": [
                        {
                            "messageId": "4559f7dd823ecb78ff77edb5f18a7445d8cb9c9ba618a6b56a2d80a5b03f64c2",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте. Да. \nЗвоните по номеру 89271686925, договоримся",
                                "offers": []
                            },
                            "createdAt": "26.09.2024 09:24",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "26.09.2024 09:24"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300865457,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300865457/",
                    "title": "Земельный участок, 13 сот.",
                    "address": "Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148411746-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148411746-1.jpg"
                    },
                    "price": "550 000 ₽",
                    "externalId": "63869445"
                }
            ],
            "objectId": 63869445
        },
        "83715": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95449081,
                    "offerId": 306704393,
                    "createdAt": "2024-09-26T11:57:40.586781+00:00",
                    "updatedAt": "2024-09-26T11:57:40.759322+00:00",
                    "messages": [
                        {
                            "messageId": "2f7bae3a9b105a3c024e6e2540f70daff57bedfdcbadd1163d41d4c6ace054f2",
                            "userId": 122248544,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Уточните, пожалуйста, ещё сдаёте?",
                                "offers": []
                            },
                            "createdAt": "26.09.2024 13:57",
                            "new": 0,
                            "userName": "Пользователь ID 122248544"
                        }
                    ],
                    "update_at": "26.09.2024 13:57"
                }
            ],
            "users": [
                {
                    "userId": 122248544,
                    "name": "122248544",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=122248544&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=122248544&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306704393,
                    "userId": 114681854,
                    "url": "https://cian.ru/rent/flat/306704393/",
                    "title": "1-комн. кв., 37,9 м², 2/10 этаж",
                    "address": "Саратовская область, Энгельс, улица Шурова Гора, 7/7",
                    "publishedUserId": 114681854,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2256119804-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2256119804-1.jpg"
                    },
                    "price": "20 000 ₽/мес.",
                    "externalId": "71046454"
                }
            ],
            "objectId": 71046454
        },
        "83867": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 89031205,
                    "offerId": 305017367,
                    "createdAt": "2024-08-09T10:21:42.668390+00:00",
                    "updatedAt": "2024-09-27T05:53:58.465825+00:00",
                    "messages": [
                        {
                            "messageId": "edf19d067f3854d7c54658c1f884f1ac6e264385f525e55259cf95a76b318724",
                            "userId": 115540687,
                            "direction": "out",
                            "content": {
                                "text": "продали за 970",
                                "offers": []
                            },
                            "createdAt": "27.09.2024 07:53",
                            "new": 0,
                            "userName": "Алексей Савин"
                        }
                    ],
                    "update_at": "27.09.2024 07:53"
                }
            ],
            "users": [
                {
                    "userId": 115540687,
                    "name": "Алексей Савин",
                    "firstName": "Алексей",
                    "lastName": "Савин",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115540687&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115540687&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 305017367,
                    "userId": 115540687,
                    "url": "https://cian.ru/sale/suburban/305017367/",
                    "externalId": "64035747"
                }
            ],
            "objectId": 64035747
        },
        "83962": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95386563,
                    "offerId": 300836901,
                    "createdAt": "2024-09-26T03:44:30.749522+00:00",
                    "updatedAt": "2024-09-27T10:23:08.445961+00:00",
                    "messages": [
                        {
                            "messageId": "ec87ce76909cc230e433e0ea5fe6d70a831a74157c0a771608948d1ce7a3df9a",
                            "userId": 48504235,
                            "direction": "in",
                            "content": {
                                "text": "Анастасия добрый день, что на счёт дома, скажите ?",
                                "offers": []
                            },
                            "createdAt": "27.09.2024 12:23",
                            "new": 0,
                            "userName": "Надежда Пурим"
                        }
                    ],
                    "update_at": "27.09.2024 12:23"
                }
            ],
            "users": [
                {
                    "userId": 48504235,
                    "name": "Надежда Пурим",
                    "firstName": "Надежда",
                    "lastName": "Пурим",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=48504235&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=48504235&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300836901,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300836901/",
                    "title": "Дом, 75,7 м²",
                    "address": "Саратовская область, Красноармейск муниципальное образование, с. Ревино, улица Садовая, 2",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2150548187-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2150548187-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63973944"
                }
            ],
            "objectId": 63973944
        },
        "84051": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95386563,
                    "offerId": 300836901,
                    "createdAt": "2024-09-26T03:44:30.749522+00:00",
                    "updatedAt": "2024-09-27T16:04:50.139886+00:00",
                    "messages": [
                        {
                            "messageId": "127253addc56aeddf1568b38a84c6512a4967cbd970a468d429f81dce6b53c67",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте. Пока не получила ответ от продавца.",
                                "offers": []
                            },
                            "createdAt": "27.09.2024 18:04",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "27.09.2024 18:04"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300836901,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300836901/",
                    "title": "Дом, 75,7 м²",
                    "address": "Саратовская область, Красноармейск муниципальное образование, с. Ревино, улица Садовая, 2",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2150548187-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2150548187-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63973944"
                }
            ],
            "objectId": 63973944
        },
        "84196": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95386563,
                    "offerId": 300836901,
                    "createdAt": "2024-09-26T03:44:30.749522+00:00",
                    "updatedAt": "2024-09-28T08:47:49.131435+00:00",
                    "messages": [
                        {
                            "messageId": "37a2121fd40afc6a1218f98e21bedea129ebca8e1a40d66e0a9bc47511483d6f",
                            "userId": 48504235,
                            "direction": "in",
                            "content": {
                                "text": "Ок",
                                "offers": []
                            },
                            "createdAt": "28.09.2024 10:47",
                            "new": 0,
                            "userName": "Надежда Пурим"
                        }
                    ],
                    "update_at": "28.09.2024 10:47"
                }
            ],
            "users": [
                {
                    "userId": 48504235,
                    "name": "Надежда Пурим",
                    "firstName": "Надежда",
                    "lastName": "Пурим",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=48504235&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=48504235&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300836901,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300836901/",
                    "title": "Дом, 75,7 м²",
                    "address": "Саратовская область, Красноармейск муниципальное образование, с. Ревино, улица Садовая, 2",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2150548187-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2150548187-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63973944"
                }
            ],
            "objectId": 63973944
        },
        "84219": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95406674,
                    "offerId": 300865457,
                    "createdAt": "2024-09-26T07:23:04.472583+00:00",
                    "updatedAt": "2024-09-28T10:02:47.622140+00:00",
                    "messages": [
                        {
                            "messageId": "a6838dffa20a7583e0ea79d8f8e9add1e818c832e6e1f8433b6183f21b40beb1",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте. Участок завтра будете смотреть?",
                                "offers": []
                            },
                            "createdAt": "28.09.2024 12:02",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "28.09.2024 12:02"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300865457,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300865457/",
                    "title": "Земельный участок, 13 сот.",
                    "address": "Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148411746-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148411746-1.jpg"
                    },
                    "price": "550 000 ₽",
                    "externalId": "63869445"
                }
            ],
            "objectId": 63869445
        },
        "84220": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95406674,
                    "offerId": 300865457,
                    "createdAt": "2024-09-26T07:23:04.472583+00:00",
                    "updatedAt": "2024-09-28T10:33:26.427166+00:00",
                    "messages": [
                        {
                            "messageId": "11ccee8bd4f417831ed4a0a212c50137216c0507a9e4d1ebbfee9305325450a5",
                            "userId": 106270336,
                            "direction": "in",
                            "content": {
                                "text": "Добрый день. На фото непонятно, он на склоне находится?",
                                "offers": []
                            },
                            "createdAt": "28.09.2024 12:33",
                            "new": 0,
                            "userName": "Олег"
                        }
                    ],
                    "update_at": "28.09.2024 12:33"
                }
            ],
            "users": [
                {
                    "userId": 106270336,
                    "name": "Олег",
                    "firstName": "Олег",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=106270336&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=106270336&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300865457,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300865457/",
                    "title": "Земельный участок, 13 сот.",
                    "address": "Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148411746-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148411746-1.jpg"
                    },
                    "price": "550 000 ₽",
                    "externalId": "63869445"
                }
            ],
            "objectId": 63869445
        },
        "84221": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95406674,
                    "offerId": 300865457,
                    "createdAt": "2024-09-26T07:23:04.472583+00:00",
                    "updatedAt": "2024-09-28T10:33:27.027571+00:00",
                    "messages": [
                        {
                            "messageId": "9508d5a9e33c1102e542bb55ce3aa5bd8c9699458db9e3fd643f6e1ad1cae0fa",
                            "userId": 106270336,
                            "direction": "in",
                            "content": {
                                "text": "Добрый день. На фото непонятно, он на склоне находится?",
                                "offers": []
                            },
                            "createdAt": "28.09.2024 12:33",
                            "new": 0,
                            "userName": "Олег"
                        }
                    ],
                    "update_at": "28.09.2024 12:33"
                }
            ],
            "users": [
                {
                    "userId": 106270336,
                    "name": "Олег",
                    "firstName": "Олег",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=106270336&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=106270336&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300865457,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300865457/",
                    "title": "Земельный участок, 13 сот.",
                    "address": "Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148411746-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148411746-1.jpg"
                    },
                    "price": "550 000 ₽",
                    "externalId": "63869445"
                }
            ],
            "objectId": 63869445
        },
        "84222": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95406674,
                    "offerId": 300865457,
                    "createdAt": "2024-09-26T07:23:04.472583+00:00",
                    "updatedAt": "2024-09-28T10:35:57.234797+00:00",
                    "messages": [
                        {
                            "messageId": "8eac72c86a3ea4c4dd5594ed6cd19bbf90bea7e7bb1efa32e7e331bc4cba083b",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Участок у подножия горы, местами не ровный, но не на склоне",
                                "offers": []
                            },
                            "createdAt": "28.09.2024 12:35",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "28.09.2024 12:35"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300865457,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300865457/",
                    "title": "Земельный участок, 13 сот.",
                    "address": "Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148411746-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148411746-1.jpg"
                    },
                    "price": "550 000 ₽",
                    "externalId": "63869445"
                }
            ],
            "objectId": 63869445
        },
        "84223": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95406674,
                    "offerId": 300865457,
                    "createdAt": "2024-09-26T07:23:04.472583+00:00",
                    "updatedAt": "2024-09-28T10:38:21.006558+00:00",
                    "messages": [
                        {
                            "messageId": "85bc3f8e03b991842e0fe15e69d51918744961d6591a07a5ec08709a62e84fbb",
                            "userId": 106270336,
                            "direction": "in",
                            "content": {
                                "text": "В это воскресенье не будем смотреть",
                                "offers": []
                            },
                            "createdAt": "28.09.2024 12:38",
                            "new": 0,
                            "userName": "Олег"
                        }
                    ],
                    "update_at": "28.09.2024 12:38"
                }
            ],
            "users": [
                {
                    "userId": 106270336,
                    "name": "Олег",
                    "firstName": "Олег",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=106270336&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=106270336&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300865457,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300865457/",
                    "title": "Земельный участок, 13 сот.",
                    "address": "Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148411746-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148411746-1.jpg"
                    },
                    "price": "550 000 ₽",
                    "externalId": "63869445"
                }
            ],
            "objectId": 63869445
        },
        "84224": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95406674,
                    "offerId": 300865457,
                    "createdAt": "2024-09-26T07:23:04.472583+00:00",
                    "updatedAt": "2024-09-28T10:38:21.694712+00:00",
                    "messages": [
                        {
                            "messageId": "2dcc463634ee82639d9a3bf2b7e7638bf07cd1167757add5cd7b5f30e3ffbbe0",
                            "userId": 106270336,
                            "direction": "in",
                            "content": {
                                "text": "В это воскресенье не будем смотреть",
                                "offers": []
                            },
                            "createdAt": "28.09.2024 12:38",
                            "new": 0,
                            "userName": "Олег"
                        }
                    ],
                    "update_at": "28.09.2024 12:38"
                }
            ],
            "users": [
                {
                    "userId": 106270336,
                    "name": "Олег",
                    "firstName": "Олег",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=106270336&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=106270336&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300865457,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300865457/",
                    "title": "Земельный участок, 13 сот.",
                    "address": "Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148411746-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148411746-1.jpg"
                    },
                    "price": "550 000 ₽",
                    "externalId": "63869445"
                }
            ],
            "objectId": 63869445
        },
        "84228": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95406674,
                    "offerId": 300865457,
                    "createdAt": "2024-09-26T07:23:04.472583+00:00",
                    "updatedAt": "2024-09-28T10:48:36.077757+00:00",
                    "messages": [
                        {
                            "messageId": "0fa3c6f845a0ff1433f433291ec8ed2a9ccd6c8611006b6f118a666b5c02e408",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Хорошо",
                                "offers": []
                            },
                            "createdAt": "28.09.2024 12:48",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "28.09.2024 12:48"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300865457,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300865457/",
                    "title": "Земельный участок, 13 сот.",
                    "address": "Саратовская область, пос. Красный Текстильщик, улица Песочная, 50А",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148411746-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148411746-1.jpg"
                    },
                    "price": "550 000 ₽",
                    "externalId": "63869445"
                }
            ],
            "objectId": 63869445
        },
        "84260": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95694034,
                    "offerId": 305858926,
                    "createdAt": "2024-09-28T11:49:55.625207+00:00",
                    "updatedAt": "2024-09-28T11:49:55.856170+00:00",
                    "messages": [
                        {
                            "messageId": "e80abf7b7cb121d563e4f8ec7299165de506a412989c95c907964314a5150600",
                            "userId": 114743865,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте,квартира угловая ?",
                                "offers": []
                            },
                            "createdAt": "28.09.2024 13:49",
                            "new": 0,
                            "userName": "Пользователь ID 114743865"
                        }
                    ],
                    "update_at": "28.09.2024 13:49"
                }
            ],
            "users": [
                {
                    "userId": 114743865,
                    "name": "114743865",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=114743865&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=114743865&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 305858926,
                    "userId": 114681854,
                    "url": "https://cian.ru/sale/flat/305858926/",
                    "title": "1-комн. кв., 39,8 м², 3/10 этаж",
                    "address": "Саратовская область, Энгельс, улица Шурова Гора, 7/8",
                    "publishedUserId": 114681854,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2241306491-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2241306491-1.jpg"
                    },
                    "price": "4 070 000 ₽",
                    "externalId": "70054879"
                }
            ],
            "objectId": 70054879
        },
        "84453": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95777014,
                    "offerId": 306555301,
                    "createdAt": "2024-09-29T09:38:20.165471+00:00",
                    "updatedAt": "2024-09-29T09:38:20.448716+00:00",
                    "messages": [
                        {
                            "messageId": "d5be5cbd3fe70cb1a0c25726839dcedabceb3ce9ec2b8d6de5a2edb49c6fbbfa",
                            "userId": 110186933,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Пожалуйста, свяжитесь со мной +79378192555",
                                "offers": []
                            },
                            "createdAt": "29.09.2024 11:38",
                            "new": 0,
                            "userName": "Татьяна  Осипова"
                        }
                    ],
                    "update_at": "29.09.2024 11:38"
                }
            ],
            "users": [
                {
                    "userId": 110186933,
                    "name": "Татьяна  Осипова",
                    "firstName": "Татьяна ",
                    "lastName": "Осипова",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=110186933&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=110186933&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306555301,
                    "userId": 114681854,
                    "url": "https://cian.ru/rent/flat/306555301/",
                    "title": "1-комн. кв., 39,2 м², 16/26 этаж",
                    "address": "Саратовская область, Энгельс, улица Пушкина, 87",
                    "publishedUserId": 114681854,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2253640066-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2253640066-1.jpg"
                    },
                    "price": "23 000 ₽/мес.",
                    "externalId": "53936599"
                }
            ],
            "objectId": 53936599
        },
        "84554": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95804228,
                    "offerId": 306555301,
                    "createdAt": "2024-09-29T13:26:53.084252+00:00",
                    "updatedAt": "2024-09-29T13:26:53.261851+00:00",
                    "messages": [
                        {
                            "messageId": "6c3bcb02581cef1ea671114f714575620ca645b471a42097d8ae48a64f4dd958",
                            "userId": 122124703,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте, подскажите, пожалуйста, а диван не планируете менять?",
                                "offers": []
                            },
                            "createdAt": "29.09.2024 15:26",
                            "new": 0,
                            "userName": "Александра"
                        }
                    ],
                    "update_at": "29.09.2024 15:26"
                }
            ],
            "users": [
                {
                    "userId": 122124703,
                    "name": "Александра",
                    "firstName": "Александра",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=122124703&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=122124703&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306555301,
                    "userId": 114681854,
                    "url": "https://cian.ru/rent/flat/306555301/",
                    "title": "1-комн. кв., 39,2 м², 16/26 этаж",
                    "address": "Саратовская область, Энгельс, улица Пушкина, 87",
                    "publishedUserId": 114681854,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2253640066-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2253640066-1.jpg"
                    },
                    "price": "23 000 ₽/мес.",
                    "externalId": "53936599"
                }
            ],
            "objectId": 53936599
        },
        "84915": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95936258,
                    "offerId": 300865439,
                    "createdAt": "2024-09-30T14:16:29.613645+00:00",
                    "updatedAt": "2024-09-30T14:16:29.891107+00:00",
                    "messages": [
                        {
                            "messageId": "e65030acb98e56384ceacc4bbd2c9af19fce1f525cae1be0fe940a2fc8b2e8a1",
                            "userId": 13920165,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Уточните, пожалуйста, вы ещё продаёте эту квартиру?",
                                "offers": []
                            },
                            "createdAt": "30.09.2024 16:16",
                            "new": 0,
                            "userName": "Пользователь ID 13920165"
                        }
                    ],
                    "update_at": "30.09.2024 16:16"
                }
            ],
            "users": [
                {
                    "userId": 13920165,
                    "name": "13920165",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=13920165&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=13920165&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300865439,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/flat/300865439/",
                    "title": "1-комн. кв., 21,4 м², 8/9 этаж",
                    "address": "Саратовская область, Саратов, проспект Энтузиастов, 9а",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148411549-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148411549-1.jpg"
                    },
                    "price": "2 900 000 ₽",
                    "externalId": "63832560"
                }
            ],
            "objectId": 63832560
        },
        "84916": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95936258,
                    "offerId": 300865439,
                    "createdAt": "2024-09-30T14:16:29.613645+00:00",
                    "updatedAt": "2024-09-30T14:18:40.256987+00:00",
                    "messages": [
                        {
                            "messageId": "dbffeec6055cdf481bd0a8d754499607c8eceac67a036e7884ffee9677e8363b",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте. Да. Звоните , 89271686925",
                                "offers": []
                            },
                            "createdAt": "30.09.2024 16:18",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "30.09.2024 16:18"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300865439,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/flat/300865439/",
                    "title": "1-комн. кв., 21,4 м², 8/9 этаж",
                    "address": "Саратовская область, Саратов, проспект Энтузиастов, 9а",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148411549-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148411549-1.jpg"
                    },
                    "price": "2 900 000 ₽",
                    "externalId": "63832560"
                }
            ],
            "objectId": 63832560
        },
        "84951": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95957480,
                    "offerId": 306907471,
                    "createdAt": "2024-09-30T17:18:30.328390+00:00",
                    "updatedAt": "2024-09-30T17:18:30.546323+00:00",
                    "messages": [
                        {
                            "messageId": "a029c229586f061b3472e75a9b9c6074f97d0d01f18b2548c392efac0a420a51",
                            "userId": 115641322,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте в аренду нельзя взять?",
                                "offers": []
                            },
                            "createdAt": "30.09.2024 19:18",
                            "new": 0,
                            "userName": "Пользователь ID 115641322"
                        }
                    ],
                    "update_at": "30.09.2024 19:18"
                }
            ],
            "users": [
                {
                    "userId": 115641322,
                    "name": "115641322",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115641322&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115641322&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 306907471,
                    "userId": 104181819,
                    "url": "https://cian.ru/sale/suburban/306907471/",
                    "title": "Дом, 66,6 м²",
                    "address": "Саратовская область, Энгельс, пос. Новое Осокорье, улица Байкальская, 97",
                    "publishedUserId": 104181819,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2259825156-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2259825156-1.jpg"
                    },
                    "price": "3 400 000 ₽",
                    "externalId": "70899050"
                }
            ],
            "objectId": 70899050
        },
        "85028": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95988871,
                    "offerId": 307963232,
                    "createdAt": "2024-10-01T04:37:54.113209+00:00",
                    "updatedAt": "2024-10-01T04:37:54.165473+00:00",
                    "messages": [
                        {
                            "messageId": "74d3c0e7814c4ad1b2571290f500fc0210ce6b9161e9893bf5c677908fe488f4",
                            "userId": 54327425,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Душм в доме нет ?",
                                "offers": []
                            },
                            "createdAt": "01.10.2024 06:37",
                            "new": 0,
                            "userName": "Сергей"
                        }
                    ],
                    "update_at": "01.10.2024 06:37"
                }
            ],
            "users": [
                {
                    "userId": 54327425,
                    "name": "Сергей",
                    "firstName": "Сергей",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=54327425&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=54327425&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307963232,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/307963232/",
                    "title": "Дом, 40 м²",
                    "address": "Саратовская область, Энгельс, Тихая улица, 3",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2283730912-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2283730912-1.jpg"
                    },
                    "price": "4 500 000 ₽",
                    "externalId": "72628711"
                }
            ],
            "objectId": 72628711
        },
        "85029": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 95988871,
                    "offerId": 307963232,
                    "createdAt": "2024-10-01T04:37:54.113209+00:00",
                    "updatedAt": "2024-10-01T04:40:23.909792+00:00",
                    "messages": [
                        {
                            "messageId": "6e011ed77ff8dd71b23cc9b58fe42acaf36b42d4589dca19a0eff7795d002634",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте. Нет, но можно сделать.\nЕсть баня.\nНомер для связи 89271686925",
                                "offers": []
                            },
                            "createdAt": "01.10.2024 06:40",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "01.10.2024 06:40"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 307963232,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/307963232/",
                    "title": "Дом, 40 м²",
                    "address": "Саратовская область, Энгельс, Тихая улица, 3",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2283730912-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2283730912-1.jpg"
                    },
                    "price": "4 500 000 ₽",
                    "externalId": "72628711"
                }
            ],
            "objectId": 72628711
        },
        "85626": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 96243618,
                    "offerId": 300860652,
                    "createdAt": "2024-10-02T18:52:00.112417+00:00",
                    "updatedAt": "2024-10-02T18:52:00.180882+00:00",
                    "messages": [
                        {
                            "messageId": "f52d8fb059bff5411bba8353373f3ed9b3b9162ba9a4a2f8129fff4d4fb51869",
                            "userId": 122318476,
                            "direction": "in",
                            "content": {
                                "text": "Здравствуйте. Пожалуйста, свяжитесь со мной +79954997015",
                                "offers": []
                            },
                            "createdAt": "02.10.2024 20:52",
                            "new": 0,
                            "userName": "Ксения"
                        }
                    ],
                    "update_at": "02.10.2024 20:52"
                }
            ],
            "users": [
                {
                    "userId": 122318476,
                    "name": "Ксения",
                    "firstName": "Ксения",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=122318476&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=122318476&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        },
        "85627": {
            "webhookType": "offersMessages",
            "chats": [
                {
                    "chatId": 96243618,
                    "offerId": 300860652,
                    "createdAt": "2024-10-02T18:52:00.112417+00:00",
                    "updatedAt": "2024-10-02T18:55:15.523150+00:00",
                    "messages": [
                        {
                            "messageId": "ef665ff5271db9c23d78c2186b40a5aea47c04f33ad61cc1404bb8d7021dab6c",
                            "userId": 115390423,
                            "direction": "out",
                            "content": {
                                "text": "Здравствуйте. Завтра позвоню. Мой номер 89271686925",
                                "offers": []
                            },
                            "createdAt": "02.10.2024 20:55",
                            "new": 0,
                            "userName": "Анастасия Лачугина"
                        }
                    ],
                    "update_at": "02.10.2024 20:55"
                }
            ],
            "users": [
                {
                    "userId": 115390423,
                    "name": "Анастасия Лачугина",
                    "firstName": "Анастасия",
                    "lastName": "Лачугина",
                    "avatar": {
                        "images": {
                            "small": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=1",
                            "big": "https://www.cian.ru/avatar.axd?i=115390423&t=2&p=2"
                        }
                    }
                }
            ],
            "offers": [
                {
                    "id": 300860652,
                    "userId": 115390423,
                    "url": "https://cian.ru/sale/suburban/300860652/",
                    "title": "Дом, 73 м²",
                    "address": "Саратовская область, Федоровский район, Калужское муниципальное образование, с. Тамбовка, улица Победы, 73",
                    "publishedUserId": 115390423,
                    "mainPhoto": {
                        "miniUrl": "https://images.cdn-cian.ru/images/2148334102-3.jpg",
                        "url": "https://images.cdn-cian.ru/images/2148334102-1.jpg"
                    },
                    "price": "400 000 ₽",
                    "externalId": "63977339"
                }
            ],
            "objectId": 63977339
        }
    }
}

export const avito_response = {
    "chats": {
        "chat128310": {
            "id": 128310,
            "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
            "user_id": 0,
            "agency_id": 4,
            "object_id": null,
            "details": {
                "id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                "context": {
                    "type": "item",
                    "value": {
                        "id": 4458396777,
                        "title": "Аренда автомобиля для доставки",
                        "user_id": 388757292,
                        "images": {
                            "main": {
                                "140x105": "https://20.img.avito.st/image/1/1.Aen7sba-rQDVEH0D9Y1CjdUQrwZFGg8B9RCvAksQNwCfE68.77ywF5ZHLW-KuVRRcJcxEghF3m8ihozmlYdnLtqXtao",
                                "img": "https://20.img.avito.st/image/1/1.Aen7sba-rQDVEH0D9Y1CjdUQrwZFGg8B9RCvAksQNwCfE68.77ywF5ZHLW-KuVRRcJcxEghF3m8ihozmlYdnLtqXtao"
                            },
                            "count": 1
                        },
                        "status_id": 4,
                        "price_string": "1 250 ₽",
                        "url": "https://avito.ru/sankt-peterburg/predlozheniya_uslug/arenda_avtomobilya_dlya_dostavki_4458396777",
                        "location": {
                            "title": "Санкт-Петербург",
                            "lat": 60.036488,
                            "lon": 30.343132
                        }
                    }
                },
                "created": 1725967922,
                "updated": 1727881030,
                "users": [
                    {
                        "id": 310461073,
                        "name": "FlatShop - Агентство недвижимости",
                        "parsing_allowed": true,
                        "public_user_profile": {
                            "user_id": 310461073,
                            "item_id": 4458396777,
                            "avatar": {
                                "default": "https://80.img.avito.st/image/1/1.pXa-hLa2H5-II4uZ-NbfHXQnCZUApw8dDCcL.YrF-9nj8JEsbL-5EhGqmXM1Mb2jpxENdm3zjR2Ve--4",
                                "images": {
                                    "100x100": "https://80.img.avito.st/image/1/1.pXa-hLa2H5_AJsOc-NbfHXQnCZUA7wpVCScL.4JkFW2uY-i_KDJtkBi4tiMT15rp5IhK2ESwwYMg-tWI",
                                    "1024x1024": "https://80.img.avito.st/image/1/1.pXa-hLazH5-IN4uN-NbfHXQnC58AJQ.wMBR5U-eV-wXX8MuZoCphniSfMKbDl4jGVaE1Uc3GNU",
                                    "128x128": "https://80.img.avito.st/image/1/1.pXa-hLa2H5-IJYuf-NbfHXQnCZUApwkdCicL.FNcp-c_HwZP26eMdqD-D-jieEtEmpqDRdA9I9EYPyC4",
                                    "132x132": "https://80.img.avito.st/image/1/1.pXa-hLa2H5-AJYOf-NbfHXQnCZUArwkVCicL.DXQAxN2jrWyfuvGxV4patl-9eboirxFG0-i2BWrqRL4",
                                    "176x176": "https://80.img.avito.st/image/1/1.pXa-hLa2H5_oJeuf-NbfHXQnCZUAxwl9CicL.FCXYyRVDpEc7ccbDyUPFVOjgGps31Gl5kB7ApMdk8XA",
                                    "192x192": "https://80.img.avito.st/image/1/1.pXa-hLa2H5-IJIue-NbfHXQnCZUApwgdCycL.cZ3BINUsqH4rIbP7TmrMNjqzRXVpCTuxYcR5v7vBBL0",
                                    "256x256": "https://80.img.avito.st/image/1/1.pXa-hLa2H5-II4uZ-NbfHXQnCZUApw8dDCcL.YrF-9nj8JEsbL-5EhGqmXM1Mb2jpxENdm3zjR2Ve--4",
                                    "25x25": "https://80.img.avito.st/image/1/1.pXa-hLayH586Ffts3Kd3nQovA686Jws.MF_A55bg4cdhTQhEPQsfKmUx9Tb4M1b3Nv3Jfy6Y4go",
                                    "50x50": "https://80.img.avito.st/image/1/1.pXa-hLayH59sQ_ts3Kd3nQovA_lsJws.7RrjsWC_oyjHz7K4yVUUoPvvE36fab8aO9Hf1tWhqZg",
                                    "64x64": "https://80.img.avito.st/image/1/1.pXa-hLa2H5-IJouc-NbfHXQnCZUApwodCScL.VBW04LOLY2wztlWTyiJ-C4y6n6IYklRR9vT7go2VkfY",
                                    "96x96": "https://80.img.avito.st/image/1/1.pXa-hLa2H5_IJsuc-NbfHXQnCZUA5wpdCScL.yVqh1hR5sb6NaGY_gaYlQCIMPa_ZvTxT1hNmFMaeE4M"
                                }
                            },
                            "url": "https://avito.ru/user/60240bd560177f52bdcfa9cd5ebf6c52/profile?id=4458396777&iid=4458396777&src=messenger&page_from=from_item_messenger"
                        }
                    },
                    {
                        "id": 299579588,
                        "name": "Major-Logistic-Pro",
                        "parsing_allowed": false,
                        "public_user_profile": {
                            "user_id": 299579588,
                            "item_id": 4458396777,
                            "avatar": {
                                "default": "https://static.avito.ru/stub_avatars/M/15_256x256.png",
                                "images": {
                                    "128x128": "https://static.avito.ru/stub_avatars/M/15_128x128.png",
                                    "192x192": "https://static.avito.ru/stub_avatars/M/15_192x192.png",
                                    "24x24": "https://static.avito.ru/stub_avatars/M/15_24x24.png",
                                    "256x256": "https://static.avito.ru/stub_avatars/M/15_256x256.png",
                                    "36x36": "https://static.avito.ru/stub_avatars/M/15_36x36.png",
                                    "48x48": "https://static.avito.ru/stub_avatars/M/15_48x48.png",
                                    "64x64": "https://static.avito.ru/stub_avatars/M/15_64x64.png",
                                    "72x72": "https://static.avito.ru/stub_avatars/M/15_72x72.png",
                                    "96x96": "https://static.avito.ru/stub_avatars/M/15_96x96.png"
                                }
                            },
                            "url": "https://avito.ru/user/da6c1694a0be180703221c8ca2e993f3/profile?id=4458396777&iid=4458396777&src=messenger&page_from=from_item_messenger"
                        }
                    }
                ],
                "last_message": {
                    "id": "1eb56ac38395861da4e6129af75931c1",
                    "author_id": 310461073,
                    "created": 1727881030,
                    "content": {
                        "text": "А Б В А Б В Г"
                    },
                    "type": "text",
                    "direction": "out",
                    "delivered": 1727881030
                },
                "success": true
            },
            "create_at_real": 1725967922,
            "update_at_real": 1727881030,
            "created_at": "2024-09-10 14:32:02.753586",
            "update_at": "2024-09-10 14:32:02.753586",
            "update": "02.10.2024 16:57",
            "new_mess": 0,
            "is_active": false,
            "messages": [
                {
                    "id": 517021,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "f138a4d5-65ab-47ab-852e-ed4040980043",
                        "version": "v3.0.0",
                        "timestamp": 1725967922,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "302010dfc2fc266be29e973cd5188579",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 0,
                                "created": 1725967922,
                                "type": "system",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "[Системное сообщение] Исходящий звонок\nПозвонить"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-09-10T11:32:02Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1725967922,
                    "create_at_real": 1725967922,
                    "update_at": "2024-09-10 14:32:02.583305",
                    "created_at": "2024-09-10 14:32:02.583305",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 517022,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "2650e0ee-5b7e-4096-b519-1a405ec08cb3",
                        "version": "v3.0.0",
                        "timestamp": 1725967927,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "302010dfc2fc266be29e973cd5188579",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 0,
                                "created": 1725967922,
                                "type": "system",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "[Системное сообщение] Исходящий звонок\nПозвонить"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-09-10T11:32:07Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1725967927,
                    "create_at_real": 1725967922,
                    "update_at": "2024-09-10 14:32:07.846332",
                    "created_at": "2024-09-10 14:32:07.846332",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 555452,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "a74ebe8d-c675-4b7a-a7e1-57f22ea41d83",
                        "version": "v3.0.0",
                        "timestamp": 1727786487,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "3b4883fab64e1376a4fbc244679d8824",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727786486,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-01T12:41:26Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727786487,
                    "create_at_real": 1727786486,
                    "update_at": "2024-10-01 15:41:27.116937",
                    "created_at": "2024-10-01 15:41:27.116937",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 555482,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "108eb43f-2f1f-4083-bc4f-47b9d5c37e96",
                        "version": "v3.0.0",
                        "timestamp": 1727787509,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "7cde97047aa3e4b1dbcb922b0d492fd3",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727787509,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-01T12:58:29Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727787509,
                    "create_at_real": 1727787509,
                    "update_at": "2024-10-01 15:58:29.906269",
                    "created_at": "2024-10-01 15:58:29.906269",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 555507,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "7d887573-46a9-421b-a321-14169f081668",
                        "version": "v3.0.0",
                        "timestamp": 1727787978,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "9b8f6b750d6310802b8bba06fa77f7be",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727787977,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-01T13:06:17Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727787978,
                    "create_at_real": 1727787977,
                    "update_at": "2024-10-01 16:06:18.09493",
                    "created_at": "2024-10-01 16:06:18.09493",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 555634,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "57ccc4fb-52b6-4b93-ad3d-fe78ab6664d0",
                        "version": "v3.0.0",
                        "timestamp": 1727791206,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "73d57dd391b01b037529c462941b6c14",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727791205,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-01T14:00:06Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727791206,
                    "create_at_real": 1727791205,
                    "update_at": "2024-10-01 17:00:06.189615",
                    "created_at": "2024-10-01 17:00:06.189615",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557457,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "7f40fef0-d4d9-462d-a7a7-fb3ff6752b7b",
                        "version": "v3.0.0",
                        "timestamp": 1727871616,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "c6f8f49975e4305e5fdb2009248acc78",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727871616,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T12:20:16Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727871616,
                    "create_at_real": 1727871616,
                    "update_at": "2024-10-02 15:20:16.33168",
                    "created_at": "2024-10-02 15:20:16.33168",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557514,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "cb47d443-49fa-4fd8-b9c2-9c212016b830",
                        "version": "v3.0.0",
                        "timestamp": 1727872825,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "0b1bab8df270cf372224bafbc3953015",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727872825,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T12:40:25Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727872825,
                    "create_at_real": 1727872825,
                    "update_at": "2024-10-02 15:40:25.868753",
                    "created_at": "2024-10-02 15:40:25.868753",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557515,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "b338dca0-2bdf-4f00-89c8-1a62104f5f85",
                        "version": "v3.0.0",
                        "timestamp": 1727872862,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "3ddc61057d19d8f2bf3c3d597089c83c",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727872861,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T12:41:02Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727872862,
                    "create_at_real": 1727872861,
                    "update_at": "2024-10-02 15:41:02.240136",
                    "created_at": "2024-10-02 15:41:02.240136",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557522,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "6fc073bb-efc5-4c00-8a94-07b5c10b4a87",
                        "version": "v3.0.0",
                        "timestamp": 1727873016,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "c4197dc2212da0385bb23de189b17180",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727873016,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T12:43:36Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727873016,
                    "create_at_real": 1727873016,
                    "update_at": "2024-10-02 15:43:36.827542",
                    "created_at": "2024-10-02 15:43:36.827542",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557530,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "136ebb87-3a71-4be5-95af-544186341819",
                        "version": "v3.0.0",
                        "timestamp": 1727873261,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "529c7912d7ef4efea1c4958214deaccf",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727873261,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T12:47:41Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727873261,
                    "create_at_real": 1727873261,
                    "update_at": "2024-10-02 15:47:41.948014",
                    "created_at": "2024-10-02 15:47:41.948014",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557535,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "b36bcbfd-cb37-4e65-9206-d2be833a71c4",
                        "version": "v3.0.0",
                        "timestamp": 1727873294,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "66f6d8cbe5b9ac1ecc078d82a5acf17d",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727873294,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T12:48:14Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727873294,
                    "create_at_real": 1727873294,
                    "update_at": "2024-10-02 15:48:14.318658",
                    "created_at": "2024-10-02 15:48:14.318658",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557543,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "0016d972-e3e5-4c82-b630-ae241e30182b",
                        "version": "v3.0.0",
                        "timestamp": 1727873506,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "1c08a640ce36a26842ae11c277ff0e0f",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727873504,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д E "
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T12:51:44Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727873506,
                    "create_at_real": 1727873504,
                    "update_at": "2024-10-02 15:51:46.473456",
                    "created_at": "2024-10-02 15:51:46.473456",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557555,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "036af9d6-a895-4dc8-a30f-d559470588c1",
                        "version": "v3.0.0",
                        "timestamp": 1727873782,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "897328d3bfb6ac0d28bdbed432eb7912",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727873782,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д E "
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T12:56:22Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727873782,
                    "create_at_real": 1727873782,
                    "update_at": "2024-10-02 15:56:23.004187",
                    "created_at": "2024-10-02 15:56:23.004187",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557559,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "0082c513-5ddd-452c-8b72-9de9a18f54fe",
                        "version": "v3.0.0",
                        "timestamp": 1727873916,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "26dedf596d453e3a510b46abd4123934",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727873915,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д E "
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T12:58:36Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727873916,
                    "create_at_real": 1727873915,
                    "update_at": "2024-10-02 15:58:36.233163",
                    "created_at": "2024-10-02 15:58:36.233163",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557562,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "45b0a81c-9eb3-47e5-908e-21991161a041",
                        "version": "v3.0.0",
                        "timestamp": 1727873950,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "15f3c64e9e35e5ebc4ef15b4a3cfe06d",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727873950,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д E "
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T12:59:10Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727873950,
                    "create_at_real": 1727873950,
                    "update_at": "2024-10-02 15:59:11.056334",
                    "created_at": "2024-10-02 15:59:11.056334",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557574,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "289361f9-d2c7-4c9f-8ece-2e0bc67abd18",
                        "version": "v3.0.0",
                        "timestamp": 1727874230,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "e2153b431d64302c54ec16427f73fa0a",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727874230,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д E З"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T13:03:50Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727874230,
                    "create_at_real": 1727874230,
                    "update_at": "2024-10-02 16:03:50.94639",
                    "created_at": "2024-10-02 16:03:50.94639",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557585,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "224e6730-ffcd-49af-9c6f-f26923ae3f34",
                        "version": "v3.0.0",
                        "timestamp": 1727874345,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "fad31624725164dc5faab2d414d00629",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727874344,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д E З\n"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T13:05:44Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727874345,
                    "create_at_real": 1727874344,
                    "update_at": "2024-10-02 16:05:45.070354",
                    "created_at": "2024-10-02 16:05:45.070354",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557590,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "abb5f6a1-8c68-46c2-905a-ac9c74a3239a",
                        "version": "v3.0.0",
                        "timestamp": 1727874477,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "43408b5374c1da7c03a08c9cd26beb4e",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727874477,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д E З"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T13:07:57Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727874477,
                    "create_at_real": 1727874477,
                    "update_at": "2024-10-02 16:07:57.815229",
                    "created_at": "2024-10-02 16:07:57.815229",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557613,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "6ba170a5-4bf0-4727-97fb-1e1d2fc9fc0b",
                        "version": "v3.0.0",
                        "timestamp": 1727875498,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "556fda5e672d5fe39c8fd2d5d9e4f1a4",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727875498,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д E З"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T13:24:58Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727875498,
                    "create_at_real": 1727875498,
                    "update_at": "2024-10-02 16:24:58.490267",
                    "created_at": "2024-10-02 16:24:58.490267",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557622,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "46a3df65-94b1-43d5-8583-28a7a8c88bc5",
                        "version": "v3.0.0",
                        "timestamp": 1727876048,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "7f42cab18746345a7b8d37f1103a97df",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727876048,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д E З"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T13:34:08Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727876048,
                    "create_at_real": 1727876048,
                    "update_at": "2024-10-02 16:34:08.432993",
                    "created_at": "2024-10-02 16:34:08.432993",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557647,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "d1c42a2e-a7aa-4668-9d47-fe5180fa6a04",
                        "version": "v3.0.0",
                        "timestamp": 1727876689,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "47c2b637a3ce9f861f3d835332be84f9",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727876689,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В Г Д E З"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T13:44:49Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727876689,
                    "create_at_real": 1727876689,
                    "update_at": "2024-10-02 16:44:49.775489",
                    "created_at": "2024-10-02 16:44:49.775489",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557678,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "3c9c4351-cf41-4e32-b160-fbea45e6e2b5",
                        "version": "v3.0.0",
                        "timestamp": 1727878671,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "60388a1c0364a3c7de1aee86bd7ee453",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727878670,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В "
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T14:17:50Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727878671,
                    "create_at_real": 1727878670,
                    "update_at": "2024-10-02 17:17:51.40041",
                    "created_at": "2024-10-02 17:17:51.40041",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557682,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "04c56366-b448-42eb-9c00-1e0e1f5d53a8",
                        "version": "v3.0.0",
                        "timestamp": 1727878796,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "8a6ba43611f1ccdb9ed05c8a01ead7ca",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727878795,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В "
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T14:19:56Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727878796,
                    "create_at_real": 1727878795,
                    "update_at": "2024-10-02 17:19:56.239553",
                    "created_at": "2024-10-02 17:19:56.239553",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557684,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "6208981d-4685-4a79-9e8b-b67c2355969b",
                        "version": "v3.0.0",
                        "timestamp": 1727878805,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "d107ef032e769772321c6c8678de18fb",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727878804,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В А Б В "
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T14:20:04Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727878805,
                    "create_at_real": 1727878804,
                    "update_at": "2024-10-02 17:20:05.156774",
                    "created_at": "2024-10-02 17:20:05.156774",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557703,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "7aed9775-4c4c-4ca3-857d-7ae1a2712464",
                        "version": "v3.0.0",
                        "timestamp": 1727878967,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "7421e16b7675508da2852b641df3ec40",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727878966,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В А Б В Г"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T14:22:47Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727878967,
                    "create_at_real": 1727878966,
                    "update_at": "2024-10-02 17:22:47.256117",
                    "created_at": "2024-10-02 17:22:47.256117",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557704,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "5c882e6f-afd0-4a9c-8be6-020ca45589c0",
                        "version": "v3.0.0",
                        "timestamp": 1727878996,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "6f9d6bcead1c7f4c026f1852cd39d299",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727878996,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "[Системное сообщение] Исходящий звонок\nПозвонить"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T14:23:16Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727878996,
                    "create_at_real": 1727878996,
                    "update_at": "2024-10-02 17:23:16.651354",
                    "created_at": "2024-10-02 17:23:16.651354",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557734,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "24f4f2f9-8df5-4783-a0c6-0f6613f5742a",
                        "version": "v3.0.0",
                        "timestamp": 1727879824,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "674aed480ff358f3234bafd1a65c53fd",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727879824,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В А Б В Г"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T14:37:04Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727879824,
                    "create_at_real": 1727879824,
                    "update_at": "2024-10-02 17:37:05.023824",
                    "created_at": "2024-10-02 17:37:05.023824",
                    "userName": "FlatShop - Агентство недвижимости"
                },
                {
                    "id": 557762,
                    "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                    "details": {
                        "id": "6e3b9dc5-9dd9-478d-b7d4-61219ffa1c4e",
                        "version": "v3.0.0",
                        "timestamp": 1727881031,
                        "payload": {
                            "type": "message",
                            "value": {
                                "id": "1eb56ac38395861da4e6129af75931c1",
                                "chat_id": "u2i-ZZKopaG73Hhqx2VVIUugeg",
                                "user_id": 310461073,
                                "author_id": 310461073,
                                "created": 1727881030,
                                "type": "text",
                                "chat_type": "u2i",
                                "content": {
                                    "text": "А Б В А Б В Г"
                                },
                                "item_id": 4458396777,
                                "published_at": "2024-10-02T14:57:10Z"
                            }
                        },
                        "api": 285
                    },
                    "new": 0,
                    "user_id": 0,
                    "agency_id": 4,
                    "update_at_real": 1727881031,
                    "create_at_real": 1727881030,
                    "update_at": "2024-10-02 17:57:11.544092",
                    "created_at": "2024-10-02 17:57:11.544092",
                    "userName": "FlatShop - Агентство недвижимости"
                }
            ],
            "id_add_user": 0
        }
    },
    "urls": [
        "https://www.avito.ru/items/4458396777"
    ]
}