<template>
    <template v-if="!is_loading">
        <div style="overflow: auto; height: calc(100vh - 60px - 91px - 58px);" v-if="!chatId" @touchstart.stop
            @touchmove.stop @touchend.stop>
            <div class="px-10 pt-10 px-sm-20 chat-item cursor-pointer" v-for="(chat, chat_id) in chats" :key="chat_id">
                <div @click="open_chat(chat_id)" class="chat-block__left_item d-flex gap-10 position-relative"
                    :class="{ 'active': chat.is_active }">
                    <span v-if="chat.new_mess" class="avito-new-mess-counter badge bg-primary rounded-circle">
                        <span v-text="chat.new_mess >= 99 ? '99+' : chat.new_mess"></span>
                    </span>
                    <div class="chat-block__left_item-icon">
                        <img style="height:50px; width: 50px; border-radius: 5px"
                            v-if="chat.details.context.value.images.main.img"
                            :src="chat.details.context.value.images.main.img" alt="img">
                    </div>

                    <div class="chat-block__left_item-text d-flex flex-column justify-content-between">
                        <span style="max-width: calc(100vw - 85px); overflow: hidden; text-overflow: ellipsis;"
                            class="name ">{{ chat.details.users[0].name }}</span>
                        <span style="font-size: 12px; line-height: 14.4px;" class="letter text-body-tertiary">{{
                            chat.details.context.value.title }}</span>
                        <span style="font-size: 8px; line-height: 9.6px;" class="when text-body-tertiary">{{ chat.update
                            }}</span>

                    </div>
                </div>
            </div>
        </div>

        <template v-else>
            <div class="messages-header">
                <img class="cursor-pointer" @click="closeChat" src="@/assets/icons/backwards.svg">
                <img style="height:40px; width: 40px; border-radius: 5px" v-if="imageGroup" :src="imageGroup" alt="img">
                <div class="chat-block__top_right-text d-flex flex-column justify-content-between">
                    <span class="text-ellipsis name-chat-avito"
                        style="font-size: 14px; line-height: 16.8px; color: #222222;">{{
                            nameGroup }}</span>
                    <span class="quantity-participant-avito text-ellipsis">
                        <a href="javascript:{}" @click="openObjectCard" class="message__block_name">{{ nameChat }}</a>
                    </span>
                </div>
                <div class="chat-block__top_right-icons">
                    <form class="chat-block__top_right-form" style="display: none;">
                        <input placeholder="Поиск" type="text" class="search">
                        <button>
                            <img src="/images/chat/search-icon.svg" alt="img">
                        </button>
                    </form>
                    <button class="dots chat-block__top_right-btn dots-modal-show" style="display: none;">
                        <img src="/images/chat/menu-icon.svg" alt="img">
                    </button>
                </div>
            </div>
            <div class="chat-block__top_right px-10 px-sm-20 pt-20 pb-10 position-relative d-flex gap-20 flex-column">
                <div class="messages-container" ref="messagesRef"
                    :style="{ height: `calc(100dvh - ${containerHeightToCalc}px)` }" @touchstart.stop @touchmove.stop
                    @touchend.stop>
                    <div class="day-container d-flex flex-column justify-content-center align-items-center gap-20"
                        v-for="(_mes, key) in listMessage" :key="key">
                        <div style="font-size: 14px; line-height: 16.8px; color: #666666">{{ key }}</div>
                        <div class="message-avito">
                            <div :id="`message-${mes.id}`" class="message-avito__info"
                                :style="!mes.details.payload.value.author_id || mes.details.payload.value.user_id == mes.details.payload.value.author_id ? 'flex-direction: row-reverse; margin-left: 40px;' : 'margin-right: 40px;'"
                                v-for="(mes) in _mes" :key="mes.id">
                                <!-- <img :src="getMessageUserAvatar(mes)" alt="Аватарка"> -->
                                <div class="message-avito__block">
                                    <a class="message-avito__block_name">{{ mes.userName }}</a>
                                    <span class="message-avito__block_text"
                                        style="max-width: calc(100vw - 40px); text-wrap: wrap;">{{
                                            mes.details.payload.value.content.text }}</span>
                                    <span class="message-avito__block_time">{{ formatDate('HH:mm', mes.created_at)
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="messages-footer-avito">
                    <router-link to='/add-requisition'
                        class="d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
                        style="width:40px;height:40px;">
                        <img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить">
                    </router-link>
                    <TextArea class="messages-footer-avito__mes-input" inputId="new-message-avito" inputName="message"
                        :inputRequired="true" :inputValue="text_mes" inputClass="rounded-1 mb-10"
                        inputWrapClass="flex-column mb-3" @inputTyped="(e) => handleChangeInput(e)" />
                    <div class="messages-footer-avito__send-message" @click="send_mes_avito">
                        <img :src="require('@/assets/icons/direct-message.svg')" alt="Отправить">
                    </div>
                </div>
            </div>
        </template>
    </template>
    <div v-else class="d-flex flex-column align-items-center">
        <Loader />
    </div>
</template>

<script>
import api from "@/api";
import CommonService from "@/services/CommonService";
import TextArea from "@/components/inputs/TextArea";
import Loader from "@/components/common/Loader";
import moment from 'moment';
import { avito_response } from '../mockdata'
import { nextTick } from 'vue';

export default {
    components: {
        TextArea,
        Loader
    },
    data() {
        return {
            textareaHeightAvito: '40px',
            user_id: null,
            agency_id: null,
            chats: {},
            chatsIds: [],
            nameGroup: '',
            nameChat: '',
            imageGroup: null,
            listMessage: {},
            chatId: null,
            error: false,
            error_mes: '',
            text_mes: '',
            can_chat: false,
            user_global: [],
            total_new: 0,
            is_loading: true,
            initial_counter: 0
        }
    },
    methods: {
        closeChat() {
            this.$emit('setActiveChat', null)
            this.chatId = null
            this.listMessage = {}
        },
        handleChangeInput(e) {
            if (!e.target.value) {
                this.textareaHeightAvito = `40px`
            } else if (e.target.scrollHeight > 40) {
                this.textareaHeightAvito = `${e.target.scrollHeight}px`
            }

            this.text_mes = e.target.value
        },
        formatDate(format, datetime) {
            const date = moment(datetime);

            if (date.isValid()) {
                return CommonService.formatDateTime(datetime, 'ru', format);
            }

            return datetime
        },
        get_user_global() {
            api.get('/chats/get-user', {
                params: {
                    user_id: this.user_id
                }
            }).then((response) => {
                this.user_global = response.data;
                this.get_can_chat();

            }).catch(function (error) {
                console.log(error);
            });
        },

        get_messages() {
            return api.post('/chats/get-avito-messages', {
                agency_id: this.agency_id,
                user_id: this.user_id,
                user: this.user_global,
            })
                .then((response) => {
                    this.chats = {};
                    const res = response.data;
                    if (res.chats) {
                        this.chats = res.chats;
                        this.prepareChats(res.chats)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        },

        get_can_chat() {
            api.get('/chats/can-user-chat', {
                params: {
                    user_id: this.user_id,
                    service_id: 1,
                }
            }).then((response) => {
                if (response.data.can === true) {
                    this.get_messages().finally(() => {
                        this.is_loading = false
                    });
                }
            }).catch(function (error) {
                console.log(error);
            });
        },


        //Новые сообщения
        update_total_new(total_new) {
            if (total_new > 0) {
                this.initial_counter = total_new
            } else {
                this.initial_counter = 0
            }
        },

        open_chat(chatId) {
            this.error = false,
                this.error_mes = '';
            this.nameGroup = '';
            this.nameChat = '';
            this.nameGroup = this.chats[chatId].details.users[0].name
            this.imageGroup = null;
            this.imageGroup = this.chats[chatId].details.context.value.images.main.img


            this.$store.commit('set_unread_messages_count', {
                avito: this.$store.getters.unread_messages_count.avito - this.chats[chatId].new_mess
            })

            if (this.chats[chatId].details.context.value.title) {
                this.nameChat = this.chats[chatId].details.context.value.title;
            }
            this.chats[chatId].is_active = true;
            this.chats[chatId].new_mess = 0;

            for (let i in this.chats) {
                if (i !== chatId) {
                    this.chats[i].is_active = false;
                }
            }

            this.get_message_chat(chatId);
            this.$emit('setActiveChat', chatId);

            this.chatId = chatId;

        },

        prepareChatMessages() {
            this.listMessage = {}
            this.chats[this.chatId].messages.map((message, index) => {
                const date = moment(message.created_at, 'YYYY-MM-DD HH:mm:ss.SSS')

                let formatedDate;
                if (date.isValid()) {

                    formatedDate = this.formatDate('DD.MM.YYYY', date.format('DD.MM.YYYY'));

                    const dateToCheck = moment(formatedDate); // Replace with your date
                    const isToday = dateToCheck.isSame(moment(), 'day');
                    const isYesterday = dateToCheck.isSame(moment().subtract(1, 'days'), 'day');

                    if (isToday) {
                        formatedDate = "Сегодня"
                    } else if (isYesterday) {
                        formatedDate = "Вчера"
                    } else {
                        formatedDate = this.formatDate('DD MMMM YYYY[г.]', message.created_at)
                    }

                    if (!this.listMessage[formatedDate]) {
                        this.listMessage[formatedDate] = []
                    }

                } else {
                    formatedDate = "Сегодня";
                }

                this.listMessage[formatedDate].push(message)

                return message
            });
        },


        get_message_chat(chatId) {
            this.error = false,
                this.error_mes = '';

            return api.post('/chats/get-message-chat-avito', {
                chatId: chatId,
            }).then((response) => {
                const res = response.data;

                this.chats[chatId].messages = res.messages
                this.chats[chatId].new_mess = 0;
                this.chatId = chatId

                this.prepareChatMessages()

            }).catch(function (error) {
                this.user_id(error);
            });
        },

        send_mes_avito() {
            if (!this.isEmpty(this.chatId)) {
                let chatId = this.chatId;
                let chat_id_avito = this.chats[chatId].chat_id;

                if (!this.isEmpty(this.text_mes)) {
                    let text = this.text_mes;
                    this.text_mes = '';

                    api.post('/chats/send-message', {
                        chat_id: chat_id_avito,
                        text: text,
                        service_id: 1,
                        agency_id: this.agency_id,
                    }).then(() => {
                        setTimeout(() => {
                            this.get_message_chat(chatId);
                        }, 500)
                    }).catch(function (error) {
                        this.user_id(error);
                    });
                }
            } else {

                this.error = true;
                this.error_mes = "Для отправки сообщения необходимо выбрать чат";
            }
        },

        // getMessageUserAvatar(message) {
        //     if (!message.details.payload.value.author_id || message.details.payload.value.user_id == message.details.payload.value.author_id) {
        //         return this.userInfo.avatar
        //     }

        //     const userInfo = this.chats[this.chatId].details.users.find((user) => user.id == message.details.payload.value.user_id);

        //     if (userInfo) {
        //         return userInfo.public_user_profile.avatar.default
        //     }

        //     return null;
        // },

        openObjectCard() {
            // if (this.chats[this.chatId].object_id > 0) {
            // openObjectModal(this.chats[this.chatId].object_id);
            // $('.chat-cian').css('zIndex','unset');
            // }
        },

        //Проверка на пустоту
        isEmpty(data) {
            if (typeof (data) === 'object') {
                if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
                    return true;
                } else if (!data) {
                    return true;
                }
                return false;
            } else if (typeof (data) === 'string') {
                if (!data.trim()) {
                    return true;
                }
                return false;
            } else if (typeof (data) === 'undefined') {
                return true;
            } else {
                return false;
            }
        },
        prepareChats(chats) {
            let new_counter = 0
            for (let i in chats) {
                for (let j in chats[i].messages) {
                    if (this.chatsIds.indexOf(chats[i].messages[j].id) == -1) {
                        this.chatsIds.push(chats[i].messages[j].id);
                    }

                    if (chats[i].messages[j].new) {
                        new_counter = new_counter + 1
                    }
                }
            }

            this.$store.commit('set_chat_ids', { chat_type: 'avito_chat_ids', chatIds: this.chatsIds })


            this.initial_counter = new_counter
        },
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo
        },
        containerHeightToCalc() {
            const siteHeaderHeight = document.querySelector('.header')?.clientHeight || 0;

            const messagesHeaderHeight = document.querySelector('.messages-header')?.clientHeight || 71

            const textAreaHeight = parseInt(this.textareaHeightAvito.match(/\d+/)[0], 10);

            return siteHeaderHeight + messagesHeaderHeight + textAreaHeight + 60

        },
        need_refetch() {
            return this.$store.getters.need_refetch_messages.avito
        }
    },
    mounted() {
        this.user_id = this.userInfo.id
        this.agency_id = this.userInfo.agency_id
        this.get_user_global();

        nextTick(() => {
            if (this.need_refetch) {
                this.$store.commit('set_need_refetch_messages', { section: 'avito', value: false })
            }
        })

    },
    watch: {
        'need_refetch': function (val) {
            if (val) {
                if (val) {
                    if (this.chatId) {
                        this.get_message_chat(this.chatId)
                    } else {
                        this.get_messages()
                    }
                }

                this.$store.commit('set_need_refetch_messages', { section: 'avito', value: false })
            }
        }
    },
    updated() {
        if (this.chatId) {
            const chatMessages = this.chats[this.chatId].messages;

            const lastMessage = chatMessages[chatMessages.length - 1];

            const lastMessageContainer = document.getElementById(`message-${lastMessage.id}`);

            if (lastMessageContainer) {
                lastMessageContainer.scrollIntoView({ behavior: 'smooth', block: 'end' })
            }
        }
    }
}
</script>

<style lang="scss">
.text-ellipsis {
    max-width: calc(-120px + 100vw);
    overflow: hidden;
    text-overflow: ellipsis;
}

.messages-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
}

.message-avito {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    &__info {
        display: flex;
        gap: 10px;

        img {
            margin-top: 10px;
            width: 32px;
            height: 32px;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    &__block {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 10px;
        gap: 10px;
        border-radius: 5px;
        background: #FFFFFF;
        width: 100%;
    }

    &__block_name {
        line-height: 16.8px;
        color: #029EFF;
        font-size: 14px;
    }

    &__block_time {
        font-size: 14px;
        line-height: 16.8px;
        color: #888888
    }
}

.messages-footer-avito {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    left: 0;
    gap: 10px;
    padding: 10px;
    background-color: #FFFFFF;
    transition: .10s all;

    &__mes-input {
        margin-bottom: 0 !important;
        display: flex;
        flex-grow: 1;

        textarea {
            height: v-bind(textareaHeightAvito);
            width: 100%;
            min-height: 40px;
            overflow: hidden;
            padding: 10px;
            font-size: 14px;
            margin: 0 !important;
        }
    }

    &__send-message {
        height: 40px;
        width: 40px;
        border-radius: 12px;
        border: 1px solid #DEDEDE;
        background-color: #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

.messages-header {
    display: flex;
    gap: 10px;
    background-color: #FFFFFF;
    padding: 20px 10px 10px 10px;
    border-bottom: 1px solid #e8d8d8;
}

.chat-item:last-child {
    padding-bottom: 10px;
}

.with-space {
    bottom: 58px !important;
}

.avito-new-mess-counter {
    position: absolute;
    width: 17px;
    font-size: 8px;
    line-height: 9px;
    height: 17px;
    top: 18px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pb-60 {
    padding-bottom: 100px;
}
</style>