<template>
    <div class="d-flex flex-column col-12 text-nowrap-mask scroller scroller-x pb-0 mb-0" role="tablist"
        :style="this.hasActiveChat ? 'height: calc(100vh - 50px);' : ''">
        <div v-if="!this.hasActiveChat"
            class="nav-container px-10 px-sm-20 pt-10 pb-10 border-brd-primary position-relative">
            <h1 class="page-title mb-0">Чаты</h1>
            <NavTabs :navItems="this.tabItems" />
        </div>
        <AvitoChat v-if="activeTab == 'avito'" @setActiveChat="setActiveChat" />
        <CianChat v-if="activeTab == 'cian'" @setActiveChat="setActiveChat" />
    </div>
</template>

<script>
import NavTabs from "@/components/common/NavTabs";
import AvitoChat from "@/components/chat/avito"
import CianChat from "@/components/chat/cian"


export default {
    name: "chats",
    components: {
        NavTabs,
        AvitoChat,
        CianChat
    },
    data() {
        return {
            hasActiveChat: false,
            tabItems: [
                {
                    id: 'avito',
                    label: "Авито",
                    title: "Авито",
                    icon: 'avito-logo.svg',
                    active: true,
                    action: () => this.handleTabClick('avito')
                }, {
                    id: 'cian',
                    label: "Циан",
                    title: "Циан",
                    icon: 'cian-logo.svg',
                    active: false,
                    action: () => this.handleTabClick('cian')
                },
                //  {
                // 	id: 'new-buildings-tab',
                // 	label: "Новостройки",
                // 	title: "Новостройки",
                // 	data_bs_toggle: null,
                // 	data_bs_target: null,
                // 	count: null,
                // 	active: false,
                // 	action: () => console.log('avito')
                // },
            ],
            activeTab: 'avito'
        }
    },
    computed: {
        avito_unread_messages() {
            return this.$store.getters.unread_messages_count.avito
        },
        cian_unread_messages() {
            return this.$store.getters.unread_messages_count.cian
        },
    },
    methods: {
        handleTabClick(tab) {
            this.activeTab = tab

        },
        setActiveChat(chat) {
            this.hasActiveChat = !!chat

            this.tabItems.forEach((item) => {
                if (item.id == this.activeTab) {
                    item.active = true
                } else {
                    item.active = false;
                }
            })
        },
        initializeCounters(cianCounter, avitoCounter) {
            this.tabItems.find((tab) => tab.id == 'cian').count = cianCounter >= 99 ? '99+' : cianCounter
            this.tabItems.find((tab) => tab.id == 'avito').count = avitoCounter >= 99 ? '99+' : avitoCounter
        },
    },
    watch: {
        'cian_unread_messages': function (val) {
            this.initializeCounters(val, this.avito_unread_messages)
        },
        'avito_unread_messages': function (val) {
            this.initializeCounters(this.cian_unread_messages, val)
        },
    },
    mounted() {
        this.initializeCounters(this.cian_unread_messages, this.avito_unread_messages)

        document.body.classList.add('overflow-hidden')
    },
    unmounted() {
        document.body.classList.remove('overflow-hidden')
    }
}
</script>

<style lang="scss">
.nav-container {
    li {
        a {
            padding-top: 6px;
            padding-bottom: 6px;
            font-weight: 300;
            position: relative !important;

            &>span {
                position: absolute !important;
                width: 15px;
                font-size: 8px;
                line-height: 9px;
                height: 15px;
                top: 0px;
                right: -10px;
            }
        }
    }
}
</style>